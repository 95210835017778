// import React from 'react';
// import styled from 'styled-components';

// const TabContainer = styled.div`
//   display: flex;
//   gap: 2px;
//   background: #2C3E50;
//   height: 48px;
// `;

// const TabButton = styled.button`
//   padding: 8px 24px;
//   background: ${props => props.active ? 'linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113))' : 'transparent'};
//   color: ${props => props.active ? '#FFFFFF' : '#9AA6AC'};
//   border: none;
//   cursor: pointer;
//   font-weight: 600;
//   font-size: 14px;
//   transition: all 0.2s ease;
// //   border-radius: 8px 8px 0 0;
//   position: relative;

//   &:hover {
//     background-color: ${props => props.active ? '#1B8271' : '#374248'};
//     color: #FFFFFF;
//   }

//   &:after {
//     content: '';
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 2px;
//     background-color: ${props => props.active ? '#22C55E' : 'transparent'};
//     transition: all 0.2s ease;
//   }
// `;

// const ContentContainer = styled.div`
//   padding: 24px;
//   width:100%;
//   border-radius: 0 0 8px 8px;
//   color: #9AA6AC;

// `;




// const TabContent = styled.div`
//   animation: fadeIn 0.3s ease;
  
//   @keyframes fadeIn {
//     from { opacity: 0; }
//     to { opacity: 1; }
//   }
// `;

// const TabSwitcher = ({ activeTab, onTabClick }) => {
//   return (
//     <div>
//       <TabContainer>
//         <TabButton 
//           active={activeTab === 'in-play'}
//           onClick={() => onTabClick('in-play')}
//         >
//           In-Play
//         </TabButton>
//         <TabButton
//           active={activeTab === 'today'}
//           onClick={() => onTabClick('today')}
//         >
//           Today
//         </TabButton>
//         <TabButton
//           active={activeTab === 'tomorrow'}
//           onClick={() => onTabClick('tomorrow')}
//         >
//           Tomorrow
//         </TabButton>
//       </TabContainer>
//     </div>
//   );
// };

// export default TabSwitcher;




import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { History } from 'lucide-react';

const TabContainer = styled.div`
  display: flex;
  gap: 2px;
  background: #2C3E50;
  height: 48px;
`;

const TabButton = styled.button`
  padding: 15px 24px;
  background: ${props => props.active ? 'linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113))' : 'transparent'};
  color: ${props => props.active ? '#FFFFFF' : '#9AA6AC'};
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.2s ease;
//   border-radius: 8px 8px 0 0;
  position: relative;

  &:hover {
    background-color: ${props => props.active ? '#1B8271' : '#374248'};
    color: #FFFFFF;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    // height: 48px;
    background-color: ${props => props.active ? '#22C55E' : 'transparent'};
    transition: all 0.2s ease;
  }
`;


const HistoryButton = styled.button`
  background: transparent;
  border: none;
  color: #9AA6AC;
  cursor: pointer;
  padding: 8px;
  margin-left: auto;
  display: flex;
  align-items: center;
  transition: color 0.2s ease;

  &:hover {
    color: #FFFFFF;
  }
`;

const TabSwitcher = ({ activeTab, onTabClick }) => {
  const history = useHistory();

  const handleHistoryClick = () => {
    history.push('/sports/markets/history');
  };

  return (
    <TabContainer>
      <TabButton 
        active={activeTab === 'in-play'}
        onClick={() => onTabClick('in-play')}
      >
        In-Play
      </TabButton>
      <TabButton
        active={activeTab === 'today'}
        onClick={() => onTabClick('today')}
      >
        Today
      </TabButton>
      <TabButton
        active={activeTab === 'tomorrow'}
        onClick={() => onTabClick('tomorrow')}
      >
        Tomorrow
      </TabButton>
      <HistoryButton onClick={handleHistoryClick}>
        <History size={20} />
      </HistoryButton>
    </TabContainer>
  );
};

export default TabSwitcher;





