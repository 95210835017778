import React, { useState, useMemo, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import BetSlip from '../../BetSlip';
import CricketSchedule from '../../Today';
import SportsScheduleTomorrow from '../../Tomorrow';
import CricketBettingUI from '..';
import TabSwitcher from '../../TabSwitcher';
import MiddleDetailBettingUi from './Middle';
import LiveStreamFrame from '../../LiveStreamFrame';
import BettingTable from '../../MyBets';
import BettingHistoryTable from '../../BettingHistoryTable';
import { useBetting } from '../../../../../../context/BettingContext';

// Constants
const MENU_ITEMS = [
  { id: 'all-sports', label: 'All Sports' },
  { id: 'cricket', label: 'Cricket' },
  { id: 'soccer', label: 'Soccer' },
  { id: 'tennis', label: 'Tennis' }
];

// SVG icons
// SVG icons
const SportIcons = {
  'all-sports': <img src="/assets/images/sports/als.png" alt="All Sports" width="20" height="20" />,
  'cricket': <img src="/assets/images/sports/crics.png" alt="Cricket" width="20" height="20" />,
  'soccer': <img src="/assets/images/sports/sf.png" alt="Soccer" width="20" height="20" />,
  'tennis': <img src="/assets/images/sports/tss.png" alt="Tennis" width="20" height="20" />
};

// Styled Components
const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const MainContent = styled.div`
  flex: 0 0 60%;
  background-color:#F3F4F6;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  overflow-y: auto;
  margin-bottom:19px;

`;

const ContentWrapper = styled.div`
  flex: 1;
  min-height: calc(100vh - 200px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 2px;
  }
`;

const Header = styled.div`
  background: #2c3e50;
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  flex-shrink: 0;
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.5s ease;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const BannerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.1)
  );
  pointer-events: none;
`;

const Section = styled.div`
  flex: ${props => props.width};
  background-color: white;
  min-height: 100vh;
  overflow-y: auto;
  border-right: 1px solid #edf2f7;
  
  &::-webkit-scrollbar {
    width: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #CBD5E0;
    border-radius: 2px;
  }
`;

const Header1 = styled.div`
  background: #2c3e50;
  color: #24EE89;
  padding: 12px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  flex-shrink: 0;
`;

const MenuList = styled.ul`
  list-style-type: none;
  padding: 8px 0px;
  margin: 0;
`;

const MenuItem = styled.li`
  padding: 10px 12px;
  margin: -6px 0;
  color: ${props => props.active ? '#2C5282' : '#4A5568'};
  cursor: pointer;
  transition: all 0.2s ease;
  // border-radius: 8px;
    font-weight: ${props => props.active ? 'bold' : '600'};
  background-color: ${props => props.active ? '#BFDBFE' : 'transparent'};
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: ${props => props.active ? '600' : '500'};
  
  // &:hover {
  //   color: #2C5282;
  //   background-color: ${props => props.active ? '#EBF8FF' : '#F7FAFC'};
  // }

  svg {
    color: ${props => props.active ? '#2C5282' : '#718096'};
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TabContent = styled.div`
  animation: fadeIn 0.3s ease;
  flex: 1;
  // backgroud-color:gray !important;
  margin-bottom:12px;
  min-height: calc(100vh - 300px);
  overflow-y:auto;
  // height:100vh;
  bakcground-color:white;

  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const MatchTitle = styled.div`
  color: black;
  font-size: 14px;
  padding: 20px;
  text-align: center;
  // background: white;
  margin-bottom: 16px;
  flex-shrink: 0;
`;
const MatchTitle2 = styled.div`
  color: black;
  font-size: 14px;
font-weight:bold;
  text-align: center;
  // background: white;
  flex-shrink: 0;
`;


// Memoized Menu Component
const SideMenu = React.memo(({ activeTab, onTabChange }) => (
  <Section width="0 0 15%">
    <Header1>Sports</Header1>
    <MenuList>
      {MENU_ITEMS.map(item => (
        <MenuItem
          key={item.id}
          active={activeTab === item.id}
          onClick={() => onTabChange(item.id)}
        >
          <IconWrapper>
            {SportIcons[item.id]}
          </IconWrapper>
          {item.label}
        </MenuItem>
      ))}
    </MenuList>
  </Section>
));

const CricketBetUiComponent = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState('all-sports');
  const [sideMenutab, setSideMenuTab] = useState('all-sports');
  const matchDataIS = location.state?.matchData;
  const { isMatchClicked} = useBetting()

  console.log("maamthed data is is", matchDataIS)
  useEffect(() => {
    if (matchDataIS) {
      setIsLoading(false);
    }
  }, [matchDataIS, tab, isMatchClicked]);

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  const handleTabChange = (tab) => {
    console.log("tab value", tab)
    setActiveTab(tab);
  };
  const handleSideTabChange = (tab) => {
    console.log("tab value", tab)
    setSideMenuTab(tab);
  };

  const [shouldShowLiveStream, setShouldShowLiveStream] = useState(true);

  useEffect(() => {
    const path = location.pathname;
    const excludedPaths = [
      '/sports/markets/in-play',
      '/sports/markets/today',
      '/sports/markets/tomorrow',
      '/sports/markets/history'
    ];
    
    setShouldShowLiveStream(!excludedPaths.includes(path));
  }, [location.pathname]);

  useEffect(() => {
    if (matchDataIS) {
      setIsLoading(false);
    }
  }, [matchDataIS, tab, isMatchClicked]);

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  const { matchData } = location.state || {};
  const renderMiddleContent = () => {
    switch (activeTab) {
      case 'in-play':
        return (
          <TabContent>
            <CricketBettingUI upperTab={activeTab} tab={sideMenutab} />
          </TabContent>
        );
      case 'today':
        return (
          <TabContent>
            <CricketSchedule />
          </TabContent>
        );
      case 'tomorrow':
        return (
          <TabContent>
            <SportsScheduleTomorrow />
          </TabContent>
        );
        case 'history':
          return (
            <TabContent>
              <BettingHistoryTable/>
            </TabContent>
          );
      default:
        return (
          <TabContent>
            <MiddleDetailBettingUi matchData={matchData} />
          </TabContent>
        );
    }
  };

  const middleSection = useMemo(() => (
    <Section width="0 0 60%" bg="#1C1F23">
      {renderMiddleContent()}
    </Section>
  ), [activeTab]);

  const rightSection = useMemo(() => (
    <Section width="0 0 25%">
      {shouldShowLiveStream && <LiveStreamFrame id={matchDataIS?.eventId} />}
      <BetSlip />
      <BettingTable />
    </Section>
  ), [shouldShowLiveStream, matchDataIS?.eventId]);
  const rightSectionHistory = useMemo(() => (
    <Section width="0 0 25%">
      <LiveStreamFrame  />
      <BetSlip />
     
    </Section>
  ), []);



  useEffect(() => {
    if (!matchData) {
      // Handle case when no match data is available
      // Maybe redirect back or show error
    }
  }, [matchData]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    
    history.push(`/sports/markets/${tab}`);
  };

  return (
    <LayoutContainer>
      <SideMenu
        activeTab={sideMenutab}
        onTabChange={handleSideTabChange}
      />

      <MainContent>
        <TabSwitcher
          activeTab={activeTab}
          onTabClick={handleTabClick}
        />
        {
          !matchData && (<Header>
            <BannerImage
              src="/assets/images/sports-b.png"
              alt="Sports Banner"
              loading="lazy"
            />
            <BannerOverlay />
          </Header>)
        }

        <ContentWrapper>
          {matchData && (
            <div style={{ padding: '5px', width: "100%", display: 'flex', justifyContent: 'space-between' }}>
              <MatchTitle2>
                Live Score
              </MatchTitle2>

              <MatchTitle2>
                {matchData.team1} vs {matchData.team2}
              </MatchTitle2>

            </div>)}
          {renderMiddleContent()}
        </ContentWrapper>
      </MainContent>

      {rightSection}
    </LayoutContainer>
  );
};

export default CricketBetUiComponent;