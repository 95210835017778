import React, { Component } from 'react';
import styled from 'styled-components';
import { Play } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';

const GameOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease;
`;

const PlayIconContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #1b2336;
`;

const FullScreenIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  display: ${(props) => (props.loading ? 'none' : 'block')};
`;

const PlayIcon = styled(Play)`
  color: #fff;
  font-size: 24px;
`;

const BackButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
  
  &:hover {
    opacity: 0.8;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  gap: 12px;
  padding: 12px;
  width: 100%;
  
  /* Mobile - 3 items per row */
  grid-template-columns: repeat(3, 1fr);
  
  /* Tablet - 4 items per row */
  @media (min-width: 640px) {
    grid-template-columns: repeat(4, 1fr);
  }
  
  /* Desktop - 6 items per row */
  @media (min-width: 1024px) {
    grid-template-columns: repeat(6, 1fr);
    gap: 16px;
    padding: 16px;
  }
  
  /* Large Desktop - 8 items per row */
  @media (min-width: 1280px) {
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
    padding: 20px;
  }
`;

const LotteryGameCard = styled.div`
  width: 100%;
  aspect-ratio: 1.32;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease;

  &:hover {
    ${GameOverlay} {
      opacity: 1;
    }
    transform: translateY(-5px);
  }
`;

const LotteryGameImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

class Lottery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: localStorage.getItem('logged') === 'true',
      displayGamesLotteryXgaming: [],
      message: null,
      iframeUrl: '',
      showIframe: false,
      iframeLoading: false
    };
  }

  componentDidMount() {
    this.fetchGames();
  }

  handleIframeLoad = () => {
    this.setState({ iframeLoading: false });
  };

  handleBackButton = () => {
    this.setState({ showIframe: false, iframeUrl: '', iframeLoading: true });
  };

  fetchGames = async () => {
    try {
      const response = await fetch('https://api.ibitplay.com/api/games/list');
      const result = await response.json();
      const lotteryVendors = ["mascot", "caleta", "belatragames", "superlottotv", "lottoinstantwin"];
      const lotteryGames = lotteryVendors.reduce((acc, vendor) => {
        if (result[vendor]) {
          acc.push(...result[vendor]);
        }
        return acc;
      }, []);
      this.setState({ displayGamesLotteryXgaming: lotteryGames });
    } catch (error) {
      this.setState({ message: { type: 'error', text: error.message } });
    }
  };

  handleGameLaunchXGaming = async (game) => {
    if (!this.state.isLogged) {
      this.setState({ message: { type: 'error', text: "Please login to play live game" } });
      return;
    }

    try {
      const response = await fetch('https://api.ibitplay.com/api/casino/gamerun', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          game_id: game.id,
          currency: "USD",
          mode: "real",
          language: "en",
          home_url: "https://skyla.game",
          device: "desktop",
          user_id: "9845465912",
          vendor: game.vendor,
          title: game.title,
          coin: "usdt"
        }),
      });

      const result = await response.json();
      if (result.gameRunUrl) {
        this.setState({ iframeUrl: result.gameRunUrl, showIframe: true, iframeLoading: true });
      } else {
        throw new Error("Failed to launch game");
      }
    } catch (error) {
      this.setState({ message: { type: 'error', text: error.message } });
    }
  };

  render() {
    const { displayGamesLotteryXgaming, showIframe, iframeUrl, iframeLoading } = this.state;

    const imageStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '250px',
      animation: 'fadeinout 2s infinite',
    };

    const animationStyle = `
      @keyframes fadeinout {
        0%, 100% { opacity: 0; }
        50% { opacity: 1; }
      }
    `;

    if (showIframe) {
      return (
        <FullScreenContainer>
          <style>{animationStyle}</style>
          {iframeLoading && (
            <img src="assets/images/lg.png" alt="Loading" style={imageStyle} />
          )}
          <FullScreenIframe
            src={iframeUrl}
            title="Game Iframe"
            onLoad={this.handleIframeLoad}
            loading={iframeLoading}
          />
          <BackButton onClick={this.handleBackButton}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </BackButton>
        </FullScreenContainer>
      );
    }

    return (
      <GridWrapper>
        {displayGamesLotteryXgaming?.map((game, index) => (
          <LotteryGameCard
            key={game.id}
            onClick={() => this.handleGameLaunchXGaming(game)}
          >
            <LotteryGameImage src={game.details_thumbnails_300x300} alt={`Game ${index}`} />
            <GameOverlay>
              <PlayIconContainer>
                <PlayIcon />
              </PlayIconContainer>
            </GameOverlay>
          </LotteryGameCard>
        ))}
      </GridWrapper>
    );
  }
}

export default Lottery;