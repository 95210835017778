import { ChevronLeft, ChevronLeftIcon } from 'lucide-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #284050;
  color: white;
  padding: 10px 16px;
`;

const Title = styled.div`
  font-size: 18px;
margin-top:-2px;
`;

const ToggleButton = styled.button`
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const FrameContainer = styled.div`
  width: 100%;
  height: 169px;
  overflow: hidden;
`;

const ScoreContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #000;
`;

const ScoreFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  margin-top:4px;
`;

const StreamContainer = styled.div`
  width: 100%;
  height: 169px;
  overflow: hidden;
  margin-top: 16px;
`;

const StreamWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const StreamFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const PlayButton = styled.button`
  background: #27ae60;
  color: white;
  padding: 4px 9px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight:bold;
  &:hover {
    background: #2ecc71;
  }
`;

const MobileLiveInterface = ({ eventId }) => {
    const [showStream, setShowStream] = useState(false);

    const toggleStream = () => {
        setShowStream(!showStream);
    };

    const history= useHistory();

    return (
        <Container>
            <Header>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'centr' }}>
                    <ChevronLeftIcon style={{marginLeft:'-13px', fontWeight:'bold'}} onClick={()=>history.goBack()} size={24} />
                    <Title>Live Score</Title>
                </div>
                <PlayButton onClick={toggleStream}>
                    {showStream ? 'Stop Stream' : 'Play Stream'}
                </PlayButton>
            </Header>
            {showStream && (
                <StreamContainer>
                    <StreamWrapper>
                        <StreamFrame
                            src={`https://livestream-v3-iframe.akamaized.uk/?eventid=${eventId}`}
                            title="Live Stream"
                            allowFullScreen
                        />
                    </StreamWrapper>
                </StreamContainer>
            )}
            <FrameContainer>
                <ScoreContainer>
                    <ScoreFrame
                        src={`https://score.akamaized.uk/?id=${eventId}`}
                        title="Live Score"
                    />
                </ScoreContainer>
            </FrameContainer>

        </Container>
    );
};

export default MobileLiveInterface;