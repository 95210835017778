// // import React from 'react';
// // import styled from 'styled-components';

// // const StreamContainer = styled.div`
// //   width: 100%;
// // `;

// // const StreamWrapper = styled.div`
// //   width: 100%;
// //   background: #ffff;
// //   overflow: hidden;
// //   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
// // `;

// // const StreamFrame = styled.iframe`
// //   width: 100%;
// //   height: 100%;
// //   border: none;
// //   display: block;
// // `;

// // const Header = styled.div`
// //   background: #2c3e50;
// //   color: white;
// //    padding: 14px 8px;
// //   display: flex;
// //   justify-content: space-between;
// //   align-items: center;
// //   font-size: 14px;
// // `;

// // const LiveStreamFrame = ({ id }) => (

// //     <>

// //         <Header>
// //             <div>Live Match </div>
// //         </Header>
// //         <StreamContainer>
// //             <StreamWrapper>
// //                 <StreamFrame
// //                     src={`https://livestream-v3-iframe.akamaized.uk/?eventid=${id}`}
// //                     title="Live Stream"
// //                     allowFullScreen
// //                 />
// //             </StreamWrapper>
// //         </StreamContainer>
// //     </>

// // );

// // export default LiveStreamFrame;


// import React, { useState } from 'react';
// import styled from 'styled-components';

// const StreamContainer = styled.div`
//   width: 100%;
// `;

// const StreamWrapper = styled.div`
//   width: 100%;
//   background: #ffffff;
//   overflow: hidden;
//   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
// `;

// const Header = styled.div`
//   background: #2c3e50;
//   color: white;
//   padding: 14px 8px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   font-size: 14px;
//   cursor: pointer;
//   &:hover {
//     background: #34495e;
//   }
// `;
// const PlayButton = styled.button`
//   background: #27ae60;
//   color: white;
//   padding: 6px 12px;
//   border-radius: 4px;
//   border: none;
//   cursor: pointer;
//   &:hover {
//     background: #2ecc71;
//   }
// `;

// const ToggleButton = styled.button`
//   background: #34495e;
//   color: white;
//   padding: 6px 12px;
//   border-radius: 4px;
//   border: none;
//   cursor: pointer;
//   &:hover {
//     background: #3d566e;
//   }
// `;

// const StreamFrame = styled.iframe`
//   width: 100%;
//   aspect-ratio: 16/9;
//   border: none;
//   display: block;
// `;

// const VideoContainer = styled.div`
//   width: 100%;
//   background: #000000;
// `;

// const LiveStreamFrame = ({ id }) => {
//   const [isOpen, setIsOpen] = useState(false);
  
//   const toggleStream = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <StreamContainer>
//       <StreamWrapper>
//       <Header>
//           <span>Live Match</span>
//           <PlayButton onClick={togglePlay}>
//             {isPlaying ? 'Stop' : 'Play'}
//           </PlayButton>
//         </Header>
        
//         {isOpen && (
//           <VideoContainer>
//             <StreamFrame
//                src={`https://livestream-v3-iframe.akamaized.uk/?eventid=${id}`}
//               title="Live Stream Player"
//               allowFullScreen
//             />
//           </VideoContainer>
//         )}
//       </StreamWrapper>
//     </StreamContainer>
//   );
// };

// export default LiveStreamFrame;


import styled from 'styled-components';
import { useState } from 'react';

const PlayButton = styled.button`
  background: #27ae60;
  color: white;
  padding: 4px 9px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight:bold;
  &:hover {
    background: #2ecc71;
  }
`;
const StreamContainer = styled.div`
  width: 100%;
  overflow:hidden;
  overflow-scroll:hidden;
`;

const StreamWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  overflow: hidden;
    overflow-scroll:hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  background: #2c3e50;
  color: white;
  padding: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: #34495e;
  }
`;
// const PlayButton = styled.button`
//   background: #27ae60;
//   color: white;
//   padding: 6px 12px;
//   border-radius: 4px;
//   border: none;
//   cursor: pointer;
//   &:hover {
//     background: #2ecc71;
//   }
// `;

const ToggleButton = styled.button`
  background: #34495e;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  &:hover {
    background: #3d566e;
  }
`;

const StreamFrame = styled.iframe`
  width: 100%;
  aspect-ratio: 16/9;
  border: none;
  display: block;
`;

const VideoContainer = styled.div`
  width: 100%;
  background: #000000;
    overflow:hidden;
`;

const LiveStreamFrame = ({ id }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <StreamContainer>
      <StreamWrapper>
        <Header>
          <span>Live Match</span>
          <PlayButton onClick={togglePlay}>
            {isPlaying ? 'Stop' : 'Play'}
          </PlayButton>
        </Header>
        
        {isPlaying && (
          <VideoContainer>
            <StreamFrame
              src={`https://livestream-v3-iframe.akamaized.uk/?eventid=${id}`}
              title="Live Stream Player"
              allowFullScreen
            />
          </VideoContainer>
        )}
      </StreamWrapper>
    </StreamContainer>
  );
};

export default LiveStreamFrame;

