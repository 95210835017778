import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  background: #1e4d5c;
  color: white;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    color: white;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: bold;
`;

const MinMax = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const MinMaxBox = styled.div`
  background: rgba(255, 255, 255, 0.2);
  padding: 5px 10px;
  border-radius: 3px;
`;

const TableContainer = styled.div`
  background: linear-gradient(to right, #fff7e6 50%, #ffe6e6 50%);
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(6, 1fr);
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  text-align: center;
  background: #f8f9fa;
`;

const HeaderSection = styled.div`
  grid-column: ${props => props.start} / ${props => props.end};
  text-align: center;
  font-weight: bold;
  color: #000;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(6, 1fr);
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background: ${props => props.suspended ? 'rgba(240, 240, 240, 0.7)' : 'transparent'};
  position: relative;
`;

const TeamName = styled.div`
  padding-left: 10px;
  color: #000;
`;

const OddsBox = styled.div`
  background: ${props => {
    if (props.selected && (props.index === 2 || props.index === 3)) {
      return props.type === 'back' ? '#72BBEF' : '#FFB6C1';
    }
    return props.index < 3 ? 
      'linear-gradient(90deg, #e0f7fa, #b2ebf2)' : 
      'linear-gradient(90deg, #ffe6e6, #ffcccc)';
  }};
  border: 1px solid ${props => props.type === 'back' ? '#72BBEF' : '#FFB6C1'};
  margin: 0 4px;
  padding: 8px;
  text-align: center;
  cursor: ${props => props.suspended ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.suspended ? 0.7 : 1};
  transition: all 0.2s;
  color: #000;
  
  &:hover {
    opacity: ${props => props.suspended ? 0.7 : 0.8};
  }
`;

const BettingContainer = styled.div`
  width: 100%;
  padding: 8px;
  background: ${props => props.isBack ? '#EFF6FF' : '#FEE2E2'};
  color: #000;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  color: #000;
`;

const Checkbox = styled.input`
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 13px;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CancelButton = styled.div`
  padding: 6px 10px;
  background: white;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  cursor: pointer;
  color: #000;
  font-size: 12px;
  
  &:hover {
    background: #F9FAFB;
  }
`;

const OddsDisplay = styled.div`
  padding: 6px 10px;
  background: white;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  color: #000;
  font-weight: 500;
  font-size: 12px;
  min-width: 60px;
  text-align: center;
`;

const StakeInput = styled.input`
  padding: 6px 10px;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  width: 90px;
  color: #000;
  font-size: 12px;
  
  &::placeholder {
    color: #6B7280;
  }
`;

const PlaceBetButton = styled.div`
  padding: 6px 12px;
  background: #4B5563;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  
  &:hover {
    background: #374151;
  }
`;

const QuickStakeContainer = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 8px;
`;

const QuickStakeButton = styled.div`
  padding: 4px 6px;
  background: white;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  flex: 1;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  
  &:hover {
    background: #F9FAFB;
  }
`;

const StatusOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 16.67%;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(200, 200, 200, 0.7),
    rgba(180, 180, 180, 0.8)
  );
  box-shadow: 
    inset 0 0 20px rgba(160, 160, 160, 0.4),
    inset 0 0 40px rgba(140, 140, 140, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: none;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(80, 80, 80, 0.15);
    z-index: -1;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      rgba(120, 120, 120, 0.1),
      rgba(100, 100, 100, 0.2)
    );
    z-index: -2;
  }
`;

const StatusText = styled.div`
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: 50px;
  border-radius: 4px;

`;

const StatusDot = styled.span`
  width: 6px;
  height: 6px;
  background-color: ${props => props.isBallRunning ? '#4CAF50' : '#FF5722'};
  border-radius: 50%;
  display: inline-block;
`;

const BookmakerMarket = ({ matchData, bookmakerData }) => {
  const [selectedBack, setSelectedBack] = useState(null);
  const [selectedLay, setSelectedLay] = useState(null);
  const [stake, setStake] = useState('');
  const [acceptAnyOdds, setAcceptAnyOdds] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleBackSelect = (value, index, rowIndex) => {
    setSelectedBack(value);
    setSelectedLay(null);
    setSelectedRow(rowIndex);
  };

  const handleLaySelect = (value, index, rowIndex) => {
    setSelectedLay(value);
    setSelectedBack(null);
    setSelectedRow(rowIndex);
  };

  const handleCancel = () => {
    setSelectedBack(null);
    setSelectedLay(null);
    setStake('');
    setAcceptAnyOdds(false);
  };

  const handleStakeChange = (value) => {
    setStake(value);
  };

  const handleQuickStake = (value) => {
    setStake(value.toString());
  };

  const quickStakes = [4, 30, 50, 200, 500, 1000];

  return (
    <Container>
      <Header>
        <Title>
          <span>⭐</span>
          <span>Bookmaker Market | Zero Commission</span>
        </Title>
        <MinMax>
          <MinMaxBox>Min {bookmakerData[0]?.minSetting || '1.00'}</MinMaxBox>
          <MinMaxBox>Max {bookmakerData[0]?.maxSetting || '2,362.00'}</MinMaxBox>
        </MinMax>
      </Header>
      <TableContainer>
        <TableHeader>
          <HeaderSection start={3} end={4}></HeaderSection>
          <HeaderSection start={4} end={5}>Back</HeaderSection>
          <HeaderSection start={5} end={0}>Lay</HeaderSection>
        </TableHeader>
        {bookmakerData.map((market, rowIndex) => (
          <React.Fragment key={market.selectionId}>
            <TableRow suspended={market.selectionStatus === "SUSPEND"}>
              {market.selectionStatus === "SUSPEND" && (
                <StatusOverlay>
                  <StatusText>
                    Suspended
                  </StatusText>
                </StatusOverlay>
              )}
              <TeamName>{market.selectionName}</TeamName>
              {market.selectionStatus === "SUSPEND" ? (
                <>
                  <OddsBox disabled></OddsBox>
                  <OddsBox disabled></OddsBox>
                  <OddsBox suspended>
                    Suspend
                  </OddsBox>
                  <OddsBox suspended>
                    Suspend
                  </OddsBox>
                  <OddsBox disabled></OddsBox>
                  <OddsBox disabled></OddsBox>
                </>
              ) : (
                <>
                  <OddsBox disabled></OddsBox>
                  <OddsBox disabled></OddsBox>
                  <OddsBox
                    type="back"
                    index={2}
                    selected={selectedBack === market.backOdds && selectedRow === rowIndex}
                    onClick={() => handleBackSelect(market.backOdds, 2, rowIndex)}
                  >
                    {market.backOdds}
                  </OddsBox>
                  <OddsBox
                    type="lay"
                    index={3}
                    selected={selectedLay === market.layOdds && selectedRow === rowIndex}
                    onClick={() => handleLaySelect(market.layOdds, 3, rowIndex)}
                  >
                    {market.layOdds}
                  </OddsBox>
                  <OddsBox disabled></OddsBox>
                  <OddsBox disabled></OddsBox>
                </>
              )}
            </TableRow>
            {selectedRow === rowIndex && (selectedBack || selectedLay) && (
              <BettingContainer isBack={selectedBack !== null}>
                <CheckboxContainer>
                  <Checkbox 
                    type="checkbox" 
                    id={`acceptOdds-${rowIndex}`} 
                    checked={acceptAnyOdds}
                    onChange={(e) => setAcceptAnyOdds(e.target.checked)}
                  />
                  <Label htmlFor={`acceptOdds-${rowIndex}`}>Accept Any Odds</Label>
                </CheckboxContainer>
                
                <ActionContainer>
                  <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                  <OddsDisplay>{selectedBack || selectedLay}</OddsDisplay>
                  <StakeInput 
                    type="text" 
                    placeholder="Enter stake" 
                    value={stake}
                    onChange={(e) => handleStakeChange(e.target.value)}
                  />
                  <PlaceBetButton>Place Bets</PlaceBetButton>
                </ActionContainer>

                <QuickStakeContainer>
                  {quickStakes.map(stakeValue => (
                    <QuickStakeButton 
                      key={stakeValue}
                      onClick={() => handleQuickStake(stakeValue)}
                    >
                      {stakeValue}
                    </QuickStakeButton>
                  ))}
                </QuickStakeContainer>
              </BettingContainer>
            )}
          </React.Fragment>
        ))}
      </TableContainer>
    </Container>
  );
};

export default BookmakerMarket;