import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Info, Search, Eye, UserPlus, Menu, X, ChevronRight, Users, Settings, Edit2, Copy, Upload, Trash, Check } from 'lucide-react';
import Members from './Members';
import JoinClubModal from '../JoinClubModal';
import { getUID, sendNotfication } from '../../../../Helper';
import RakebackDetailsModal from '../RakebackModal';
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(50, 55, 56, 0.8);
  backdrop-filter: blur(4px);
  z-index: 1000;
  padding: 1rem;
  animation: ${fadeIn} 0.3s ease-out;
           @media (max-width: 768px) {
  padding:0;
  }
`;

const ModalCard = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 90vh;
  max-height: 800px;
  background: linear-gradient(135deg, #323738 0%, #4A5354 100%);
  border-radius: 24px;
  padding: 1.25rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(82, 236, 130, 0.1);
  animation: ${slideIn} 0.3s ease-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (min-width: 640px) {
    padding: 1.5rem;
    width:100%;
  }
  
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, #52EC82, #89E977);
    z-index: -1;
    border-radius: 24px;
    opacity: 0.2;
  }
   @media (max-width: 768px) {
   height:80vh;
    width: 100%;
      border-radius:0px;
  padding:1rem 0.55rem;
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 0.5rem;
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(82, 236, 130, 0.3);
    border-radius: 3px;
  }
         @media (max-width: 640px) {
  padding:0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -0.2rem;
  right: 1rem;
  background: none;
  border: none;
  color: rgba(137, 233, 119, 0.6);
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 10;

  &:hover {
    color: #52EC82;
    transform: scale(1.1);
  }
`;

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  margin: 0.5rem 0 1.5rem;
  background: linear-gradient(to right, #52EC82, #89E977);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(82, 236, 130, 0.3);

  @media (min-width: 640px) {
    font-size: 2rem;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  position: relative;
`;

const ClubAvatar = styled.div`
  width: 70px;
  height: 70px;
  background: #4A5354;
  border-radius: 12px;
  position: relative;
  overflow: visible;
  border: 2px solid rgba(82, 236, 130, 0.3);
  flex-shrink: 0;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
  
  .host-badge {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #52EC82, #89E977);
    color: #323738;
    padding: 2px 6px;
    font-size: 0.7rem;
    font-weight: bold;
  }
`;

const ClubInfo = styled.div`
  flex: 1;
  min-width: 0;
  
  h1 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
    color: #52EC82;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .club-id {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.8rem;
  }
  
  .welcome-text {
    color: rgba(255, 255, 255, 0.6);

    font-size: 13.5px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 0.75rem;
  
  button {
    background: #4A5354;
    border: none;
    color: #52EC82;
    cursor: pointer;
    padding: 0.4rem;
    border-radius: 50%;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
      background: rgba(82, 236, 130, 0.2);
      transform: translateY(-2px);
    }
  }
`;

const RakebackSection = styled.div`
  background: #4A5354;
  border-radius: 16px;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(82, 236, 130, 0.2);
  backdrop-filter: blur(10px);

  .button-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 1.5rem;
    align-items:cneter;

    
    @media (min-width: 768px) {
      flex-direction: row;
      gap: 1rem;
    }
  }
`;

const RakebackHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  
  h2 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .percentage {
    font-size: 1.75rem;
    font-weight: bold;
    color: #52EC82;
  }
`;

const ProgressContainer = styled.div`
  margin-top: 1.5rem;
`;

const PlayersProgress = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  
  span {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;

const PlayerMilestones = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 2px;
  margin-left: 10px;
  
  span {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.8rem;
    
    svg {
      margin-left: 4px;
    }
  }
`;

const ProgressBar = styled.div`
  height: 6px;
  background: rgba(82, 236, 130, 0.1);
  border-radius: 3px;
  position: relative;
  margin: 0.75rem 0;
  
  .progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: linear-gradient(to right, #52EC82, #89E977);
    border-radius: 3px;
    width: ${props => props.progress}%;
    transition: width 0.3s ease;
  }
`;

const ProgressLabels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  
  span {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.6);
  }
`;

const ActionButton = styled.button`
  flex: 1;
  background: linear-gradient(to right, #52EC82, #89E977);
  color: black;
  border: none;
  border-radius: 20px;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  
  @media (max-width: 768px) {
    font-size: 0.875rem;
    padding: 0.625rem;
    gap: 0.375rem;
  }
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(82, 236, 130, 0.3);
  }
`;

const MenuToggle = styled.button`
  position: fixed;
  bottom: 6.5rem;
  right: 1.5rem;
  background: linear-gradient(to right, #52EC82, #89E977);
  border: none;
  border-radius: ${props => props.isOpen ? '50px' : '50%'};
  width: ${props => props.isOpen ? '200px' : '60px'};
  height: ${props => props.isOpen ? '70px' : '60px'};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 100;
  padding: ${props => props.isOpen ? '0.5rem 1.5rem' : '0'};
  
  svg {
    color: #323738;
  }
  
  &:hover {
    transform: ${props => props.isOpen ? 'none' : 'scale(1.1)'};
  }
`;

const Card = styled.div`
  background-color: #34393A;
  padding:0.5rem 1rem;
  border-radius: 0.5rem;
    border: 1px solid rgba(82, 236, 130, 0.2);
  width: 50%;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
`;

const Title = styled.div`
  color: white;
  font-weight: 500;
  font-size:14px;
`;

const Value = styled.div`
  color: #56EC81;
  font-size:16px;
  font-weight: bold;
  margin-top: 2px;
`;
const Value2 = styled.span`
  color:#fff;
 
  font-weight: bold;
  margin-left:2px;
  margin-bottom:2px;
`;

const Detail = styled.p`
  color: #a1a1aa;
  margin-top: 0.25rem;
`;

const Container1 = styled.div`
  display: flex;
  gap:6px;
  margin:9px 0px;
`;

const InviteModalContent = styled.div`
  padding: 1.5rem;
  color: white;
`;

const InviteHeader = styled.div`
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #52EC82;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.2rem;
  
  img {
    height: 32px;
    width: auto;
    object-fit: contain;
  }
`;

const InviteInfo = styled.div`
  background: #4A5354;
  border-radius: 12px;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(82, 236, 130, 0.2);
`;

const InviteRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  
  .label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
  }
  
  .value {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    span {
      font-weight: 500;
    }
  }
`;

const EditIcon = styled.div`
  cursor: pointer;
  color: #52EC82;
  transition: transform 0.2s ease;
  
  &:hover {
    transform: scale(1.1);
  }
`;

const CancelButton = styled.button`
  flex: 1;
  background: rgba(82, 236, 130, 0.1);
  border: 1px solid #52EC82;
  color: #52EC82;
  border-radius: 20px;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    font-size: 0.875rem;
    padding: 0.625rem;
  }

  &:hover {
    background: rgba(82, 236, 130, 0.2);
    transform: translateY(-2px);
  }
`;

const EditProfileIcon = styled.div`
  position: absolute;
  bottom: -6px;
  right: -6px;
  background: #52EC82;
  padding: 4px;
  border-radius: 50%;
  color: #323738;
  cursor: pointer;
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  
  &:hover {
    transform: scale(1.1);
  }
`;

const ImageUploadContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto 1.5rem;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    border: 2px solid rgba(82, 236, 130, 0.3);
  }
  
  input {
    display: none;
  }
`;

const AdminModalContent = styled.div`
  padding: 1rem;
  color: white;
  max-height: calc(90vh - 20px);
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(82, 236, 130, 0.3);
    border-radius: 3px;
  }
`;

const AdminOption = styled.div`
  background: #4A5354;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid rgba(82, 236, 130, 0.2);
  
  &:hover {
    background: #5a6364;
    transform: translateY(-2px);
  }
`;

const NotificationInput = styled.input`
  width: 100%;
  background: #323738;
  border: 1px solid rgba(82, 236, 130, 0.2);
  border-radius: 8px;
  padding: 0.75rem;
  color: white;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const NotificationTextarea = styled.textarea`
  width: 100%;
  background: #323738;
  border: 1px solid rgba(82, 236, 130, 0.2);
  border-radius: 8px;
  padding: 0.75rem;
  color: white;
  font-size: 1rem;
  min-height: 100px;
  resize: vertical;
`;

const BannerUploadContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
`;

const BannerUpload = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  background: #323738;
  border: 2px dashed rgba(82, 236, 130, 0.3);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .upload-text {
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  .upload-hint {
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
  
  &:hover {
    border-color: #52EC82;
    background: #3a4041;
  }
`;

const Tabs = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
`;

const Tab = styled.div`
    padding: 0.5rem 1rem;
    border-radius: 8px;
    cursor: pointer;
    color: ${props => props.active ? '#52EC82' : 'white'};
    background: ${props => props.active ? 'rgba(82, 236, 130, 0.1)' : 'transparent'};
    border: 1px solid ${props => props.active ? '#52EC82' : 'rgba(255, 255, 255, 0.1)'};
    
    &:hover {
        border-color: #52EC82;
    }
`;

const BannerList = styled.div`
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const BannerItem = styled.div`
    background: #323738;
    border: 1px solid rgba(82, 236, 130, 0.2);
    border-radius: 12px;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
    transition: all 0.3s ease;
    
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        border-color: rgba(82, 236, 130, 0.4);
    }
`;

const BannerPreview = styled.div`
    width: 100%;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
    flex-shrink: 0;
    border: 1px solid rgba(82, 236, 130, 0.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const BannerInfo = styled.div`
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    
    h4 {
        margin: 0;
        color: #52EC82;
        font-size: 1.1rem;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .dates {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 0.85rem;
        
        div {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: rgba(255, 255, 255, 0.7);
            
            .label {
                color: rgba(255, 255, 255, 0.5);
                min-width: 70px;
            }
            
            .date {
                color: #fff;
                font-weight: 500;
            }
            
            .time {
                color: rgba(255, 255, 255, 0.7);
                margin-left: 0.5rem;
            }
        }
    }
`;

const BannerActions = styled.div`
    display: flex;
    gap: 0.75rem;
    margin-left: auto;
    padding-left: 1rem;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    align-self: stretch;
    
    input[type="file"] {
        display: none;
    }
    
    button {
        background: none;
        border: none;
        padding: 0.5rem;
        cursor: pointer;
        border-radius: 6px;
        transition: all 0.2s ease;
        
        &:hover {
            background: rgba(255, 255, 255, 0.1);
        }
        
        &.edit {
            color: #52EC82;
        }
        
        &.delete {
            color: #ff6b6b;
        }
    }
`;

const CreateBannerForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background: #3a4041;
    border-radius: 12px;
    margin-top: 1rem;
`;

const BannerInput = styled.input`
    width: 100%;
    background: #323738;
    border: 1px solid rgba(82, 236, 130, 0.2);
    border-radius: 8px;
    padding: 0.75rem;
    color: white;
    font-size: 1rem;
    
    &:focus {
        outline: none;
        border-color: #52EC82;
    }
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  color: #52EC82;
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  
  &:hover {
    color: #3cbd63;
  }
`;


const CreateOwnClubModal = ({ isOpen, onClose, userData }) => {
    const [players, setPlayers] = useState(2);
    const [currentRakeback, setCurrentRakeback] = useState(20);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isHost, setIsHost] = useState(true);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [inviteCode, setInviteCode] = useState('');
    const [showProfileModal, setShowProfileModal] = useState(false);
    // const [profileImage, setProfileImage] = useState("https://img.freepik.com/premium-vector/bearded-king-with-crown-his-head-logo-royal-king-symbol_939711-964.jpg");
    // const [clubName, setClubName] = useState(isHost ? 'Club_hnitzxljou' : 'Agent_x9k2m');
    const [showAdminModal, setShowAdminModal] = useState(false);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [showPromotionsModal, setShowPromotionsModal] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationContent, setNotificationContent] = useState('');
    const [affdata, setAffData] = useState(null);

    const [copyStatus, setCopyStatus] = useState({
        inviteId: false,
        shareLink: false
    });

    const handleCopy = async (text, type) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopyStatus(prev => ({ ...prev, [type]: true }));

            // Reset the copy status after 2 seconds
            setTimeout(() => {
                setCopyStatus(prev => ({ ...prev, [type]: false }));
            }, 2000);
        } catch (err) {
            console.error('Failed to copy text:', err);
        }
    };

    const [promotionBanners, setPromotionBanners] = useState([
        { id: 1, image: null, createdAt: null, updatedAt: null, title: '' },
        { id: 2, image: null, createdAt: null, updatedAt: null, title: '' },
        { id: 3, image: null, createdAt: null, updatedAt: null, title: '' }
    ]);
    const [rackbackOpen, setOpenRakeback] = useState(false)
    const [clubData, setClubData] = useState(null);
    const [loading, setLoading] = useState(true);
    // Initialize state with club data
    const [clubName, setClubName] = useState('');
    const [uniqueClubId, setUniqueClubId] = useState('');
    const [profileImage, setProfileImage] = useState('');
    // useEffect(() => {
    //     if (userData?.uid) {
    //         fetchClubData();
    //     }
    // }, [userData]);

    const fetchClubData = async () => {
        try {
            // Replace with your actual API endpoint
            const response = await fetch(`https://api.skyla.game/clubmembership/userprofile/${userData?.uid}`);
            const data = await response.json();

            // Update state with fetched data
            setClubData(data);
            setClubName(data.name || 'Club_hnitzxljou');
            setUniqueClubId(data.unique_club_id || '');
            setInviteCode(data.unique_club_id || '');
            // Handle profile picture if it exists in base64 format
            if (data.profile_picture_data?.data) {
                setProfileImage(`data:image/png;base64,${data.profile_picture_data.data}`);
            } else {
                setProfileImage("https://img.freepik.com/premium-vector/bearded-king-with-crown-his-head-logo-royal-king-symbol_939711-964.jpg");
            }

            setLoading(false);
        } catch (error) {
            console.error('Error fetching club data:', error);
            setLoading(false);
        }
    };
    useEffect(() => {
        if (userData?.uid) {
            fetchClubData();
            fetchUserAffiliations();
        }
    }, [userData]);
    const fetchUserAffiliations = async () => {
        try {
            const response = await fetch(`https://api.skyla.game/clubmembership/user-affiliations/${getUID}`);
            const data = await response.json();
            if (data.success) {
                setAffData(data.data);
            } else {
                console.error('Failed to fetch user affiliations:', data);
            }
        } catch (error) {
            console.error('Error fetching user affiliations:', error);
        }
    };
    const updateProfileDetails = async (imageFile, name) => {
        try {
            const formData = new FormData();

            // Add existing values from clubData
            formData.append('clubId', clubData.id);
            formData.append('clubName', name);
            formData.append('ownerEarningsPercentage', clubData.owner_earnings_percentage);
            formData.append('agentEarningsPercentage', clubData.agent_earnings_percentage);
            formData.append('memberEarningsPercentage', clubData.member_earnings_percentage);

            // Only append file if a new image was uploaded
            if (imageFile) {
                formData.append('profilePicture', imageFile);
            }

            const response = await fetch('https://api.skyla.game/clubmembership/update', {
                method: 'PUT',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to update profile');
            }

            const updatedData = await response.json();
            return updatedData;
        } catch (error) {
            console.error('Error updating profile:', error);
            throw error;
        }
    };
    console.log("user data$$$$$$", userData)

    const defaultImages = {
        host: "https://img.freepik.com/premium-vector/bearded-king-with-crown-his-head-logo-royal-king-symbol_939711-964.jpg",
        agent: "https://img.freepik.com/premium-vector/businessman-avatar-cartoon-character-profile_18591-50581.jpg"
    };

    const [profileImages, setProfileImages] = useState({
        host: defaultImages.host,
        agent: defaultImages.agent
    });

    const getProgressPercentage = useCallback((players) => {
        if (players <= 2) return 12;   // Scaled from 20 to 6
        if (players <= 5) return 18;  // Scaled from 40 to 12
        if (players <= 10) return 22.5; // Scaled from 55 to 16.5
        if (players <= 20) return 27; // Scaled from 70 to 21
        if (players <= 50) return 30; // Scaled from 80 to 24
        if (players <= 100) return 33; // Scaled from 90 to 27
        if (players <= 200) return 34.5; // Scaled from 95 to 28.5
        return 36; // Scaled from 100 to 30
    }, []);



    const handleOverlayClick = useCallback((e) => {

        console.log("commmm", userData)
        if (e.target === e.currentTarget) {
            onClose();
            setIsMenuOpen(false);
        }
    }, [onClose]);

    const handleSwapRole = useCallback(() => {
        setIsHost(prev => !prev);
        setProfileImage(prev => prev === profileImages.agent ? profileImages.host : profileImages.agent);
    }, [profileImages]);


    const handleImageChange = useCallback((e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImage(reader.result);
            };
            reader.readAsDataURL(file);
            // Store the actual file for upload
            setNewProfileImage(file);
        }
    }, []);

    const [newProfileImage, setNewProfileImage] = useState(null);

    const handleProfileUpdate = useCallback(async () => {
        try {
            // Show loading state
            setLoading(true);

            // Update profile with new data
            await updateProfileDetails(newProfileImage, clubName);

            // Refresh club data to get latest changes
            await fetchClubData();


            // Close the modal
            setShowProfileModal(false);

            // Reset the new image state
            setNewProfileImage(null);
        } catch (error) {
            console.error('Error updating profile:', error);
            // Handle error (show toast, etc.)
        } finally {
            setLoading(false);
        }
    }, [clubName, newProfileImage]);

    const handleJoin = async (result) => {
        console.log(result);
        await fetchUserAffiliations(); // Refetch affiliations data
        setIsModalOpen(false); // Close the join modal
        sendNotfication("Joined club succesfully");
    };
    const modalStyle = useMemo(() => ({ maxWidth: '500px' }), []);
    const profileModalStyle = useMemo(() => ({ maxWidth: '400px' }), []);

    const menuToggleContent = useMemo(() => (
        isMenuOpen ? (
            <>
                <div onClick={() => setIsActive(true)}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "4px",
                        padding: "0.25rem"
                    }}>
                    <Users size={20} />
                    <div style={{
                        fontSize: "12px",
                        color: "#323738",
                        fontWeight: "500"
                    }}>Members</div>
                </div>

                {isHost && (
                    <div onClick={() => setShowAdminModal(true)}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "4px",
                            padding: "0.25rem"
                        }}>
                        <Settings size={20} />
                        <div style={{
                            fontSize: "12px",
                            color: "#323738",
                            fontWeight: "500"
                        }}>Admin</div>
                    </div>
                )}
            </>
        ) : (
            <Menu size={20} />
        )
    ), [isMenuOpen, isHost]);

    const [activePromotionTab, setActivePromotionTab] = useState('create'); // 'create' or 'list'
    const [newBanner, setNewBanner] = useState({
        image: null,
        title: '',
        createdAt: null,
        updatedAt: null
    });

    const handleDeleteClub = () => {
        if (window.confirm('Are you sure you want to delete this club? This action cannot be undone.')) {
            // Add your delete club logic here
            console.log('Club deleted'); // Placeholder for actual delete logic
            onClose(); // Close the modal after deletion
        }
    };

    if (!isOpen) return null;

    return (

        <>
            {
                isOpen && !loading && (
                    <ModalOverlay onClick={handleOverlayClick}>
                        <ModalCard>
                            <CloseButton onClick={onClose}>
                                <X size={20} />
                            </CloseButton>

                            {/* <ModalTitle>Join a club</ModalTitle> */}
                            <Container>
                                <Header>
                                    <ClubAvatar>
                                        <div className="host-badge">{isHost ? 'Host' : 'Agent'}</div>
                                        <img
                                            src={profileImage}
                                            alt={isHost ? "Club avatar" : "Agent avatar"}
                                        />
                                        <EditProfileIcon onClick={() => setShowProfileModal(true)}>
                                            <Edit2 size={16} />
                                        </EditProfileIcon>
                                    </ClubAvatar>
                                    <ClubInfo>
                                        <div style={{ color: 'white', fontSize: '18px', fontWeight: 'bold' }}>
                                            {clubName}
                                        </div>
                                        <div className="club-id">
                                            {isHost ? `Club ID: ${uniqueClubId}` : `Agent ID: ${uniqueClubId}`}
                                        </div>
                                        <div className="welcome-text">
                                            {isHost ? 'Welcome to the Club!' : 'Welcome Agent!'}
                                        </div>
                                    </ClubInfo>
                                    {affdata?.isAgent && (
                                        <ActionButtons>
                                            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                                                <div onClick={() => setOpenRakeback(true)}><Info color="#43D407" size={26} /></div>
                                                <img
                                                    src={"/assets/images/swapskyla.png"}
                                                    style={{
                                                        height: "26px",
                                                        width: "26px",
                                                        objectFit: "contain",
                                                        cursor: "pointer",
                                                        transition: "transform 0.3s ease",
                                                        transform: isHost ? "rotate(0deg)" : "rotate(180deg)"
                                                    }}
                                                    alt="swap"
                                                    onClick={handleSwapRole}
                                                />
                                            </div>
                                        </ActionButtons>
                                    )}
                                </Header>

                                <RakebackSection>
                                    <RakebackHeader>
                                        <h2>
                                            {isHost ? 'Club Rakeback' : 'Agent Commission'}
                                            <Eye size={18} />
                                        </h2>
                                        <div className="percentage">{clubData.clubrake}%</div>
                                    </RakebackHeader>
                                    <ProgressContainer>
                                        <PlayersProgress>
                                            <span>Players </span>
                                            <PlayerMilestones>
                                                {/* <span>0<Users size={12} /></span> */}
                                                <span style={{ style: 'fontSize:"10px' }}>5<Users style={{ marginLeft: "-3px", color: "#52EC82" }} size={12} /></span>
                                                <span style={{ style: "fontSize:10px", marginLeft: "10px" }}>15<Users style={{ marginLeft: "-3px", color: "#52EC82" }} size={12} /></span>
                                                <span style={{ style: "fontSize:10px", marginLeft: "10px" }}>50<Users style={{ marginLeft: "-3px", color: "#52EC82" }} size={12} /></span>
                                                <span style={{ style: "fontSize:10px", marginLeft: "10px" }}>100<Users style={{ marginLeft: "-3px", color: "#52EC82" }} size={12} /></span>
                                                <span style={{ style: "fontSize:10px" }}>200<Users style={{ marginLeft: "-3px", color: "#52EC82" }} size={12} /></span>
                                                <span style={{ style: "fontSize:10px" }}>300<Users style={{ marginLeft: "-3px", color: "#52EC82" }} size={12} /></span>

                                            </PlayerMilestones>
                                        </PlayersProgress>

                                        <ProgressBar max={30} progress={getProgressPercentage(clubData.total_users)}>
                                            <div className="progress" />
                                        </ProgressBar>

                                        <ProgressLabels>
                                            <span style={{ style: "fontSize:10px" }}>0%</span>
                                            <span style={{ style: "fontSize:10px" }}>10%</span>
                                            <span style={{ style: "fontSize:10px" }}>15%</span>
                                            <span style={{ style: "fontSize:10px" }}>20%</span>
                                            <span style={{ style: "fontSize:10px" }}>25%</span>
                                            <span style={{ style: "fontSize:10px" }}>27%</span>
                                            <span style={{ style: "fontSize:10px" }}>30%</span>
                                        </ProgressLabels>
                                    </ProgressContainer>

                                    <Container1>
                                        <Card onClick={() => setIsActive(true)}>
                                            <Title>Active Players</Title>
                                            <Value>0/<Value2>{clubData.total_users}</Value2></Value>
                                            <Detail>Past 7 days</Detail>
                                        </Card>
                                        <Card>
                                            <Title>Total Rakeback</Title>
                                            <Value>$0</Value>
                                            <Detail>Yesterday: $0</Detail>
                                        </Card>
                                    </Container1>

                                    <div className="button-container">


                                        <ActionButton onClick={() => setShowInviteModal(true)}>
                                            Invite players
                                            <ChevronRight size={18} />
                                        </ActionButton>


                                    </div>
                                    <div style={{ textAlign: 'center', margin: '12px 0px' }}>Invite Players to earn more rakeback</div>
                                    {!affdata?.isAgent && !affdata?.isMember && (

                                        <div className="button-container">
                                            <ActionButton onClick={() => setIsModalOpen(true)}>
                                                Join Club
                                                <ChevronRight size={18} />
                                            </ActionButton>
                                        </div>
                                    )}

                                    <JoinClubModal
                                        isOpen={isModalOpen}
                                        onClose={() => setIsModalOpen(false)}
                                        onJoin={handleJoin}
                                    />


                                </RakebackSection>

                                <MenuToggle
                                    isOpen={isMenuOpen}
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    {menuToggleContent}
                                </MenuToggle>
                            </Container>
                        </ModalCard>
                    </ModalOverlay>
                )
            }

            {
                isActive && (
                    <ModalOverlay>
                        <ModalCard>
                            <CloseButton onClick={() => setIsActive(false)}>
                                <X size={20} />
                            </CloseButton>
                            <ModalTitle>Member Details</ModalTitle>
                            <Members role={isHost ? 'Club' : 'Agent'} clubid={clubData.id} />
                        </ModalCard>
                    </ModalOverlay>
                )
            }

            {showInviteModal && (
                <ModalOverlay onClick={() => setShowInviteModal(false)}>
                    <ModalCard onClick={e => e.stopPropagation()}>
                        <CloseButton onClick={() => setShowInviteModal(false)}>
                            <X size={20} />
                        </CloseButton>

                        <InviteModalContent>
                            <LogoContainer>
                                <img src="/assets/images/lg.png" alt="Logo" />
                            </LogoContainer>
                            <InviteHeader>Invite Players</InviteHeader>


                            <InviteInfo>
                                <InviteRow>
                                    <div className="label">
                                        {isHost ? 'Invite ID' : 'Invite ID'}
                                    </div>
                                    <div className="value">
                                        <span>{clubData.unique_club_id}</span>
                                        <CopyButton onClick={() => handleCopy(clubData.unique_club_id, 'inviteId')}>
                                            {copyStatus.inviteId ? <Check size={18} /> : <Copy size={18} />}
                                        </CopyButton>
                                    </div>
                                </InviteRow>

                                <InviteRow>
                                    <div className="label">Share Link</div>
                                    <div className="value">
                                        <span>{`https://skyla.game/${isHost ? 'club' : 'agent'}/${inviteCode}`}</span>
                                        <CopyButton
                                            onClick={() => handleCopy(
                                                `https://skyla.game/${isHost ? 'club' : 'agent'}/${inviteCode}`,
                                                'shareLink'
                                            )}
                                        >
                                            {copyStatus.shareLink ? <Check size={18} /> : <Copy size={18} />}
                                        </CopyButton>
                                    </div>
                                </InviteRow>
                            </InviteInfo>

                            <div style={{ textAlign: 'center', marginTop: '2rem', display: "flex", justifyContent: "center" }}>
                                <ActionButton onClick={() => setShowInviteModal(false)}>
                                    Done
                                </ActionButton>
                            </div>
                        </InviteModalContent>
                    </ModalCard>
                </ModalOverlay>
            )}

            {showEditModal && (
                <ModalOverlay onClick={() => setShowEditModal(false)}>
                    <ModalCard onClick={e => e.stopPropagation()} style={{ maxWidth: '400px' }}>
                        <CloseButton onClick={() => setShowEditModal(false)}>
                            <X size={20} />
                        </CloseButton>

                        <InviteModalContent>
                            <LogoContainer>
                                <img src="/assets/images/lg.png" alt="Logo" />
                            </LogoContainer>
                            <InviteHeader>
                                Edit {isHost ? 'Club' : 'Agent'} ID
                            </InviteHeader>



                            <InviteInfo>
                                <input
                                    type="text"
                                    value={inviteCode}
                                    onChange={(e) => setInviteCode(e.target.value)}
                                    style={{
                                        width: '100%',
                                        background: '#323738',
                                        border: '1px solid rgba(82, 236, 130, 0.2)',
                                        borderRadius: '8px',
                                        padding: '0.75rem',
                                        color: 'white',
                                        fontSize: '1rem'
                                    }}
                                />
                            </InviteInfo>

                            <div style={{
                                display: 'flex',
                                gap: '1rem',
                                marginTop: '1.5rem',
                                padding: '0 1rem',
                                '@media (max-width: 768px)': {
                                    padding: '0 0.5rem',
                                    gap: '0.75rem'
                                }
                            }}>
                                <CancelButton onClick={() => setShowEditModal(false)}>
                                    Cancel
                                </CancelButton>
                                <ActionButton onClick={() => {
                                    // Handle save logic here
                                    setShowEditModal(false);
                                }}>
                                    Save Changes
                                </ActionButton>
                            </div>
                        </InviteModalContent>
                    </ModalCard>
                </ModalOverlay>
            )}

            {showProfileModal && (
                <ModalOverlay onClick={() => setShowProfileModal(false)}>
                    <ModalCard onClick={e => e.stopPropagation()} style={{ maxWidth: '400px' }}>
                        <CloseButton onClick={() => setShowProfileModal(false)}>
                            <X size={20} />
                        </CloseButton>

                        <InviteModalContent>
                            <LogoContainer>
                                <img src="/assets/images/lg.png" alt="Logo" />
                            </LogoContainer>
                            <InviteHeader>
                                Edit {isHost ? 'Club' : 'Agent'} Profile
                            </InviteHeader>



                            <InviteInfo>
                                <ImageUploadContainer>
                                    <img src={profileImage} alt="Profile" />
                                    <EditProfileIcon
                                        as="label"
                                        htmlFor="imageUpload"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Edit2 size={16} />
                                    </EditProfileIcon>
                                    <input
                                        id="imageUpload"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </ImageUploadContainer>

                                <input
                                    type="text"
                                    value={clubName}
                                    onChange={(e) => setClubName(e.target.value)}
                                    placeholder={`Enter ${isHost ? 'Club' : 'Agent'} Name`}
                                    style={{
                                        width: '100%',
                                        background: '#323738',
                                        border: '1px solid rgba(82, 236, 130, 0.2)',
                                        borderRadius: '8px',
                                        padding: '0.75rem',
                                        color: 'white',
                                        fontSize: '1rem',
                                        marginTop: '1rem'
                                    }}
                                />
                            </InviteInfo>

                            <div style={{
                                display: 'flex',
                                gap: '1rem',
                                marginTop: '1.5rem',
                                padding: '0 1rem',
                                '@media (max-width: 768px)': {
                                    padding: '0 0.5rem',
                                    gap: '0.75rem'
                                }
                            }}>
                                <CancelButton
                                    onClick={() => {
                                        setShowProfileModal(false);
                                        setNewProfileImage(null);
                                    }}
                                    disabled={loading}
                                >
                                    Cancel
                                </CancelButton>
                                <ActionButton
                                    onClick={handleProfileUpdate}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save Changes'}
                                </ActionButton>
                            </div>
                        </InviteModalContent>
                    </ModalCard>
                </ModalOverlay>
            )}

            {showAdminModal && (
                <ModalOverlay onClick={() => setShowAdminModal(false)}>
                    <ModalCard onClick={e => e.stopPropagation()} style={{ maxWidth: '500px' }}>
                        <CloseButton onClick={() => setShowAdminModal(false)}>
                            <X size={20} />
                        </CloseButton>

                        <AdminModalContent>
                            <LogoContainer>
                                <img src="/assets/images/lg.png" alt="Logo" />
                            </LogoContainer>

                            <InviteHeader>Admin Settings</InviteHeader>

                            <AdminOption onClick={() => setShowNotificationModal(true)}>
                                <h3 style={{ marginBottom: '0.5rem' }}>Club Notifications</h3>
                                <p style={{ color: 'rgba(255,255,255,0.6)', fontSize: '0.9rem' }}>
                                    Send notifications to club members
                                </p>
                            </AdminOption>

                            <AdminOption onClick={() => setShowPromotionsModal(true)}>
                                <h3 style={{ marginBottom: '0.5rem' }}>Club Promotions</h3>
                                <p style={{ color: 'rgba(255,255,255,0.6)', fontSize: '0.9rem' }}>
                                    Manage promotional banners
                                </p>
                            </AdminOption>

                            <AdminOption onClick={handleDeleteClub}>
                                <h3 style={{ marginBottom: '0.5rem' }}>Delete Club</h3>
                                <p style={{ color: 'rgba(255,255,255,0.6)', fontSize: '0.9rem' }}>
                                    Permanently delete this club
                                </p>
                                {/* <Trash size={20} style={{ color: 'rgba(255, 0, 0, 0.8)', cursor: 'pointer' }} /> */}
                            </AdminOption>
                        </AdminModalContent>
                    </ModalCard>
                </ModalOverlay>
            )}

            {showNotificationModal && (
                <ModalOverlay onClick={() => setShowNotificationModal(false)}>
                    <ModalCard onClick={e => e.stopPropagation()} style={{ maxWidth: '500px' }}>
                        <CloseButton onClick={() => setShowNotificationModal(false)}>
                            <X size={20} />
                        </CloseButton>

                        <AdminModalContent>
                            <LogoContainer>
                                <img src="/assets/images/lg.png" alt="Logo" />
                            </LogoContainer>

                            <InviteHeader>Club Notification</InviteHeader>


                            <NotificationInput
                                placeholder="Notification Title"
                                value={notificationTitle}
                                onChange={(e) => setNotificationTitle(e.target.value)}
                            />

                            <NotificationTextarea
                                placeholder="Notification Content"
                                value={notificationContent}
                                onChange={(e) => setNotificationContent(e.target.value)}
                            />

                            <div style={{
                                display: 'flex',
                                gap: '1rem',
                                marginTop: '1.5rem',
                                padding: '0 1rem',
                                '@media (max-width: 768px)': {
                                    padding: '0 0.5rem',
                                    gap: '0.75rem'
                                }
                            }}>
                                <CancelButton onClick={() => setShowNotificationModal(false)}>
                                    Cancel
                                </CancelButton>
                                <ActionButton onClick={() => {
                                    // Handle notification send logic here
                                    setShowNotificationModal(false);
                                }}>
                                    Send Notification
                                </ActionButton>
                            </div>
                        </AdminModalContent>
                    </ModalCard>
                </ModalOverlay>
            )}

            {showPromotionsModal && (
                <ModalOverlay onClick={() => setShowPromotionsModal(false)}>
                    <ModalCard onClick={e => e.stopPropagation()} style={{ maxWidth: '600px' }}>
                        <CloseButton onClick={() => setShowPromotionsModal(false)}>
                            <X size={20} />
                        </CloseButton>

                        <AdminModalContent>
                            <LogoContainer>
                                <img src="/assets/images/lg.png" alt="Logo" />
                            </LogoContainer>
                            <InviteHeader>Club Promotions</InviteHeader>


                            <Tabs>
                                <Tab
                                    active={activePromotionTab === 'create'}
                                    onClick={() => setActivePromotionTab('create')}
                                >
                                    Create Banner
                                </Tab>
                                <Tab
                                    active={activePromotionTab === 'list'}
                                    onClick={() => setActivePromotionTab('list')}
                                >
                                    Banner List
                                </Tab>
                            </Tabs>

                            {activePromotionTab === 'create' ? (
                                <div>
                                    <BannerUpload htmlFor="new-banner-upload">
                                        {newBanner.image ? (
                                            <img src={newBanner.image} alt="New banner" />
                                        ) : (
                                            <>
                                                <Upload size={32} color="#52EC82" />
                                                <div className="upload-text">Upload Banner Image</div>
                                                <div className="upload-hint">Recommended size: 1200x600px</div>
                                            </>
                                        )}
                                        <input
                                            id="new-banner-upload"
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file) {
                                                    const reader = new FileReader();
                                                    reader.onloadend = () => {
                                                        setNewBanner({
                                                            image: reader.result,
                                                            title: '',
                                                            createdAt: new Date().toISOString(),
                                                            updatedAt: new Date().toISOString()
                                                        });
                                                    };
                                                    reader.readAsDataURL(file);
                                                }
                                            }}
                                        />
                                    </BannerUpload>

                                    {newBanner.image && (
                                        <CreateBannerForm>
                                            <BannerInput
                                                type="text"
                                                placeholder="Enter banner title"
                                                value={newBanner.title}
                                                onChange={(e) => setNewBanner(prev => ({
                                                    ...prev,
                                                    title: e.target.value
                                                }))}
                                            />
                                            <ActionButton
                                                onClick={() => {
                                                    if (!newBanner.title.trim()) {
                                                        alert('Please enter a banner title');
                                                        return;
                                                    }
                                                    setPromotionBanners(prev => [...prev, {
                                                        id: Date.now(),
                                                        ...newBanner
                                                    }]);
                                                    setNewBanner({
                                                        image: null,
                                                        title: '',
                                                        createdAt: null,
                                                        updatedAt: null
                                                    });
                                                    setActivePromotionTab('list');
                                                }}
                                            >
                                                Create Banner
                                            </ActionButton>
                                        </CreateBannerForm>
                                    )}
                                </div>
                            ) : (
                                <BannerList>
                                    {promotionBanners
                                        .filter(banner => banner.image)
                                        .map((banner, index) => (
                                            <BannerItem key={banner.id}>
                                                <BannerPreview>
                                                    <img src={banner.image} alt={banner.title} />
                                                </BannerPreview>
                                                <BannerInfo>
                                                    {/* <h4 title={banner.title}>{banner.title}</h4> */}
                                                    <div className="dates">
                                                        <div>
                                                            <span className="label">Created:</span>
                                                            <span className="date">
                                                                {new Date(banner.createdAt).toLocaleDateString()}
                                                            </span>
                                                            <span className="time">
                                                                {new Date(banner.createdAt).toLocaleTimeString([], {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit'
                                                                })}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="label">Updated:</span>
                                                            <span className="date">
                                                                {new Date(banner.updatedAt).toLocaleDateString()}
                                                            </span>
                                                            <span className="time">
                                                                {new Date(banner.updatedAt).toLocaleTimeString([], {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit'
                                                                })}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </BannerInfo>
                                                <BannerActions>
                                                    <input
                                                        type="file"
                                                        id={`edit-banner-${banner.id}`}
                                                        accept="image/*"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            if (file) {
                                                                const reader = new FileReader();
                                                                reader.onloadend = () => {
                                                                    setPromotionBanners(prev =>
                                                                        prev.map(b =>
                                                                            b.id === banner.id
                                                                                ? {
                                                                                    ...b,
                                                                                    image: reader.result,
                                                                                    updatedAt: new Date().toISOString()
                                                                                }
                                                                                : b
                                                                        )
                                                                    );
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }
                                                        }}
                                                    />
                                                    <button
                                                        className="edit"
                                                        onClick={() => {
                                                            document.getElementById(`edit-banner-${banner.id}`).click();
                                                        }}
                                                    >
                                                        <Edit2 size={18} />
                                                    </button>
                                                    <button
                                                        className="delete"
                                                        onClick={() => {
                                                            if (window.confirm('Are you sure you want to delete this banner?')) {
                                                                setPromotionBanners(prev =>
                                                                    prev.filter(b => b.id !== banner.id)
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <X size={18} />
                                                    </button>
                                                </BannerActions>
                                            </BannerItem>
                                        ))}
                                </BannerList>
                            )}
                        </AdminModalContent>
                    </ModalCard>
                </ModalOverlay>
            )}
            <RakebackDetailsModal
                isOpen={rackbackOpen}
                onClose={() => setOpenRakeback(false)}
            />
        </>
    );
};

export default React.memo(CreateOwnClubModal);