import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getUID } from '../../../../Helper';

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  background: #1a1d21;
  border-radius: 10px;
`;

const DataTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Montserrat, sans-serif;
  border: 1px solid #2c3137;
  border-radius: 10px;
  overflow: hidden;
  background: #1a1d21;
  min-width: 600px; // Prevents table from becoming too squeezed

  th {
    padding: 12px 16px;
    font-weight: 600;
    border-bottom: 1px solid #2c3137;
    color: #ffffff90;
    background: #22262a;
    text-align: left;
    white-space: nowrap;

    @media (max-width: 768px) {
      padding: 8px 12px;
      font-size: 0.9rem;
    }
  }

  td {
    padding: 12px 16px;
    border-bottom: 1px solid #2c3137;
    color: #ffffff;

    @media (max-width: 768px) {
      padding: 8px 12px;
      font-size: 0.9rem;
    }
  }

  tbody tr:hover {
    background-color: #2c3137;
    transition: background-color 0.2s;
  }

  .amount-positive {
    color: #4caf50;
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
  }

  img {
    border-radius: 10px;
    filter: grayscale(1) brightness(0.6);
    margin-bottom: 20px;
    width: 200px;
    height: 200px;

    @media (max-width: 768px) {
      width: 140px;
      height: 140px;
      margin-bottom: 16px;
    }
  }

  .empty-text {
    color: #ffffff90;
    font-size: 1.1em;
    line-height: 1.5;

    @media (max-width: 768px) {
      font-size: 0.95em;
    }
  }
`;

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #ffffff90;

  @media (max-width: 768px) {
    padding: 20px;
    font-size: 0.95em;
  }
`;

const CommissionHistory = () => {
  const [commissions, setCommissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCommissionHistory = async () => {
      try {
        const referralResponse = await fetch(`https://api.skyla.game/affiliate/referral-info/${getUID}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const referralData = await referralResponse.json();
        if (referralData.referralCode) {
          const rewardsResponse = await axios.get(`https://api.skyla.game/affiliate/rewards/${referralData.referralCode}`);
          const commissionRewards = rewardsResponse.data.rewards.filter(
            reward => reward.type === 'commission'
          );
          setCommissions(commissionRewards);
        }
      } catch (err) {
        setError('Failed to fetch commission history');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchCommissionHistory();
  }, []);

  if (loading) {
    return <LoadingSpinner>Loading...</LoadingSpinner>;
  }

  if (error) {
    return <EmptyState>
      <div className="empty-text">{error}</div>
    </EmptyState>;
  }

  if (!commissions || commissions.length === 0) {
    return (
      <EmptyState>
        <img
          src="/assets/images/bcgames/affiliate/empty.webp"
          alt="crying-lion"
          width={200}
          height={200}
        />
        <div className="empty-text">
          No rewards yet. <br /> Invite friends to join you now!
        </div>
      </EmptyState>
    );
  }

  return (
    <TableWrapper>
      <DataTable>
        <thead>
          <tr>
            <th>Member Name</th>
            <th>Date</th>
            <th>Referral Code</th>
            <th style={{ textAlign: 'right' }}>Amount (SBC)</th>
          </tr>
        </thead>
        <tbody>
          {commissions.map((commission, index) => (
            <tr key={index}>
              <td>{commission.membername}</td>
              <td>{new Date(commission.createdAt).toLocaleDateString()}</td>
              <td>{commission.referalCode}</td>
              <td style={{ textAlign: 'right' }}>
                <span className="amount-positive">
                  {commission.amount.toFixed(8)}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </DataTable>
    </TableWrapper>
  );
};

export default CommissionHistory;