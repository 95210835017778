"use client"

import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import { ArrowDown, ArrowUp, Minus } from 'lucide-react'

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: system-ui, sans-serif;
  background: white;
//   border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
`

const TableHeader = styled.thead`
  background-color: #1a2b3c;
  color: white;
`

const TableHeaderCell = styled.th`
  padding: 12px 16px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
`

const TableRow = styled.tr`
  border-bottom: 1px solid #eee;
  background-color: ${props => {
    switch(props.status) {
      case 'won': return '#e6f4ea';
      case 'lost': return '#fce8e8';
      default: return 'white';
    }
  }};
  
  &:hover {
    background-color: ${props => {
      switch(props.status) {
        case 'won': return '#d2ebd8';
        case 'lost': return '#f8d7d7';
        default: return '#f5f5f5';
      }
    }};
  }
`

const TableCell = styled.td`
  padding: 12px 16px;
  font-size: 14px;
  color: #333;
`

const BetType = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${props => {
    switch(props.type) {
      case 'back': return '#e3f2fd';
      case 'lay': return '#fce4ec';
      default: return '#f3e5f5';
    }
  }};
  color: ${props => {
    switch(props.type) {
      case 'back': return '#1565c0';
      case 'lay': return '#c2185b';
      default: return '#7b1fa2';
    }
  }};
`

const Status = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${props => {
    switch(props.status) {
      case 'won': return '#e6f4ea';
      case 'lost': return '#fce8e8';
      default: return '#f5f5f5';
    }
  }};
  color: ${props => {
    switch(props.status) {
      case 'won': return '#1b873b';
      case 'lost': return '#d32f2f';
      default: return '#666';
    }
  }};
`

const GameType = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;
`

const BetDirection = styled.span`
  color: ${props => props.type === 'back' ? '#1b873b' : '#d32f2f'};
  display: inline-flex;
  align-items: center;
  gap: 4px;
`

export default function BettingHistoryTable() {
  const betsData = {
    "success": true,
    "bets": [
      {
        "id": 9,
        "user_id": 4738793166,
        "game_type": "cricket",
        "match_title": "IPL 2024: MI vs CSK",
        "team_one": "Mumbai Indians",
        "team_two": "Chennai Super Kings",
        "selection_name": "Mumbai Indians",
        "category": "Match Winner",
        "bet_type": "back",
        "odds": 1.95,
        "stake_amount": 100,
        "original_currency": "inr",
        "original_amount": 100,
        "usd_amount": 100,
        "liability": 95,
        "status": "won",
        "match_start_time": "2024-12-24T14:00:00.000Z",
        "match_end_time": "2024-12-24T18:00:00.000Z",
        "result_declared_time": "2024-12-25T20:28:46.565Z",
        "created_at": "2024-12-22T19:38:32.272Z",
        "updated_at": "2024-12-25T20:26:56.961Z",
        "username": "mitanashgambhir",
        "converted_amount": 100
      },
      {
        "id": 10,
        "user_id": 4829664393,
        "game_type": "football",
        "match_title": "Premier League: Arsenal vs Liverpool",
        "team_one": "Arsenal",
        "team_two": "Liverpool",
        "selection_name": "Over 2.5",
        "category": "Goals",
        "bet_type": "lay",
        "odds": 1.85,
        "stake_amount": 200,
        "original_currency": "usd",
        "original_amount": 200,
        "usd_amount": 200,
        "liability": 170,
        "status": "won",
        "match_start_time": "2024-12-25T20:00:00.000Z",
        "match_end_time": "2024-12-25T22:00:00.000Z",
        "result_declared_time": null,
        "created_at": "2024-12-22T19:38:32.272Z",
        "updated_at": "2024-12-22T19:38:32.272Z",
        "username": "mitansh41",
        "converted_amount": 200
      },
      {
        "id": 11,
        "user_id": 7565738583,
        "game_type": "cricket",
        "match_title": "IPL 2024: RCB vs KKR",
        "team_one": "Royal Challengers",
        "team_two": "Kolkata Knight Riders",
        "selection_name": "Virat Kohli",
        "category": "Top Batsman",
        "bet_type": "fancy",
        "odds": 4.5,
        "stake_amount": 50,
        "original_currency": "crypto",
        "original_amount": 50,
        "usd_amount": 50,
        "liability": 225,
        "status": "pending",
        "match_start_time": "2024-12-26T14:00:00.000Z",
        "match_end_time": "2024-12-26T18:00:00.000Z",
        "result_declared_time": null,
        "created_at": "2024-12-22T19:38:32.272Z",
        "updated_at": "2024-12-22T19:38:32.272Z",
        "username": "thumbloX",
        "converted_amount": 50
      },
      {
        "id": 12,
        "user_id": 9233277782,
        "game_type": "basketball",
        "match_title": "NBA: Lakers vs Warriors",
        "team_one": "LA Lakers",
        "team_two": "Golden State Warriors",
        "selection_name": "LA Lakers (-5.5)",
        "category": "Point Spread",
        "bet_type": "back",
        "odds": 1.9,
        "stake_amount": 150,
        "original_currency": "usd",
        "original_amount": 150,
        "usd_amount": 150,
        "liability": 135,
        "status": "lost",
        "match_start_time": "2024-12-27T19:00:00.000Z",
        "match_end_time": "2024-12-27T22:00:00.000Z",
        "result_declared_time": null,
        "created_at": "2024-12-22T19:38:32.272Z",
        "updated_at": "2024-12-22T19:38:32.272Z",
        "username": "yash200",
        "converted_amount": 150
      },
      {
        "id": 13,
        "user_id": 5535994178,
        "game_type": "tennis",
        "match_title": "Tennis: Australian Open Final",
        "team_one": "Djokovic",
        "team_two": "Medvedev",
        "selection_name": "Djokovic",
        "category": "Match Winner",
        "bet_type": "back",
        "odds": 1.75,
        "stake_amount": 300,
        "original_currency": "inr",
        "original_amount": 300,
        "usd_amount": 300,
        "liability": 225,
        "status": "pending",
        "match_start_time": "2024-12-28T10:00:00.000Z",
        "match_end_time": "2024-12-28T14:00:00.000Z",
        "result_declared_time": null,
        "created_at": "2024-12-22T19:38:32.272Z",
        "updated_at": "2024-12-22T19:38:32.272Z",
        "username": "TestAvatar",
        "converted_amount": 300
      }
    ],
    "pagination": {
      "page": 1,
      "limit": 10,
      "total": 5,
      "totalPages": 1
    }
  };

  return (
    <Table>
      <TableHeader>
        <tr>
          <TableHeaderCell>User</TableHeaderCell>
          <TableHeaderCell>Game</TableHeaderCell>
          <TableHeaderCell>Match</TableHeaderCell>
          <TableHeaderCell>Selection</TableHeaderCell>
          <TableHeaderCell>Type</TableHeaderCell>
          <TableHeaderCell>Odds</TableHeaderCell>
          <TableHeaderCell>Stake</TableHeaderCell>
          <TableHeaderCell>Liability</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Date</TableHeaderCell>
        </tr>
      </TableHeader>
      <tbody>
        {betsData.bets.map((bet) => (
          <TableRow key={bet.id} status={bet.status}>
            <TableCell>{bet.username}</TableCell>
            <TableCell>
              <GameType>
                {bet.game_type}
              </GameType>
            </TableCell>
            <TableCell>{bet.match_title}</TableCell>
            <TableCell>{bet.selection_name}</TableCell>
            <TableCell>
              <BetType type={bet.bet_type}>
                {bet.bet_type === 'back' && (
                  <BetDirection type="back">
                    {/* <ArrowUp size={14} /> */}
                    Back
                  </BetDirection>
                )}
                {bet.bet_type === 'lay' && (
                  <BetDirection type="lay">
                    {/* <ArrowDown size={14} /> */}
                    Lay
                  </BetDirection>
                )}
                {bet.bet_type === 'fancy' && (
                  <span>
                    {/* <Minus size={14} /> */}
                    Fancy
                  </span>
                )}
              </BetType>
            </TableCell>
            <TableCell>{bet.odds}</TableCell>
            <TableCell>${bet.stake_amount}</TableCell>
            <TableCell>${bet.liability}</TableCell>
            <TableCell>
              <Status status={bet.status}>
                {bet.status.charAt(0).toUpperCase() + bet.status.slice(1)}
              </Status>
            </TableCell>
            <TableCell>
              {format(new Date(bet.match_start_time), 'MMM d, yyyy')}
            </TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  )
}

