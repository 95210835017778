import React, { useState ,useEffect} from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { getUID, isMobile, SITE_URL,
  encode, wait, decode,sendNotfication
 } from '../../../../Helper';
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import C from "../../../../Constant";


const mockBonusData = [
  { id: 1, type: "Quest Bonus", claimed: 0.00, icon: "https://bc.game/modules/bonus2/assets/icon-quest-5a485063.png" },
  { id: 2, type: "Lucky Spin", claimed: 0.00, icon: "https://bc.game/modules/bonus2/assets/icon-lucky-spin-cc90b095.png" },
  { id: 3, type: "Roll Competition", claimed: 0.00, icon: "https://bc.game/modules/bonus2/assets/icon-roll-a05d018c.png" },
  { id: 5, type: "Weekly Bonus", claimed: 0.00, icon: "https://bc.game/modules/bonus2/assets/icon-weekly-fc8e3574.png" },
  { id: 6, type: "Monthly Bonus", claimed: 0.00, icon: "https://bc.game/modules/bonus2/assets/icon-monthly-5aefcffd.png" },
  { id: 10, type: "Deposit Bonus", claimed: 0.00, icon: "https://bc.game/modules/bonus2/assets/icon-deposit-67dfc30e.png" }
];

const mockTransactions = [
  { id: 1, date: "2024-03-19", type: "Quest Bonus", amount: 50.00, status: "Completed" },
  { id: 2, date: "2024-03-18", type: "Lucky Spin", amount: 25.00, status: "Pending" },
  { id: 3, date: "2024-03-17", type: "Roll Competition", amount: 75.00, status: "Completed" },
];

const Container = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 100vh;
  max-width: 400px;
  margin: 0 auto;
  padding: 24px;
  
  @media (max-width: 768px) {
    max-width: 100%;
    border-radius: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  
  @media (max-width: 768px) {
    margin-bottom: 20px;
    padding: 0;
  }
`;

const Title = styled.h2`
  color: white;
  margin: 0;
  font-size: 24px;
  
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  font-size: 24px;
  padding: 8px;
  
  @media (max-width: 768px) {
    font-size: 20px;
    padding: 6px;
  }
  
  &:hover {
    color: white;
  }
`;

const TabContainer = styled.div`
  background-color: #24272c;
  border-radius: 8px;
  display: flex;
  margin-bottom: 24px;
  padding: 6px;
  
  @media (max-width: 768px) {
    margin-bottom: 20px;
    padding: 4px;
  }
`;

const Tab = styled.button`
  background: ${props => props.active ? '#1a1d23' : 'transparent'};
  border: none;
  border-radius: 6px;
  color: ${props => props.active ? 'white' : '#6b7280'};
  cursor: pointer;
  flex: 1;
  padding: 12px;
  transition: all 0.3s ease;
  font-weight: ${props => props.active ? '600' : '400'};
  font-size: 16px;
  
  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
  
  &:hover {
    color: ${props => props.active ? 'white' : '#a1a1aa'};
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-right: 4px;
  
  &::-webkit-scrollbar {
    width: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #4a5568;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const BonusList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const BonusItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a1a1aa;
  padding: 16px;
  border-radius: 8px;
  transition: all 0.2s ease;
  
  @media (max-width: 768px) {
    padding: 12px;
  }
  
  &:hover {
    background-color: #24272c;
  }
`;

const BonusInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  
  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const BonusIcon = styled.img`
  width: 32px;
  height: 32px;
  
  @media (max-width: 768px) {
    width: 28px;
    height: 28px;
  }
`;

const BonusType = styled.span`
  color: #d1d5db;
  font-size: 16px;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const BonusAmount = styled.span`
  color: #d1d5db;
  font-weight: 600;
  font-size: 16px;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TransactionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const TransactionItem = styled.div`
  background-color: #24272c;
  border-radius: 8px;
  padding: 16px;
  
  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const TransactionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const TransactionType = styled.span`
  color: #d1d5db;
  font-weight: 500;
  font-size: 16px;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TransactionAmount = styled.span`
  color: #10b981;
  font-weight: 600;
  font-size: 16px;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TransactionDetails = styled.div`
  display: flex;
  justify-content: space-between;
  color: #6b7280;
  font-size: 14px;
  
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Status = styled.span`
  color: ${props => props.status === 'Completed' ? '#10b981' : '#f59e0b'};
  font-size: 14px;
  font-weight: 500;
  
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const BonusDetails= ({ data }) => {
  const [activeTab, setActiveTab] = useState('Overview');

  const [progress] = useState(25); // Example progress

  const [token] = useState(storage.getKey("token") || null);
  const [logged, setLogged] = useState(false);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    // Scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Check if user is logged in
    if (token !== null) {
      setLogged(true);
    }

    // Initialize socket connections
    const initializeSocket = async () => {
      await wait(200);
      socket.emit(C.RAKEBACK_AMOUNT);
    };

    initializeSocket();

    // Socket listeners
    const handleRakebackAmount = (data) => {
      const decodedData = decode(data);
      if (decodedData) {
        setAmount(decodedData.amount);
      }
    };

    const handleAddRakeback = async (data) => {
      const decodedData = decode(data);
      await wait(1000);
      socket.emit(C.CREDIT);
      let message = decodedData.status.toString();
      sendNotfication(message, "info", "top-center");
    };

    socket.on(C.RAKEBACK_AMOUNT, handleRakebackAmount);
    socket.on(C.ADD_RAKEBACK, handleAddRakeback);

    // Cleanup socket listeners
    return () => {
      socket.off(C.RAKEBACK_AMOUNT, handleRakebackAmount);
      socket.off(C.ADD_RAKEBACK, handleAddRakeback);
    };
  }, [token]);

  const addReward = () => {
    socket.emit(C.ADD_RAKEBACK);
  };


  const [openModal, setOpneModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [bonus, setBonus] = useState(null);
  const [bonususer, setBonususer] = useState(null);
  const [bonusredeemuser, setBonusredeemuser] = useState(null)

  const handleBonusRequest = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const userId = getUID;
      const response = await fetch('https://api.skyla.game/bonus/userbonus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
        },
        body: JSON.stringify({
          userid: userId,
          role: 'user'
        })
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data = await response.json();
      setSuccess(true);
      if (Array.isArray(data) && data.length > 0) {
        setBonususer(data[0]); // Extract the first object from the array
        setSuccess(true);
        console.log('User Bonus Success:', data[0]);
      } else {
        throw new Error('No data found');
      }
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };
  

  const handleBonusRedeemRequest = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const userId = getUID;
      const response = await fetch('https://api.skyla.game/bonus/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
        },
        body: JSON.stringify({
          userid: userId
        })
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data = await response.json();
      setSuccess(true);
      setBonusredeemuser(data);
      console.log('User Bonus Redeem:', data);
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };
  const fetchGameDeatils = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const userId = getUID;
      const response = await fetch('https://api.skyla.game/bonus/bonusgame', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
        },
        body: JSON.stringify({
          userid: userId,
          role: 'user'
        })
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data = await response.json();
      setSuccess(true);
      if (Array.isArray(data) && data.length > 0) {
        setBonus(data[0]); // Extract the first object from the array
        setSuccess(true);
        console.log('Game Bonus Success:', data[0]);
      } else {
        throw new Error('No data found');
      }
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGameDeatils()
    handleBonusRedeemRequest()
    handleBonusRequest()
  }, [])
  const formatAmount = (amount) => `$${amount.toFixed(2)}`;
  const history = useHistory();

  const [reedemcode, setReedemCode] = useState(null)

  const handleRedeemCode = async () => {

    if(!reedemcode) return sendNotfication('Please Enter Reedeam Code !')

    const url = 'https://api.skyla.game/bonus/redeem-bonus/redeem';
    const headers = {
      'Content-Type': 'application/json',
      'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053',
    };
    const payload = {
      userid: getUID,
      code: reedemcode, // Assuming you have a code to redeem
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.status === 200) {
        sendNotfication(data.message); // Show success notification
        // setAmount(data.amount); // Update amount if needed
        window.location.reload();
      } else if (response.status === 400) {
        sendNotfication(data.error); // Show error notification for bad request
      } else if (response.status === 404) {
        sendNotfication(data.error); // Show error notification for not found
      } else if (response.status === 500) {
        sendNotfication(data.error); // Show error notification for server error
      }
    } catch (error) {
      sendNotfication('Failed to redeem bonus'); // Handle network errors
    }
    finally
    {
      setReedemCode("");
    }
  };
  const calculateTotalBonus = (user, bonus) => {
    if (!user || !bonus) return 0;

    const userBonuses =
      (user.vipbonus || 0) +
      (user.specialbonus || 0) +
      (user.generalbonus || 0) +
      (user.joiningbonus || 0);

    const additionalBonuses =
      (bonus.dailybonus || 0) +
      (bonus.weeklybonus || 0) +
      (bonus.monthlybonus || 0) +
      (bonus.depositbonus || 0) +
      (bonus.rakebackbonus || 0);
      // Calculate the total redeem bonus amount from bonusredeemuser
  const redeemBonusTotal = bonusredeemuser?.reduce((sum, item) => sum + (item.amount || 0), 0);

  // Return the total of all bonuses
  return userBonuses + additionalBonuses + redeemBonusTotal;

  };

  const totalBonus = calculateTotalBonus(bonususer, bonus,bonusredeemuser);
  
  const getImagePath = (i) => {
    
      return "https://bc.game/modules/bonus2/assets/icon-quest-5a485063.png"
  
  }

  const bonusTypes = [
    { key: 'dailybonus', type: "Daily Bonus", claimed: data?.dailybonus || 0, icon:"/assets/images/bcgames/bonus/bcc1.webp" 
    },
    { key: 'weeklybonus', type: "Weekly Bonus", claimed: data?.weeklybonus || 0, icon:"/assets/images/bcgames/bonus/bcc6.webp"  },
    { key: 'monthlybonus', type: "Monthly Bonus", claimed: data?.monthlybonus || 0, icon:"/assets/images/bcgames/bonus/bcc7.webp" },
    { key: 'depositbonus', type: "Deposit Bonus", claimed: data?.depositbonus || 0, icon:"/assets/images/bcgames/bonus/bcc4.webp"  },
  ];


  const renderOverviewTab = () => (
    <BonusList>
      {bonusTypes.map(bonus => (
        <BonusItem key={bonus.key}>
          <BonusInfo>
            <BonusIcon src={bonus.icon} alt={bonus.type} />
            <BonusType>{bonus.type}</BonusType>
          </BonusInfo>
          <BonusAmount>${bonus.claimed.toFixed(2)}</BonusAmount>
        </BonusItem>
      ))}
    </BonusList>
  );

  const renderTransactionTab = () => (
    <TransactionList>
      {mockTransactions.map(transaction => (
        <TransactionItem key={transaction.id}>
          <TransactionHeader>
            <TransactionType>{transaction.type}</TransactionType>
            <TransactionAmount>+${transaction.amount.toFixed(2)}</TransactionAmount>
          </TransactionHeader>
          <TransactionDetails>
            <span>{transaction.date}</span>
            <Status status={transaction.status}>{transaction.status}</Status>
          </TransactionDetails>
        </TransactionItem>
      ))}
    </TransactionList>
  );

  return (
    <Container>
      <TabContainer>
        <Tab 
          active={activeTab === 'Overview'} 
          onClick={() => setActiveTab('Overview')}
        >
          Overview
        </Tab>
        {/* <Tab 
          active={activeTab === 'Transaction'} 
          onClick={() => setActiveTab('Transaction')}
        >
          Transaction
        </Tab> */}
      </TabContainer>
      <ContentContainer>
        {activeTab === 'Overview' ? renderOverviewTab() : renderTransactionTab()}
      </ContentContainer>
    </Container>
  );
};

export default BonusDetails;