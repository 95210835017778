


///newwer
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useBetting } from '../../../../../context/BettingContext';
import {
  Play,
  RefreshCw,
  Flag,
  Info,
  ChevronRight,
  Star
} from 'lucide-react';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../Loader';
import { height, width } from '@mui/system';

// Styled Components
const Container = styled.div`
  width: 100%;
  background: #f5f5f5;
  font-family: Arial, sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden; /* Prevent double scrollbars */
`;

const Content = styled.div`
  background: white;
  flex: 1;
  overflow-y: auto;
  margin-bottom:100px;
  height: calc(100vh - 20px); /* Adjust based on your header height */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const Match = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  border-bottom: 1px solid #eee;
`;



const MatchInfo = styled.div`
  flex: 1;
`;

const MatchTitle = styled.div`
  color: #3498db;
  font-weight: bold;
  // margin-bottom: 5px;
  font-size: 10px;
`;

const MatchTitle1 = styled.div`
  color: #3498db;
  font-weight: bold;
  margin-bottom: -5px;
  font-size: 11px;
`;


const Status = styled.span`
  color: #27ae60;
  font-size: 12px;
  margin-left: 14px;
  font-weight:bold;
  // margin-bottom: -5px;
   
`;

const NoStatus = styled.span`
  color: rgb(195, 195, 195);
  font-size: 10px;
  margin-left: 14px;
  // margin-bottom: -5px;
 
`;

const OddsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const OddsSection = styled.div`
  display: flex;
  gap: 2px;
`;

const OddsBox = styled.div`
  background: ${props => props.blue ? '#72BBEF' : '#FFB6C1'};
  padding: 5px;
  min-width: 45px;
  text-align: center;
  border-radius: 4px;
  font-weight:bold;
  cursor: ${props => props.suspended ? 'not-allowed' : 'pointer'};
  color: ${props => props.suspended ? '#e74c3c' : 'black'} !important;
  opacity: ${props => props.suspended ? 0.7 : 1};
  transition: all 0.2s ease;

  &:hover {
    opacity: ${props => props.suspended ? 0.7 : 0.8};
  }
`;

const IconGroup = styled.div`
  display: flex;
  gap: 2px;
  margin-bottom: -5px;
`;

const Icon = styled.span`
  background: #2ecc71;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
`;

const PTE = styled.div`
  color: #95a5a6;
  margin-right: 10px;
  font-size: 14px;
`;

const LiveDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
    margin-bottom:-5px;
  background-color: #2ecc71;
  border-radius: 50%;
  margin-right: 8px;
  animation: blink 1s ease-in-out infinite;

  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
const NOLiveDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color:rgb(195, 195, 195);
  border-radius: 50%;
  margin-right: 8px;
   margin-bottom:-5px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;


const CricketBettingUI = (props) => {
  console.log("props", props.tab)
  const { addBet } = useBetting();
  const [matches, setMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchAllMatches = async () => {
      setIsLoading(true);
      try {
        // Parallel fetch for better performance
        const [inPlayResponse, allMatchesResponse] = await Promise.all([
          fetch('https://api.skyla.game/sports/allinplay'),
          fetch('https://api.skyla.game/sports/all-matches')
        ]);

        const inPlayData = await inPlayResponse.json();
        const allMatchesData = await allMatchesResponse.json();

        const inPlayCricketData = inPlayData.find(sport => sport.id === "4")?.markets || [];
        const allCricketData = allMatchesData.find(sport => sport.id === "4")?.markets || [];
        const allSportsData = allMatchesData?.map((markets) => markets?.markets);
        const soccerData = allMatchesData.find(sport => sport.id === "1")?.markets || [];
        const tennisData = allMatchesData.find(sport => sport.id === "2")?.markets || [];
        const wholeSportsDtaa = [...allSportsData[0], ...allSportsData[1], ...allSportsData[2]];

        // Create a Map to store unique matches with in-play taking priority
        const matchesMap = new Map();

        // Base match object creator function
        const createMatchObject = (match, status) => ({
          id: match.marketId,
          team1: match.runners[0]?.runnerName || '',
          team2: match.runners[1]?.runnerName || '',
          status: status,
          isSuspended: match.stopBet,
          inplay: match.inplay,
          eventId: match.version,
          pte: 0, // Initialize to 0, will be updated with odds data
          marketStartTime: match.marketStartTime,
          odds: {
            one: { back: '--', lay: '--' },
            x: { back: '--', lay: '--' },
            two: { back: '--', lay: '--' }
          }
        });

        // Process in-play matches
        inPlayCricketData.forEach(match => {
          matchesMap.set(match.marketId, createMatchObject(match, 'In-Play'));
        });

        // Function to process matches based on sport type
        const processMatches = (matches) => {
          matches.forEach(match => {
            if (!matchesMap.has(match.marketId)) {
              const utcDate = new Date(match.marketStartTime);
              const istTime = new Intl.DateTimeFormat('en-IN', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              }).format(utcDate);

              matchesMap.set(match.marketId, createMatchObject(match, istTime));
            }
          });
        };

        // Process matches based on selected tab
        switch (props.tab) {
          case "all-sports":
            processMatches(wholeSportsDtaa);
            break;
          case "soccer":
            processMatches(soccerData);
            break;
          case "tennis":
            processMatches(tennisData);
            break;
          case "cricket":
            processMatches(allCricketData);
            break;
          default:
            processMatches(wholeSportsDtaa);
        }

        // Updated odds fetching function that also returns totalMatched
        const fetchMatchOdds = async (matchId) => {
          try {
            const response = await fetch(`https://api.skyla.game/sports/market-odds?market_id=${matchId}`);
            const data = await response.json();

            const marketOdds = data[0];
            if (!marketOdds || !marketOdds.runners) return null;

            const runners = marketOdds.runners;
            let formattedOdds = {
              one: { back: '--', lay: '--' },
              x: { back: '--', lay: '--' },
              two: { back: '--', lay: '--' }
            };

            if (runners.length === 2) {
              formattedOdds.one = {
                back: runners[0].ex.availableToBack[0]?.price || '--',
                lay: runners[0].ex.availableToLay[0]?.price || '--'
              };
              formattedOdds.two = {
                back: runners[1].ex.availableToBack[0]?.price || '--',
                lay: runners[1].ex.availableToLay[0]?.price || '--'
              };
            } else if (runners.length === 3) {
              formattedOdds.one = {
                back: runners[0].ex.availableToBack[0]?.price || '--',
                lay: runners[0].ex.availableToLay[0]?.price || '--'
              };
              formattedOdds.x = {
                back: runners[1].ex.availableToBack[0]?.price || '--',
                lay: runners[1].ex.availableToLay[0]?.price || '--'
              };
              formattedOdds.two = {
                back: runners[2].ex.availableToBack[0]?.price || '--',
                lay: runners[2].ex.availableToLay[0]?.price || '--'
              };
            }

            return {
              odds: formattedOdds,
              totalMatched: marketOdds.totalMatched || 0
            };
          } catch (error) {
            console.error('Error fetching odds:', error);
            return null;
          }
        };

        // Fetch odds for all matches and update them in the map
        const matchesWithOdds = await Promise.all(
          Array.from(matchesMap.entries()).map(async ([marketId, match]) => {
            const oddsData = await fetchMatchOdds(marketId);
            if (oddsData) {
              match.odds = oddsData.odds;
              match.pte = oddsData.totalMatched; // Update PTE with totalMatched from odds API
            }
            return match;
          })
        );

        // Sort and set the matches
        const sortedMatches = matchesWithOdds.sort((a, b) => {
          if (a.inplay && !b.inplay) return -1;
          if (!a.inplay && b.inplay) return 1;
          return new Date(a.marketStartTime) - new Date(b.marketStartTime);
        });

        setMatches(sortedMatches);
      } catch (error) {
        console.error('Error fetching matches:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllMatches();
    const interval = setInterval(fetchAllMatches, 30000);
    return () => clearInterval(interval);
  }, [props.tab]);








  // useEffect(() => {
  //   const fetchAllMatches = async () => {
  //     setIsLoading(true);
  //     try {
  //       // Parallel fetch for better performance
  //       const [inPlayResponse, allMatchesResponse] = await Promise.all([
  //         fetch('https://api.skyla.game/sports/allinplay'),
  //         fetch('https://api.skyla.game/sports/all-matches')
  //       ]);

  //       const inPlayData = await inPlayResponse.json();
  //       const allMatchesData = await allMatchesResponse.json();

  //       console.log("all matches data", allMatchesData)



  //       // Filter cricket matches from both responses
  //       const inPlayCricketData = inPlayData.find(sport => sport.id === "4")?.markets || [];
  //       const allCricketData = allMatchesData.find(sport => sport.id === "4")?.markets || [];
  //       const allSportsData = allMatchesData?.map((markets) => markets?.markets);
  //       const soccerData = allMatchesData.find(sport => sport.id === "1")?.markets || [];
  //       const tennisData = allMatchesData.find(sport => sport.id === "2")?.markets || [];
  //       const wholeSportsDtaa = [...allSportsData[0], ...allSportsData[1], ...allSportsData[2]];
  //       console.log("whole sports", wholeSportsDtaa)

  //       console.log("all sports data, all", allSportsData)
  //       console.log("all cricket data", allCricketData)

  //       // Create a Map to store unique matches with in-play taking priority
  //       const matchesMap = new Map();

  //       // Process in-play matches first
  //       inPlayCricketData.forEach(match => {
  //         matchesMap.set(match.marketId, {
  //           id: match.marketId,
  //           team1: match.runners[0]?.runnerName || '',
  //           team2: match.runners[1]?.runnerName || '',
  //           status: 'In-Play',
  //           isSuspended: match.stopBet,
  //           inplay: match.inplay,
  //           pte:match.totalMatched,
  //           marketStartTime: match.marketStartTime,
  //           odds: {
  //             one: { back: '--', lay: '--' },
  //             x: { back: '--', lay: '--' },
  //             two: { back: '--', lay: '--' }
  //           }
  //         });
  //       });

  //       // Add non-duplicate matches from all-matches API
  //       if (props.tab === "all-sports") {
  //         wholeSportsDtaa.forEach(match => {
  //           if (!matchesMap.has(match.marketId)) {
  //             const utcDate = new Date(match.marketStartTime);
  //             const istTime = new Intl.DateTimeFormat('en-IN', {
  //               hour: '2-digit',
  //               minute: '2-digit',
  //               hour12: true
  //             }).format(utcDate);

  //             matchesMap.set(match.marketId, {
  //               id: match.marketId,
  //               team1: match.runners[0]?.runnerName || '',
  //               team2: match.runners[1]?.runnerName || '',
  //               status: `${istTime}`,
  //               isSuspended: match.stopBet,
  //               inplay:match.inplay,
  //               pte:match.totalMatched,
  //               marketStartTime: match.marketStartTime,
  //               odds: {
  //                 one: { back: '--', lay: '--' },
  //                 x: { back: '--', lay: '--' },
  //                 two: { back: '--', lay: '--' }
  //               }
  //             });
  //           }
  //         });
  //       }
  //       else if (props.tab === "soccer") {
  //         soccerData.forEach(match => {
  //           if (!matchesMap.has(match.marketId)) {
  //             const utcDate = new Date(match.marketStartTime);
  //             const istTime = new Intl.DateTimeFormat('en-IN', {
  //               hour: '2-digit',
  //               minute: '2-digit',
  //               hour12: true
  //             }).format(utcDate);

  //             matchesMap.set(match.marketId, {
  //               id: match.marketId,
  //               team1: match.runners[0]?.runnerName || '',
  //               team2: match.runners[1]?.runnerName || '',
  //               status: `${istTime}`,
  //               isSuspended: match.stopBet,
  //               inplay:match.inplay,
  //               pte:match.totalMatched,
  //               marketStartTime: match.marketStartTime,
  //               odds: {
  //                 one: { back: '--', lay: '--' },
  //                 x: { back: '--', lay: '--' },
  //                 two: { back: '--', lay: '--' }
  //               }
  //             });
  //           }
  //         });
  //       }
  //       else if (props.tab === "tennis") {
  //         tennisData.forEach(match => {
  //           if (!matchesMap.has(match.marketId)) {
  //             const utcDate = new Date(match.marketStartTime);
  //             const istTime = new Intl.DateTimeFormat('en-IN', {
  //               hour: '2-digit',
  //               minute: '2-digit',
  //               hour12: true
  //             }).format(utcDate);

  //             matchesMap.set(match.marketId, {
  //               id: match.marketId,
  //               team1: match.runners[0]?.runnerName || '',
  //               team2: match.runners[1]?.runnerName || '',
  //               status: `${istTime}`,
  //               isSuspended: match.stopBet,
  //               inplay: match.inplay,
  //               pte:match.totalMatched,
  //               marketStartTime: match.marketStartTime,
  //               odds: {
  //                 one: { back: '--', lay: '--' },
  //                 x: { back: '--', lay: '--' },
  //                 two: { back: '--', lay: '--' }
  //               }
  //             });
  //           }
  //         });
  //       }
  //       else if (props.tab === "cricket") {
  //         allCricketData.forEach(match => {
  //           if (!matchesMap.has(match.marketId)) {
  //             const utcDate = new Date(match.marketStartTime);
  //             const istTime = new Intl.DateTimeFormat('en-IN', {
  //               hour: '2-digit',
  //               minute: '2-digit',
  //               hour12: true
  //             }).format(utcDate);

  //             matchesMap.set(match.marketId, {
  //               id: match.marketId,
  //               team1: match.runners[0]?.runnerName || '',
  //               team2: match.runners[1]?.runnerName || '',
  //               status: `${istTime}`,
  //               isSuspended: match.stopBet,
  //               inplay:match.inplay,
  //               pte:match.totalMatched,
  //               marketStartTime: match.marketStartTime,
  //               odds: {
  //                 one: { back: '--', lay: '--' },
  //                 x: { back: '--', lay: '--' },
  //                 two: { back: '--', lay: '--' }
  //               }
  //             });
  //           }
  //         });
  //       }
  //       else {
  //         wholeSportsDtaa.forEach(match => {
  //           if (!matchesMap.has(match.marketId)) {
  //             const utcDate = new Date(match.marketStartTime);
  //             const istTime = new Intl.DateTimeFormat('en-IN', {
  //               hour: '2-digit',
  //               minute: '2-digit',
  //               hour12: true
  //             }).format(utcDate);

  //             matchesMap.set(match.marketId, {
  //               id: match.marketId,
  //               team1: match.runners[0]?.runnerName || '',
  //               team2: match.runners[1]?.runnerName || '',
  //               status: `${istTime}`,
  //               isSuspended: match.stopBet,
  //               inplay: match.inplay,
  //               pte:match.totalMatched,
  //               marketStartTime: match.marketStartTime,
  //               odds: {
  //                 one: { back: '--', lay: '--' },
  //                 x: { back: '--', lay: '--' },
  //                 two: { back: '--', lay: '--' }
  //               }
  //             });
  //           }
  //         });
  //       }

  //       // Function to fetch and process odds for a single match
  //       const fetchMatchOdds = async (matchId) => {
  //         try {
  //           const response = await fetch(`https://api.skyla.game/sports/market-odds?market_id=${matchId}`);
  //           const data = await response.json();

  //           const marketOdds = data[0];
  //           if (!marketOdds || !marketOdds.runners) return null;

  //           const runners = marketOdds.runners;
  //           let formattedOdds = {
  //             one: { back: '--', lay: '--' },
  //             x: { back: '--', lay: '--' },
  //             two: { back: '--', lay: '--' }
  //           };

  //           if (runners.length === 2) {
  //             formattedOdds.one = {
  //               back: runners[0].ex.availableToBack[0]?.price || '--',
  //               lay: runners[0].ex.availableToLay[0]?.price || '--'
  //             };
  //             formattedOdds.two = {
  //               back: runners[1].ex.availableToBack[0]?.price || '--',
  //               lay: runners[1].ex.availableToLay[0]?.price || '--'
  //             };
  //           } else if (runners.length === 3) {
  //             formattedOdds.one = {
  //               back: runners[0].ex.availableToBack[0]?.price || '--',
  //               lay: runners[0].ex.availableToLay[0]?.price || '--'
  //             };
  //             formattedOdds.x = {
  //               back: runners[1].ex.availableToBack[0]?.price || '--',
  //               lay: runners[1].ex.availableToLay[0]?.price || '--'
  //             };
  //             formattedOdds.two = {
  //               back: runners[2].ex.availableToBack[0]?.price || '--',
  //               lay: runners[2].ex.availableToLay[0]?.price || '--'
  //             };
  //           }

  //           return formattedOdds;
  //         } catch (error) {
  //           console.error('Error fetching odds:', error);
  //           return null;
  //         }
  //         finally {

  //         }
  //       };

  //       // Fetch odds for all matches and update them in the map
  //       const matchesWithOdds = await Promise.all(
  //         Array.from(matchesMap.entries()).map(async ([marketId, match]) => {
  //           const odds = await fetchMatchOdds(marketId);
  //           if (odds) {
  //             match.odds = odds;
  //           }
  //           return match;
  //         })
  //       );

  //       // Sort and set the matches
  //       const sortedMatches = matchesWithOdds.sort((a, b) => {
  //         if (a.inplay && !b.inplay) return -1;
  //         if (!a.inplay && b.inplay) return 1;
  //         return new Date(a.marketStartTime) - new Date(b.marketStartTime);
  //       });

  //       setMatches(sortedMatches);


  //     } catch (error) {
  //       console.error('Error fetching matches:', error);

  //     }
  //     finally {
  //       setIsLoading(false)
  //     }
  //   };


  //   fetchAllMatches();
  //   const interval = setInterval(fetchAllMatches, 30000);
  //   setIsLoading(false)
  //   return () => clearInterval(interval);
  // }, [props.tab]);



  const handleOddsClick = (match, category, type, odds) => {
    console.log("consoles", match, category,type, odds)
    if (match.isSuspended || odds === '--' || odds === 'Suspend') return;

    // Determine the selection name based on category
    let selectionName = '';
    if (category === 'matched') {
      selectionName = match.team1;  // First team for matched category
    } else if (category === 'x') {
      selectionName = 'The Draw';   // Draw for x category
    } else if (category === 'two') {
      selectionName = match.team2;  // Second team for two category
    }

    const betDetails = {
      matchId: match.id,
      matchTitle: `${match.team1} v ${match.team2}`,
      team1: match.team1,
      team2: match.team2,
      selectionName: selectionName, // Add selection name
      category,
      type,
      odds,
      isBlue: type === 'back',
      stake: '',
      liability: '0.00'
    };

    console.log('Selected Bet:', betDetails);
    addBet(betDetails);
  };


  const history = useHistory();


 
// Option 1: Using window.location.reload()
const handleMatchClick = (match) => {
  // setMatchClicked(true);
  history.push({
    pathname: `/sports/markets/${match.id}`,
    state: {
      matchData: match
    }
  });
};





  console.log("matches, ", matches)

  return (
    <Container>


      <Content>
        <Match style={{
          backgroundColor: '#2C3E50',
          position: 'sticky',
          top: 0,
          zIndex: 5,
          marginLeft:"8px",
          marginRight:'8px',
          borderRadius:'5px'
        }}>
          <MatchInfo style={{ flex: 1 , }} ></MatchInfo>
          <OddsContainer>
            <OddsSection>
              <div style={{ textAlign: 'left', minWidth: '190px', color:'white', fontWeight: 'bold' }}>Games</div>
            </OddsSection>

            <OddsSection>
              <div style={{ textAlign: 'right', minWidth: '120px', color:'white', fontWeight: 'bold', }}>Matched PTE</div>
            </OddsSection>
            <OddsSection>
              <div style={{ textAlign: 'center', minWidth: '90px', color:'white', fontWeight: 'bold' }}>1</div>
            </OddsSection>
            <OddsSection>
              <div style={{ textAlign: 'center', minWidth: '90px', color:'white', fontWeight: 'bold' }}>X</div>
            </OddsSection>
            <OddsSection>
              <div style={{ textAlign: 'center', minWidth: '90px', color:'white', fontWeight: 'bold' }}>2</div>
            </OddsSection>
          </OddsContainer>
        </Match>
        {matches.map((match, index) => (
          <Match key={index}>
            <MatchInfo onClick={() => handleMatchClick(match)}>
              <TitleContainer>
                {match.inplay ? <LiveDot /> : <NOLiveDot />}
                <MatchTitle1>{`${match.team1} v ${match.team2}`}</MatchTitle1>
              </TitleContainer>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                {match.inplay ? <Status>{match.status}</Status> : <NoStatus>{match.status}</NoStatus>}
                <IconGroup>
                  {/* <Icon><Play size={12} /></Icon>
                  <Icon><RefreshCw size={12} /></Icon>
                  <Icon><Flag size={12} /></Icon>
                  <Icon><Info size={12} /></Icon> */}
                                    <img src='/assets/images/sports/cricket.png' style={{width:"45px", height:"15px", objectFit:'contain'}}/>
                  <img src='/assets/images/sports/livestream.png' style={{width:"15px", height:"15px", objectFit:'contain'}}/>
                  <img src='/assets/images/sports/watch.png' style={{width:"15px", height:"15px", objectFit:'contain'}}/>
                  <img src='/assets/images/sports/fancy.png' style={{width:"15px", height:"15px", objectFit:'contain'}}/>
                  <img src='/assets/images/sports/book.png' style={{width:"15px", height:"15px",objectFit:'contain'}}/>

                </IconGroup>
              </div>
            </MatchInfo>
            <PTE>{match.pte}</PTE>
            <OddsContainer>
              <OddsSection>
                <OddsBox
                  blue
                  suspended={match.isSuspended}
                  onClick={() => handleOddsClick(match, 'matched', 'back', match.odds.one.back)}
                >
                  {match.odds.one.back}
                </OddsBox>
                <OddsBox
                  suspended={match.isSuspended}
                  onClick={() => handleOddsClick(match, 'matched', 'lay', match.odds.one.lay)}
                >
                  {match.odds.one.lay}
                </OddsBox>
              </OddsSection>
              <OddsSection>
                <OddsBox
                  blue
                  suspended={match.isSuspended}
                  onClick={() => handleOddsClick(match, 'x', 'back', match.odds.x.back)}
                >
                  {match.odds.x.back}
                </OddsBox>
                <OddsBox
                  suspended={match.isSuspended}
                  onClick={() => handleOddsClick(match, 'x', 'lay', match.odds.x.lay)}
                >
                  {match.odds.x.lay}
                </OddsBox>
              </OddsSection>
              <OddsSection>
                <OddsBox
                  blue
                  suspended={match.isSuspended}
                  onClick={() => handleOddsClick(match, 'two', 'back', match.odds.two.back)}
                >
                  {match.odds.two.back}
                </OddsBox>
                <OddsBox
                  suspended={match.isSuspended}
                  onClick={() => handleOddsClick(match, 'two', 'lay', match.odds.two.lay)}
                >
                  {match.odds.two.lay}
                </OddsBox>
              </OddsSection>
            </OddsContainer>
          </Match>
        ))}
      </Content>


    </Container>
  );
};

export default CricketBettingUI;