import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getUID, isMobile, SITE_URL,
  encode, wait, decode,sendNotfication
 } from '../../../Helper';
import { X } from 'lucide-react';
import BonusDetails from './BonusDetailsTable';
import BonusMobile from './BonusMobile';
import { useHistory } from 'react-router-dom';
import LockIcon from '../../../Icons/LockIcon';



import socket from "../../../Socket";
import storage from "../../../Storage";
import C from "../../../Constant";



const Container = styled.div`
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Header = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 20px;
`;

const Title = styled.h1`
  color: white;
  font-size: 20px;
  margin: 0;
  font-weight:bold
`;
const Title1 = styled.h1`
  color: white;
  font-size: 18px;
  font-weight:bold;
  margin-bottom: 10px;
`;
const RedeemWrapper = styled.div`
  position: relative;
  width: 400px;
  background-color: #292D2E;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
`;

const RedeemInput = styled.input`
  background: rgba(42, 42, 42, 0.9);
  border: none;
  border-radius: 4px;
  color: white;
  padding: 8px 120px 8px 12px;
  width: 100%;
  height: 36px;
  font-size: 14px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    outline: none;
    background: rgba(42, 42, 42, 1);
  }
`;

const RedeemButton = styled.button`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #4F5253;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 6px 8px;
  cursor: pointer;
  font-size: 13px;
  height: 28px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: background 0.2s ease;

  &:hover {
    background: #4F5253;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    background-size: contain;
    transform: rotate(180deg);
  }
`;


const BonusCard = styled.div`
background: linear-gradient(274deg, rgba(246, 105, 44, 0) 14.01%, rgba(246, 105, 44, .6) 146.96%);
  border-radius: 12px;
  padding: 24px;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
`;

const BonusContent = styled.div`
  position: relative;
  z-index: 1;
  width: 60%;
`;

const BonusCardImage = styled.img`
  position: absolute;
  right: -5%;
  top: 50%;
  transform: translateY(-50%);
  height: 120%;
  z-index: 0;
  opacity: 0.8;
  object-fit: contain;

  @media (max-width: 768px) {
    opacity: 0.4;
    right: -20%;
  }
`;

const TotalAmount = styled.div`
  font-size: 32px;
  color: white;
  font-weight: bold;
  margin-bottom: 30px;
`;

const BonusTypes = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
`;

const BonusType = styled.div`
  .label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .amount {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
`;
const BonusType1 = styled.div`
  .label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
   
  }
  
  .amount {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
`;


const DetailsButton = styled.button`
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    color: white;
  }
`;

const DepositBonusCard = styled.div`
  background: linear-gradient(86deg, rgba(255, 164, 49, 0) 65.46%, rgba(255, 136, 3, .3) 130.45%), linear-gradient(288deg, rgba(255, 164, 49, 0) 21.4%, rgba(255, 136, 3, .3) 116.92%);
  border-radius: 12px;
  padding: 24px;
  height: 100%;
`;

const DepositHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BonusAmount1 = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: bold;
`;
const BonusAmount = styled.div`
  color: #8B8B85;
  font-size: 14px;
  font-weight: bold;
`;

const BonusTimer = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
`;

const DepositButton = styled.div`
  background: linear-gradient(to bottom, #4AE54A, #94E874);
   padding: 7px 14px;
  border-radius: 6px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  color: black !important;
  cursor: pointer;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.9;
  }
`;

const ProgressContainer = styled.div`
  position: relative;
  padding-top: 10px;
`;

const ProgressBar = styled.div`
  position: relative;
  height: 4px;
  background: #333;
 

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${props => props.progress}%;
    background: linear-gradient(90deg, #FFD700, #FFA500);
  }
`;

const ProgressPoints = styled.div`
  display: flex;
  justify-content: space-between;
  // margin-top: 20px;
  padding: 0 0px;
`;

const ProgressPoint = styled.div`
  color: ${props => props.active ? '#FFD700' : 'white'};
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  font-size: 16px;
  text-align: center;
  
  img {
    width: 70px;
    height: 70px;
    margin-bottom: 4px;
  }
`;



const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;


const CardBG = styled.div`
  background-color: rgb(50, 55, 56);
    border-radius: 12px;
`;


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 1050;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ModalContent = styled.div`
  background-color: #1E2024;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  max-height: 80vh;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
  }

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ModalHeader = styled.div`
  height: 80px;
  background-color: #2A2D35;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  @media (max-width: 768px) {
    border-radius: 0;
    height: 60px;
  }
`;

const ModalTitle = styled.h3`
  color: white;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #6F767E;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  &:hover {
    color: #fff;
  }
`;

const ModalBody = styled.div`
  padding: 16px;
  color: white;
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 3px;
  }

  @media (max-width: 768px) {
    padding: 12px 8px;
  }
`;

const DepositInfo = styled.div`
  background-color: #2A2D35;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
`;

const QRContainer = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  width: fit-content;
  margin: 20px auto;
`;

const AddressContainer = styled.div`
  background-color: #2A2D35;
  border-radius: 8px;
  padding: 12px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Address = styled.input`
  background: none;
  border: none;
  color: white;
  width: 100%;
  margin-right: 12px;
  outline: none;
  font-family: monospace;
`;

const CopyButton = styled.button`
  background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  color: black;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  
  &:hover {
    opacity: 0.9;
  }
`;






const Card = styled.div`
 background: ${props => props.background || '#2b2b3d'};
border-radius: 12px;
  padding: 10px 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px); /* For Safari support */

`;

const ImagePlaceholder = styled.img`
  width: 120px;
  height: 120px;
  object-fit:contain;
`;

const CardTitle = styled.h3`
  color: white;
  font-size: 18px;
  margin: 4px 0
  font-weight: 600;
`;

const StatsContainer = styled.div`
  width: 100%;
  margin: 10px 0;
`;
const ContainerBottom = styled.div`
  background-color: #272B2C;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  margin-top: 20px;
  min-height:100px;
`;


const StatRow = styled.div`
  display: flex;
  justify-content: center;
  align-items:center;
  color: #9ca3af;
  margin: 5px 0;
  font-size: 12px;
`;

const ClaimButton = styled.button`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background: ${({ amount }) => (amount > 0 ? 'linear-gradient(to bottom, #4AE54A, #94E874)' : 'rgba(255, 255, 255, 0.1)')};
  color: ${({ amount }) => (amount > 0 ? 'white' : 'black')};
  border: none;
  cursor: pointer;
  margin-top: 25px;

  &:hover {
    background: ${({ amount }) => (amount > 0 ? 'linear-gradient(to bottom, #4AE54A, #94E874)' : 'rgba(255, 255, 255, 0.1)')};
  }
`;
const ClaimButton1 = styled.button`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 24px;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const Timer = styled.span`
  color: #9ca3af;
  font-size: 14px;
`;

const Bonus = () => {
  const [progress] = useState(25); // Example progress

  const [token] = useState(storage.getKey("token") || null);
  const [logged, setLogged] = useState(false);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    // Scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Check if user is logged in
    if (token !== null) {
      setLogged(true);
    }

    // Initialize socket connections
    const initializeSocket = async () => {
      await wait(200);
      socket.emit(C.RAKEBACK_AMOUNT);
    };

    initializeSocket();

    // Socket listeners
    const handleRakebackAmount = (data) => {
      const decodedData = decode(data);
      if (decodedData) {
        setAmount(decodedData.amount);
      }
    };

    const handleAddRakeback = async (data) => {
      const decodedData = decode(data);
      await wait(1000);
      socket.emit(C.CREDIT);
      let message = decodedData.status.toString();
      sendNotfication(message, "info", "top-center");
    };

    socket.on(C.RAKEBACK_AMOUNT, handleRakebackAmount);
    socket.on(C.ADD_RAKEBACK, handleAddRakeback);

    // Cleanup socket listeners
    return () => {
      socket.off(C.RAKEBACK_AMOUNT, handleRakebackAmount);
      socket.off(C.ADD_RAKEBACK, handleAddRakeback);
    };
  }, [token]);

  const addReward = () => {
    socket.emit(C.ADD_RAKEBACK);
  };


  const [openModal, setOpneModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [bonus, setBonus] = useState(null);
  const [bonususer, setBonususer] = useState(null);
  const [bonusredeemuser, setBonusredeemuser] = useState(null)

  const handleBonusRequest = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const userId = getUID;
      const response = await fetch('https://api.skyla.game/bonus/userbonus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
        },
        body: JSON.stringify({
          userid: userId,
          role: 'user'
        })
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data = await response.json();
      setSuccess(true);
      if (Array.isArray(data) && data.length > 0) {
        setBonususer(data[0]); // Extract the first object from the array
        setSuccess(true);
        console.log('User Bonus Success:', data[0]);
      } else {
        throw new Error('No data found');
      }
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };
  

  const handleBonusRedeemRequest = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const userId = getUID;
      const response = await fetch('https://api.skyla.game/bonus/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
        },
        body: JSON.stringify({
          userid: userId
        })
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data = await response.json();
      setSuccess(true);
      setBonusredeemuser(data);
      console.log('User Bonus Redeem:', data);
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };
  const fetchGameDeatils = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const userId = getUID;
      const response = await fetch('https://api.skyla.game/bonus/bonusgame', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053'
        },
        body: JSON.stringify({
          userid: userId,
          role: 'user'
        })
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const data = await response.json();
      setSuccess(true);
      if (Array.isArray(data) && data.length > 0) {
        setBonus(data[0]); // Extract the first object from the array
        setSuccess(true);
        console.log('Game Bonus Success:', data[0]);
      } else {
        throw new Error('No data found');
      }
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGameDeatils()
    handleBonusRedeemRequest()
    handleBonusRequest()
  }, [])
  const formatAmount = (amount) => `$${amount.toFixed(2)}`;
  const history = useHistory();

  const [reedemcode, setReedemCode] = useState(null)

  const handleRedeemCode = async () => {

    if(!reedemcode) return sendNotfication('Please Enter Reedeam Code !')

    const url = 'https://api.skyla.game/bonus/redeem-bonus/redeem';
    const headers = {
      'Content-Type': 'application/json',
      'role-key': '8a37f45e02c57b489f9b5a9949154cf3b8c48540744185c463df5f79f25d9053',
    };
    const payload = {
      userid: getUID,
      code: reedemcode, // Assuming you have a code to redeem
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.status === 200) {
        sendNotfication(data.message); // Show success notification
        // setAmount(data.amount); // Update amount if needed
        window.location.reload();
      } else if (response.status === 400) {
        sendNotfication(data.error); // Show error notification for bad request
      } else if (response.status === 404) {
        sendNotfication(data.error); // Show error notification for not found
      } else if (response.status === 500) {
        sendNotfication(data.error); // Show error notification for server error
      }
    } catch (error) {
      sendNotfication('Failed to redeem bonus'); // Handle network errors
    }
    finally
    {
      setReedemCode("");
    }
  };
  const calculateTotalBonus = (user, bonus) => {
    if (!user || !bonus) return 0;
  
    const userBonuses = 
      (user.vipbonus || 0) + 
      (user.specialbonus || 0) + 
      (user.generalbonus || 0) + 
      (user.joiningbonus || 0);
  
    const additionalBonuses = 
      (bonus.dailybonus || 0) + 
      (bonus.weeklybonus || 0) + 
      (bonus.monthlybonus || 0) + 
      (bonus.depositbonus || 0) + 
      (bonus.rakebackbonus || 0);
  
    // Add null check for bonusredeemuser
    const redeemBonusTotal = bonusredeemuser?.reduce((sum, item) => sum + (item.amount || 0), 0) || 0;
  
    return userBonuses + additionalBonuses + redeemBonusTotal;
  };

  const totalBonus = calculateTotalBonus(bonususer, bonus,bonusredeemuser);
  return (
    <>
      {
        isMobile() ? (<BonusMobile />) : (
          <>
            <Container>
              <Header>
                <Title>Bonus</Title>
                <RedeemWrapper>
                  <RedeemInput onChange={(e)=>setReedemCode(e.target.value)} value={reedemcode} placeholder="Redeem your bonus here." />
                  <RedeemButton onClick={handleRedeemCode}>Redeem Code</RedeemButton>
                </RedeemWrapper>
              </Header>

              <BonusCard>
                <BonusContent>
                  <BonusType1>
                    <div className="label">Total Bonus Claimed(USD)</div>
                  </BonusType1>
                  <TotalAmount>${totalBonus.toFixed(2)}</TotalAmount>
                  <BonusTypes>
                    <BonusType>
                      <div className="label">Total VIP Bonus</div>
                      <div className="amount">${bonususer?.vipbonus?.toFixed(2) || "0.00"}</div>
                    </BonusType>
                    <BonusType>
                      <div className="label">Total Special Bonus</div>
                      <div className="amount">${bonususer?.specialbonus?.toFixed(2) || "0.00"}</div>
                    </BonusType>
                    <BonusType>
                      <div className="label">Total General Bonus</div>
                      <div className="amount">${bonususer?.generalbonus?.toFixed(2) || "0.00"}</div>
                    </BonusType>
                  </BonusTypes>
                  <DetailsButton onClick={() => setOpneModal(true)}>
                    Details →
                  </DetailsButton>
                </BonusContent>
                <BonusCardImage
                  src="/assets/images/bcgames/bonus/bn1.webp"
                  alt="Banner"
                />
              </BonusCard>

              <DepositBonusCard>
                <DepositHeader>
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

                    <BonusAmount1>Deposit Bonus </BonusAmount1>
                    <BonusAmount>Get Upto:<span style={{ color: "#26E284", fontSize: "16px" }}> 20000 SBC</span></BonusAmount>

                  </div>
                  {/* <BonusAmount>Get up to: 20000 </BonusAmount> */}
                  {/* <BonusTimer>Bonus ends: 18d 15h 54m</BonusTimer> */}
                </DepositHeader>

                <ProgressContainer>
                  <ProgressPoints>
                    <ProgressPoint active={progress >= 25}>
                      <img src="/assets/images/bcgames/bonus/bg1.webp" alt="Icon 1" />

                    </ProgressPoint>
                    <ProgressPoint active={progress >= 50}>
                      <img src="/assets/images/bcgames/bonus/bg2.webp" alt="Icon 2" />

                    </ProgressPoint>
                    <ProgressPoint active={progress >= 75}>
                      <img src="/assets/images/bcgames/bonus/bg3.webp" alt="Icon 3" />

                    </ProgressPoint>
                    <ProgressPoint active={progress >= 100}>
                      <img src="/assets/images/bcgames/bonus/bg4.webp" alt="Icon 4" />

                    </ProgressPoint>
                  </ProgressPoints>
                  <ProgressBar progress={progress} />
                  <ProgressPoints>
                    <ProgressPoint active={progress >= 25}>
                      {/* <img src="https://bc.game/modules/bonus2/assets/coin-ef1c21e7.png" alt="Icon 1" /> */}
                      +180%
                    </ProgressPoint>
                    <ProgressPoint active={progress >= 50}>
                      {/* <img src="https://bc.game/modules/bonus2/assets/coin2_closed-4758251f.png" alt="Icon 2" /> */}
                      +240%
                    </ProgressPoint>
                    <ProgressPoint active={progress >= 75}>
                      {/* <img src="https://bc.game/modules/bonus2/assets/coin3_closed-7cd09531.png" alt="Icon 3" /> */}
                      +300%
                    </ProgressPoint>
                    <ProgressPoint active={progress >= 100}>
                      {/* <img src="https://bc.game/modules/bonus2/assets/coin4_closed-e0ce66d3.png" alt="Icon 4" /> */}
                      +360%
                    </ProgressPoint>
                  </ProgressPoints>
                </ProgressContainer>
                <div style={{ width: "100%", display: 'flex', justifyContent: "space-between", alignItems: "center", marginTop: "8px" }}>
                  {/* <BonusTimer>Bonus ends: 18d 15h 54m</BonusTimer> */}
                  <DepositButton onClick={() => history.push('wallet')}>Deposit Now</DepositButton>
                </div>
              </DepositBonusCard>


            </Container>
            <div style={{ padding: "10px" }}>
              <Title1>General Bonus</Title1>
              <GridContainer>
                {/* Quests Card */}
                <CardBG>
                  <Card background="radial-gradient(71.39% 51.03% at 46.49% 7.5%, rgba(111, 60, 255, .6) 0%, rgba(111, 60, 255, 0) 98%)">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc1.webp" />
                    <CardTitle>Daily Bonus</CardTitle>
                    <ContainerBottom>
                      <StatsContainer >
                        {/* <StatRow>
                          <span>Daily Bonus:</span>
                          <span>0/1</span>
                        </StatRow> */}
                        {/* <StatRow>
                     <span>Weekly Quests:</span>
                     <span>0/1</span>
                   </StatRow> */}
                    <StatRow>
                          <span><LockIcon /></span>
                          <span style={{ marginLeft: "4px" }}>Available at VIP 25</span>
                        </StatRow>
                      </StatsContainer>
                      <ClaimButton1>Claim</ClaimButton1>
                    </ContainerBottom>
                  </Card>
                </CardBG>

                {/* Lucky Spin Card */}
                {/* <CardBG>
               <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(206, 124, 0, .6) 0%, rgba(206, 124, 0, 0) 60%)">
                 <ImagePlaceholder  src="/assets/images/bcgames/bonus/bcc2.webp"  />
                 <CardTitle>Lucky Spin</CardTitle>
                <ContainerBottom>
                <StatsContainer>
                   <StatRow>
                     <span>VIP Spin:</span>
                     <span>Reach VIP 1</span>
                   </StatRow>
                   <StatRow>
                     <span>Daily Spin:</span>
                     <span>$0.00/$200.00</span>
                   </StatRow>
                 </StatsContainer>
                 <ClaimButton>Claim</ClaimButton>
                </ContainerBottom>
               </Card>
   
             </CardBG> */}
                {/* Roll Competition Card */}
                {/* <CardBG>
               <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(165, 207, 0, .6) 0%, rgba(165, 207, 0, 0) 60%)">
                 <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc3.webp" />
                 <CardTitle>Roll Competition</CardTitle>
                 <ContainerBottom>
                 <StatsContainer>
                   <StatRow>
                     <span>Total claimed:</span>
                     <span>$0.00</span>
                   </StatRow>
                   <Timer>Ready in 15h 21m 28s</Timer>
                 </StatsContainer>
                 <ClaimButton>Claim</ClaimButton>
                 </ContainerBottom>
               </Card>
             </CardBG> */}

                {/* Rakeback Card */}
                <CardBG>
                  <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(214, 129, 0, .6) 0%, rgba(214, 129, 0, 0) 60%)">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc4.webp" />

                    <CardTitle>Rakeback</CardTitle>

                    <ContainerBottom>
                      <StatsContainer>
                        <StatRow>
                          <span>Ready to claim:</span>
                          <span>{amount}</span>
                        </StatRow>
                      </StatsContainer>
                      <ClaimButton 
                        amount={amount}
                        onClick={addReward}
                      >
                        Claim
                      </ClaimButton>
                    </ContainerBottom>


                  </Card>
                </CardBG>

                <CardBG>
                  <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(196, 44, 44, 0.45), transparent 60%)">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc10.webp" />
                    <CardTitle>Vault Pro</CardTitle>
                    <ContainerBottom >
                      {/* <StatsContainer>
                        <StatRow>
                          <span> My Holdings:</span>
                          <span>₹0.00</span>





                        </StatRow>
                      </StatsContainer> */}
                      {/* <StatsContainer>
                        <StatRow>
                          <span>Total Return:</span>
                          <span>₹0.00</span>

                        </StatRow>
                      </StatsContainer> */}
                      <ClaimButton style={{marginTop:'60px'}} onClick={() => history.push("/vault-pro")}>Transfer</ClaimButton>
                    </ContainerBottom>
                  </Card>
                </CardBG>
              </GridContainer>
            </div>
            <div style={{ padding: "10px" }}>
              <Title1>VIP Bonus</Title1>
              <GridContainer>
                {/* Quests Card */}
                <CardBG>
                  <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(188, 1, 191, .6) 0%, rgba(188, 1, 191, 0) 60%)">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc5.webp" />
                    <CardTitle>Welcome Bonus</CardTitle>
                    <ContainerBottom>
                      {/* <StatsContainer> */}
                      <StatRow>
                        <span><LockIcon /></span>
                        <span style={{ marginLeft: "4px", marginTop:"2px" }}>Available at VIP 11</span>
                      </StatRow>
                      {/* <StatRow>
                     <span>Weekly Quests:</span>
                     <span>0/1</span>
                   </StatRow> */}
                      {/* </StatsContainer> */}
                      {/* <ClaimButton >Claim</ClaimButton> */}
                    </ContainerBottom>
                  </Card>
                </CardBG>

                {/* Lucky Spin Card */}
                {/* <CardBG>
                  <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(13, 148, 111, .6) 0%, rgba(13, 148, 111, 0) 60%)">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc6.webp" />
                    <CardTitle>Special Bonus</CardTitle>
                    <ContainerBottom>
                      <StatsContainer>
                       
                   <StatRow>
                     <span>contact your service provider to Claim it before</span>
                  
                   </StatRow>


                        <StatRow>
                         
                         Special Bonus Available at VIP 15
                        </StatRow>
                      </StatsContainer>
                     
                    </ContainerBottom>
                  </Card>

                </CardBG> */}
                {/* Roll Competition Card */}
                <CardBG>
                  <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(6, 115, 217, .6) 0%, rgba(6, 115, 217, 0) 60%)">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc6.webp" />
                    <CardTitle>Weekly Cashback</CardTitle>
                    <ContainerBottom>
                      <StatsContainer>
                        {/* <StatRow>
                          <span>Total claimed:</span>
                          <span>$0.00</span>
                        </StatRow> */}
                        {/* <Timer>Ready in 15h 21m 28s</Timer> */}

                        <StatRow>
                          <span><LockIcon /></span>
                          <span style={{ marginLeft: "4px" }}>Available at VIP 22</span>
                        </StatRow>
                      </StatsContainer>
                      {/* <ClaimButton>Claim</ClaimButton> */}
                    </ContainerBottom>
                  </Card>
                </CardBG>

                {/* Rakeback Card */}
                <CardBG>
                  <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(179, 9, 61, .6) 0%, rgba(179, 9, 61, 0) 60%)">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc7.webp" />

                    <CardTitle>Monthly Cashback</CardTitle>

                    <ContainerBottom>
                      <StatsContainer>
                        {/* <StatRow>
                          <span>Ready to claim:</span>
                          <span>0 </span>
                        </StatRow> */}

                        <StatRow>
                          <span><LockIcon /></span>
                          <span style={{ marginLeft: "4px" }}>Available at VIP 22</span>
                        </StatRow>
                      </StatsContainer>
                      {/* <ClaimButton style={{ marginTop: "25px" }}>Claim</ClaimButton> */}
                    </ContainerBottom>


                  </Card>
                </CardBG>
                <CardBG>
                  <Card background="radial-gradient(116.81% 83.5% at 46.23% 9.2%, rgba(65, 84, 254, .6) 0%, rgba(65, 84, 254, 0) 60%)">
                    <ImagePlaceholder src="/assets/images/bcgames/bonus/bcc8.webp" />

                    <CardTitle>Sports Weekly Bonus</CardTitle>

                    <ContainerBottom>
                      <StatsContainer>
                      <StatRow>
                        <span><LockIcon /></span>
                        <span style={{ marginLeft: "4px" }}>Available at VIP 22</span>
                      </StatRow>
                      </StatsContainer>
                      {/* <ClaimButton style={{ marginTop: "25px" }}>Claim</ClaimButton> */}
                    </ContainerBottom>


                  </Card>
                </CardBG>

                <ModalOverlay show={openModal}>
                  <ModalContent>
                    <ModalHeader>
                      <ModalTitle>Bonus Details</ModalTitle>
                      <CloseButton onClick={() => setOpneModal(false)}>
                        <X size={20} />
                      </CloseButton>
                    </ModalHeader>
                    <ModalBody>
                    <BonusDetails data={bonus} />
                    </ModalBody>
                  </ModalContent>
                </ModalOverlay>
              </GridContainer>
            </div>
          </>
        )
      }
    </>
  );
};

export default Bonus;
