import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useBetting } from '../../../../../../../../context/BettingContext';
import PropTypes from 'prop-types';

const Container = styled.div`
  width: 100%;

  font-family: Arial, sans-serif;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
`;

const MatchOddsSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Title = styled.div`
  background: #37474f;
  color: white;
  padding: 8px 15px;
  border-radius: 4px;
  font-weight: bold;
`;

const InPlay = styled.div`
  color: #4CAF50;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
`;

const MaxValue = styled.div`
  background: rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  border-radius: 3px;
  color: #000;
  font-weight: 500;
`;

const Matched = styled.div`
  text-align: right;
  color: #000;
  span {
    font-weight: bold;
    color: #000;
  }
`;

const TableContainer = styled.div`
  margin-top: 10px;
`;

const SelectionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #f8f9fa;
  color: #000;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 30px;
`;

const ActionButton = styled.div`
  padding: 5px 15px;
  background: ${props => props.back ? '#72BBEF' : '#FFB6C1'};
  border: none;
  border-radius: 3px;
  cursor: pointer;
  color: #000;
  font-weight: 500;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(6, 1fr);
  border-bottom: 1px solid #ddd;
  color: #000;
  background-color: #fff;
`;

const TeamCell = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  font-weight: 500;
  border-right: 1px solid #ddd;

  svg {
    color: #666;
    min-width: 16px;
    &:hover {
      color: #333;
    }
  }
`;

const OddsCell = styled.div`
   display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 5px;
  background: ${props => {
    if (props.type === 'back') {
      // Keep the blue gradients as they are
      switch(props.index) {
        case 0:
          return 'linear-gradient(90deg, #E3F2FD, #BBDEFB)';
        case 1:
          return 'linear-gradient(90deg, #BBDEFB, #90CAF9)';
        case 2:
          return 'linear-gradient(90deg, #90CAF9, #64B5F6)';
        default:
          return 'linear-gradient(90deg, #E3F2FD, #BBDEFB)';
      }
    } else {
      // Updated pink gradients for lay cells
      switch(props.index) {
        case 2:
          return 'linear-gradient(90deg, #FFE6EA, #FFD4DA)';
        case 1:
          return 'linear-gradient(90deg, #FFD4DA, #FFC1C9)';
        case 0:
          return 'linear-gradient(90deg, #FFC1C9, #FFAEB8)';
        default:
          return 'linear-gradient(90deg, #FFE6EA, #FFD4DA)';
      }
    }
  }};
  border: 1px solid #ddd;
  cursor: pointer;
  color: #000;

  span:first-child {
    font-weight: bold;
    color: #000;
  }
  span:last-child {
    font-size: 0.9em;
    color: #333;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const GraphIcon = () => (
  <svg 
    width="16" 
    height="16" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2"
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
  </svg>
);

const InPlayBet = ({ matchData }) => {
  const { addBet } = useBetting();
  const [selectedBack, setSelectedBack] = useState(null);
  const [selectedLay, setSelectedLay] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [oddsData, setOddsData] = useState([]);
 // If no matchData is provided, show a fallback UI
 if (!matchData || !matchData.id) {
  return (
    <div className="p-4 text-center bg-gray-100 rounded-lg">
      <p className="text-gray-600">No match data available</p>
    </div>
  );
}

  // Fetch odds data
  const fetchOddsData = async () => {
    try {
      const response = await fetch(`https://api.skyla.game/sports/market-odds?market_id=${matchData.id}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching odds:', error);
      return null;
    }
  };

  // Transform API data to required format
  const transformOddsData = (apiData) => {
    if (!apiData || !apiData.length) return [];

    const market = apiData[0];
    const teams = [];

    // Add team1 data
    if (market.runners[0]) {
      teams.push({
        team: matchData.team1,
        odds: [
          ...market.runners[0].ex.availableToBack.map(back => ({
            value: back.price || '--',
            volume: back.size || 0
          })),
          ...market.runners[0].ex.availableToLay.map(lay => ({
            value: lay.price || '--',
            volume: lay.size || 0
          }))
        ]
      });
    }

    // Add team2 data
    if (market.runners[1]) {
      teams.push({
        team: matchData.team2,
        odds: [
          ...market.runners[1].ex.availableToBack.map(back => ({
            value: back.price || '--',
            volume: back.size || 0
          })),
          ...market.runners[1].ex.availableToLay.map(lay => ({
            value: lay.price || '--',
            volume: lay.size || 0
          }))
        ]
      });
    }

    // Add draw for test matches (you might want to add a condition to check if it's a test match)
    if (matchData.isTestMatch) {
      teams.push({
        team: "The Draw",
        odds: [
          { value: '--', volume: 0 },
          { value: '--', volume: 0 },
          { value: '--', volume: 0 },
          { value: '--', volume: 0 },
          { value: '--', volume: 0 },
          { value: '--', volume: 0 }
        ]
      });
    }

    return teams;
  };

  // Fetch odds data periodically
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchOddsData();
      if (data) {
        const transformedData = transformOddsData(data);
        setOddsData(transformedData);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 1000); // Update every second

    return () => clearInterval(interval);
  }, [matchData.id]);

  const handleOddsClick = (team, type, odds, volume, rowIndex) => {
    if (odds.value === '--') return;

    const betDetails = {
      matchId: matchData.id,
      matchTitle: `${matchData.team1} v ${matchData.team2}`,
      team1: matchData.team1,
      team2: matchData.team2,
      selectionName: team,
      category: team === "The Draw" ? 'x' : team === matchData.team1 ? 'matched' : 'two',
      type,
      odds: odds.value,
      isBlue: type === 'back',
      stake: '',
      liability: '0.00',
      volume
    };

    if (type === 'back') {
      setSelectedBack(odds.value);
      setSelectedLay(null);
    } else {
      setSelectedLay(odds.value);
      setSelectedBack(null);
    }
    setSelectedRow(rowIndex);

    addBet(betDetails);
  };

  return (
    <Container>
      <Header>
        <MatchOddsSection>
          <Title>Match Odds</Title>
          <InPlay>
            <span>⏱</span>
            {matchData.inplay ? 'In-Play' : 'Going In-Play'}
          </InPlay>
        </MatchOddsSection>
        <Matched>
          Matched <span>PTE {matchData.pte??0}</span>
        </Matched>
      </Header>
      <TableContainer>
        <SelectionsRow>
          <div>{oddsData.length} selections</div>
          <div>99.9%</div>
          <ButtonGroup>
            <ActionButton back>Back all</ActionButton>
            <ActionButton>Lay all</ActionButton>
          </ButtonGroup>
          <div>99.9%</div>
        </SelectionsRow>
        {oddsData.map((row, index) => (
          <TableRow key={index} suspended={matchData.isSuspended}>
            <TeamCell>
              <GraphIcon />
              {row.team}
            </TeamCell>
            {row.odds.slice(0, 3).map((odd, i) => (
              <OddsCell 
                key={i} 
                type="back" 
                index={i}
                suspended={matchData.isSuspended}
                selected={selectedBack === odd.value && selectedRow === index}
                onClick={() => !matchData.isSuspended && handleOddsClick(row.team, 'back', odd, odd.volume, index)}
              >
                <span>{odd.value}</span>
                <span>{odd.volume.toLocaleString()}</span>
              </OddsCell>
            ))}
            {row.odds.slice(3).map((odd, i) => (
              <OddsCell 
                key={i + 3} 
                type="lay" 
                index={i}
                suspended={matchData.isSuspended}
                selected={selectedLay === odd.value && selectedRow === index}
                onClick={() => !matchData.isSuspended && handleOddsClick(row.team, 'lay', odd, odd.volume, index)}
              >
                <span>{odd.value}</span>
                <span>{odd.volume.toLocaleString()}</span>
              </OddsCell>
            ))}
          </TableRow>
        ))}
      </TableContainer>
    </Container>
  );
};

InPlayBet.propTypes = {
  matchData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    team1: PropTypes.string.isRequired,
    team2: PropTypes.string.isRequired,
    inplay: PropTypes.bool,
    isTestMatch: PropTypes.bool,
    isSuspended: PropTypes.bool,
    pte: PropTypes.number
  })
};

export default InPlayBet;