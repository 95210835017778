import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useBetting } from '../../../../../context/BettingContext';


const Container = styled.div`
  width: 100%;
  background: white;
`;

const Header = styled.div`
  background: #2c3e50;
  color: white;
   padding: 14px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

const SubHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  background: #f5f5f5;
  padding: 6px 8px;
  border-bottom: 1px solid #ddd;
  font-weight: 500;
  color: #000000;
  font-size: 11px;
`;

const MatchTitle = styled.div`
  padding: 6px 8px;
  background: white;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #000000;
  font-size: 11px;
`;

const Dot = styled.span`
  width: 8px;
  height: 8px;
  background: #2ecc71;
  border-radius: 50%;
  display: inline-block;
`;

const BetRowContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  padding: 6px 8px;
  background: #ffebee;
  align-items: center;
  gap: 6px;
`;


const BetRowContainerBlue = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  padding: 6px 8px;
  background: #D4E7F7;
  align-items: center;
  gap: 6px;
`;

const TeamSection = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const CloseIcon = styled.span`
  color: #e74c3c;
  margin-right: 3px;
  cursor: pointer;
  font-size: 12px;
`;

const TeamName = styled.span`
  margin-right: 3px;
  font-size: 10px !important;
  color: #000000;
`;

const MatchOdds = styled.span`
  color: #000000;
  font-size: 0.8em;
`;

const OddsInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 3px;
  text-align: right;
  font-size: 0.8em;
  outline: none;
  color: #000000;
  &:focus {
    border-color: #95a5a6;
  }
`;

const ArrowsContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Arrow = styled.span`
  cursor: pointer;
  font-size: 8px;
  color: #666;
  line-height: 1;
  &:hover {
    color: #333;
  }
`;

const LiabilityValue = styled.div`
  text-align: right;
  padding: 4px 6px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  min-width: 30px;
  color: #e74c3c;
  font-weight: 500;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
`;

const QuickStakeButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 4px;
  padding: 6px 8px;
  background: #ffebee;
`;
const QuickStakeButtonsBlue = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 4px;
  padding: 6px 8px;
  background: #D4E7F7;
`;


const StakeButton = styled.div`
  padding: 4px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  color: black;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.8em;
  &:hover {
    background: #f5f5f5;
  }
`;

const Footer = styled.div`
  padding: 6px 8px;
  background: #ffebee;
`;
const FooterBlue = styled.div`
  padding: 6px 8px;
  background: #D4E7F7
`;


const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px;
  padding: 6px;
`;

const Button = styled.button`
  padding: 6px 8px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8em;
  
  ${props => props.primary ? `
    background: #95a5a6;
    color: white;
    &:hover {
      background: #7f8c8d;
    }
  ` : `
    background: white;
    border: 1px solid #ddd;
    &:hover {
      background: #f5f5f5;
    }
  `}
`;

const Button1 = styled.div`
  padding: 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  color: #000000;
  align-items: center;
  justify-content: center;
  font-size: 0.85em;
  
  ${props => props.primary ? `
    background: #95a5a6;
    color: white;
    &:hover {
      background: #7f8c8d;
    }
  ` : `
    background: white;
    border: 1px solid #ddd;
    &:hover {
      background: #f5f5f5;
    }
  `}
`;

const BetConfirm = styled.div`
  padding: 6px 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #000000;
  font-size: 11px;
  
  span {
    color: #000000;
  }
`;

const MinBetText = styled.div`
  text-align: right;
  padding: 3px;
  color: #000000;
  font-size: 10px;
`;

const LiabilityText = styled.div`
  text-align: right;
  padding: 3px;
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  color: #000000;
  font-size: 11px;
`;

const BetSlip = () => {
  const [betsState, setBetsState] = useState([]);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const { selectedBets, removeBet, storePlacedBets } = useBetting();
    const [totalLiability, setTotalLiability] = useState(0);

    useEffect(() => {
        if (selectedBets?.length > 0) {
            const initialBets = selectedBets.map(bet => ({
                ...bet,
                stake: '',
                liability: '0.00'
            }));
            setBetsState(initialBets);
        } else {
            setBetsState([]);
        }
    }, [selectedBets]);

    useEffect(() => {
        // Calculate total liability
        const total = betsState.reduce((sum, bet) => {
            return sum + (parseFloat(bet.liability) || 0);
        }, 0);
        setTotalLiability(total.toFixed(2));
    }, [betsState]);

    const handleStakeChange = (value, betId) => {
        setBetsState(prevState => 
            prevState.map(bet => {
                if (bet.id === betId) {
                    const calculatedLiability = ((parseFloat(bet.odds) - 1) * parseFloat(value) || 0).toFixed(2);
                    return {
                        ...bet,
                        stake: value,
                        liability: calculatedLiability
                    };
                }
                return bet;
            })
        );
    };

    const handleQuickStake = (value, betId) => {
        handleStakeChange(value.toString(), betId);
    };

    const handleOddsChange = (newOdds, betId) => {
        setBetsState(prevState => 
            prevState.map(bet => {
                if (bet.id === betId) {
                    const calculatedLiability = bet.stake ? 
                        ((parseFloat(newOdds) - 1) * parseFloat(bet.stake)).toFixed(2) : 
                        '0.00';
                    return {
                        ...bet,
                        odds: newOdds,
                        liability: calculatedLiability
                    };
                }
                return bet;
            })
        );
    };

    const handleCancelAll = () => {
        selectedBets.forEach(bet => removeBet(bet.id));
        setBetsState([]);
        setIsConfirmed(false);
    };

    const handlePlaceBets = () => {
        if (!isConfirmed) {
            alert('Please confirm your bets before placing them.');
            return;
        }

        // Validate bets
        const validBets = betsState.filter(bet => 
            bet.stake && 
            parseFloat(bet.stake) >= 4 && 
            bet.odds && 
            parseFloat(bet.odds) > 1
        );

        if (validBets.length === 0) {
            alert('Please enter valid stakes and odds for your bets.');
            return;
        }

        // Format bets for storage
        const betsToStore = validBets.map(bet => ({
            id: bet.id,
            type: bet.type,
            matchTitle: bet.matchTitle,
            selectionName: bet.selectionName,
            category: bet.category,
            odds: parseFloat(bet.odds),
            stake: parseFloat(bet.stake),
            liability: parseFloat(bet.liability),
            timestamp: new Date().toISOString()
        }));

        // Store the bets using context
        storePlacedBets(betsToStore);

        // Log placed bets
        console.log('Placed Bets:', betsToStore);

        // Clear bet slip
        handleCancelAll();

        // Show confirmation
        alert('Bets placed successfully!');
    };

    return (
      <Container>
      <Header>
          <span>Bet Slip ({selectedBets?.length || 0})</span>
      </Header>

      {selectedBets?.length === 0 ? (
          <div style={{ padding: '8px', textAlign: 'center', color: '#666' }}>
              No Bets
          </div>
      ) : (
          betsState.map((bet) => (
              <div key={bet.id}>
                  <SubHeader>
                      {bet?.type === "lay" ? 
                          <div>Lay (Bet Against)</div> : 
                          <div>Back (Bet For)</div>
                      }
                      <div>Odds</div>
                      <div>Stake</div>
                      {bet?.type === "lay" ? 
                          <div>Liability</div> : 
                          <div>Profit</div>
                      }
                  </SubHeader>

                  <MatchTitle>
                      <Dot />
                      {bet?.matchTitle}
                  </MatchTitle>

                  {bet?.type === "lay" ? (
                      <>
                          <BetRowContainer>
                              <TeamSection>
                                  <CloseIcon onClick={() => removeBet(bet.id)}>✕</CloseIcon>
                                  <TeamName>{bet.selectionName}</TeamName>
                                  <MatchOdds>{bet.category}</MatchOdds>
                              </TeamSection>
                              <OddsInputContainer>
                                  <StyledInput
                                      type="text"
                                      value={bet.odds}
                                      onChange={(e) => handleOddsChange(e.target.value, bet.id)}
                                      style={{ paddingRight: '20px' }}
                                  />
                                  <ArrowsContainer>
                                      <Arrow onClick={() => handleOddsChange((parseFloat(bet.odds) + 0.01).toFixed(2), bet.id)}>▲</Arrow>
                                      <Arrow onClick={() => handleOddsChange((parseFloat(bet.odds) - 0.01).toFixed(2), bet.id)}>▼</Arrow>
                                  </ArrowsContainer>
                              </OddsInputContainer>
                              <StyledInput
                                  type="text"
                                  value={bet.stake}
                                  onChange={(e) => handleStakeChange(e.target.value, bet.id)}
                                  placeholder="0"
                              />
                              <LiabilityValue>
                                  {bet.liability}
                              </LiabilityValue>
                          </BetRowContainer>
                          <QuickStakeButtons>
                              {[4, 30, 50, 200, 500, 1000].map(value => (
                                  <StakeButton key={value} onClick={() => handleQuickStake(value, bet.id)}>
                                      {value}
                                  </StakeButton>
                              ))}
                          </QuickStakeButtons>
                          <Footer>
                              <MinBetText>Min Bet: 4</MinBetText>
                          </Footer>
                      </>
                  ) : (
                      <>
                          <BetRowContainerBlue>
                              <TeamSection>
                                  <CloseIcon onClick={() => removeBet(bet.id)}>✕</CloseIcon>
                                  <TeamName>{bet.selectionName}</TeamName>
                                  <MatchOdds>{bet.category}</MatchOdds>
                              </TeamSection>
                              <OddsInputContainer>
                                  <StyledInput
                                      type="text"
                                      value={bet.odds}
                                      onChange={(e) => handleOddsChange(e.target.value, bet.id)}
                                      style={{ paddingRight: '20px' }}
                                  />
                                  <ArrowsContainer>
                                      <Arrow onClick={() => handleOddsChange((parseFloat(bet.odds) + 0.01).toFixed(2), bet.id)}>▲</Arrow>
                                      <Arrow onClick={() => handleOddsChange((parseFloat(bet.odds) - 0.01).toFixed(2), bet.id)}>▼</Arrow>
                                  </ArrowsContainer>
                              </OddsInputContainer>
                              <StyledInput
                                  type="text"
                                  value={bet.stake}
                                  onChange={(e) => handleStakeChange(e.target.value, bet.id)}
                                  placeholder="0"
                              />
                              <LiabilityValue>
                                  {bet.liability}
                              </LiabilityValue>
                          </BetRowContainerBlue>
                          <QuickStakeButtonsBlue>
                              {[4, 30, 50, 200, 500, 1000].map(value => (
                                  <StakeButton key={value} onClick={() => handleQuickStake(value, bet.id)}>
                                      {value}
                                  </StakeButton>
                              ))}
                          </QuickStakeButtonsBlue>
                          <FooterBlue>
                              <MinBetText>Min Bet: 4</MinBetText>
                          </FooterBlue>
                      </>
                  )}
              </div>
          ))
      )}

      {selectedBets?.length > 0 && (
          <>
              <LiabilityText>Total Liability: {totalLiability}</LiabilityText>
              <ActionButtons>
                  <Button1 onClick={handleCancelAll}>Cancel All</Button1>
                  <Button primary onClick={handlePlaceBets}>Place Bets</Button>
              </ActionButtons>

              <BetConfirm>
                  <input
                      type="checkbox"
                      checked={isConfirmed}
                      onChange={(e) => setIsConfirmed(e.target.checked)}
                  />
                  <span>Please confirm your bets.</span>
              </BetConfirm>
          </>
      )}
  </Container>
    );
};

export default BetSlip;