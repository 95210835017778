import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import SidebarHello from '../Components/HelloGameLayoutComponent/SidebarHello';
import Header from '../Components/HelloGameLayoutComponent/HederHello';
import styled from 'styled-components';
import { Event, decode, encode, wait, isMobile, sendNotfication, getUID } from "../../Helper";
import WalletAlert from "../../App/Components/User/Wallet/Alert";
import C from "../../Constant";
import storage from "../../Storage"
import UserHeader from './Parts/Header/Header-User';
import GuestHeader from './Parts/Header/Header-Guest';
import socket from "../../Socket";
import MobileMenu from './Parts/Menu/MobileMenu';
import HelloFooter from '../Components/HelloGameLayoutComponent/HelloFooter';
import SkylaSidebar from '../Components/SkylaSiebar';
import { SidebarProvider, useSidebar } from '../../context/SidebarContext';

const SIDEBAR_WIDTH = 245;
const HEADER_HEIGHT = 60;

const AppContainer = styled.div`
  display: flex;
  background-color:#232626;
  color: white;
  font-family: Arial, sans-serif;
    // z-index:1000;
`;

const MainArea = styled.div`
  flex: 1;
  padding-top:60px;
  min-height: 100vh;
  width: calc(100vw - ${SIDEBAR_WIDTH}px);
  overflow-x: hidden;
    z-index:-1 important;
    margin: ${props => props.isSmallScreen ? '0px' : props.isCollapsed ?'0px 120px 0px 170px': '0px 20px 0px 250px ' };

// margin-left: ${props => props.isSmallScreen ? '0px' : props.isCollapsed ? '0px' : '239px'};

`;

const ContentContainer = styled.div`
  padding: 0px 24px;
  margin-top: 20px;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);

  @media (max-width: 768px) {
    padding: 4px 10px;
      margin-top: 5px;
  }
`;


const HeaderMainConatiner = styled.div`
position:fixed;
top: 0;
// left: ${props => props.isSmallScreen ? '0px' : props.isCollapsed ? '80px' : '239px'};
left:0;
right: 0;
z-index: 666;
  width: 100% !important;
`;


const Index = ({ get_wallet, content }) => {
  const [auth, setAuth] = useState(false);
  const { isCollapsed, setIsCollapsed } = useSidebar();

  const uid=getUID;
  console.log("uid is", uid)

  console.log("uisidhhd", isCollapsed)
  const [isCollapsedMobile, setIsCollapsedMobile] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [header, setHeader] = useState(
    <GuestHeader
   
    />
  );
  const getUserInfo = (data) => {
    if (data.status) {
      console.log("data comes In the resposneeeee", data);

    }}

      

  useEffect(()=>{
    if(uid){
      console.log("callled")
      socket.on(C.USER_INFO, data => getUserInfo(decode(data)));
      socket.emit(C.USER_INFO, encode({ id: getUID, coin: storage.getKey('coin') ? storage.getKey('coin'):'', rate: null, game: 'all', first: true }));
    }
  },[uid])

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    socket.on(C.ONLINE, (status) => onlineUser(decode(status)));
    Event.on('showAuthModal', () => {
      sendNotfication("Please Login or Register!", 'success', 'bottom-left', true);
    });

    const sessionCookie = Cookies.get("session");
    if (sessionCookie) {
      socket.emit(C.ONLINE, encode({
        jwt_token: storage.getKey('jwt_token'),
        user_token: storage.getKey('user_token'),
        security_key: storage.getKey('security_key'),
        secret_user: storage.getKey('secret_user'),
        secret_realtime: storage.getKey('secret_realtime'),
        client_system: storage.getKey('client_system'),
        token_key: storage.getKey('token_key'),
        secret_token: storage.getKey('secret_token')
      }));
    }

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const activeAuth = (status) => {
    setAuth(status);
  };

  const onlineUser = (data) => {
    wait(500).then(() => {
      if (data.status === true) {
        storage.setKey('logged', true);
        Cookies.set("uid", data.id, { expires: 14 });
        Cookies.set("auth", true, { expires: 14 });
        storage.setKey('name', data.name);
        storage.setKey('email', data.email);
        storage.setKey('credit', data.credit);
        storage.setKey('avatar', data.avatar);
        storage.setKey('friends', data.friends);
        storage.setKey('room', data.room);
        wait(100).then(() => {
          setHeader(<UserHeader isCollapsedMobile={isCollapsedMobile} setIsCollapsedMobile={setIsCollapsedMobile} />);
        });
      } else {
        wait(1000).then(() => {
          localStorage.clear();
          setHeader(
            <GuestHeader/>
          );
        });
      }
    });
  };

  let wallet;
  try {
    wallet = get_wallet.show;
  } catch (e) {
  }

  return (
    <>
    <AppContainer>
      <MainArea isSmallScreen={isSmallScreen} isCollapsed={isCollapsed}>
    
        <HeaderMainConatiner isSmallScreen={isSmallScreen} isCollapsed={isCollapsed}>
          {header}
        </HeaderMainConatiner>
        <ContentContainer isSmallScreen={isSmallScreen} isCollapsed={isCollapsed}>
          {content}
        </ContentContainer>
    
      </MainArea>
    </AppContainer>
    <HelloFooter />
    </>
  );
};

AppContainer.propTypes = {
  get_wallet: PropTypes.string
};

const mapStateToProps = state => ({
  get_wallet: state.items.get_wallet
});

export default connect(mapStateToProps)(Index);
