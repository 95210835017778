import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import { decode, encode, wait, sendNotfication, getUID } from "../../../../Helper";
import C from "../../../../Constant";
import { Icon } from "@mui/material";
import styled from "styled-components";
import ActiveSessions from "./Sessions";
import LockIcon from "../../../../Icons/Lock";
import EmailIcon from "../../../../Icons/EmailIcon";
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  DialogTitle,
  DialogContent
} from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const ModalContent = styled.div`
  width: 400px;
  background-color: #17181b;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button`
  background: ${(props) => (props.disabled ? '#5DFF0080' : '#5DFF00')};
  color: ${(props) => (props.disabled ? '#00000080' : '#000')};
  border: none;
  border-radius: 24px;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 24px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background 0.3s;

  &:hover {
    background: ${(props) => (props.disabled ? '#5DFF0080' : '#4AE54A')};
  }
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background-color: #1a1a1a;
  border: 1px solid #ffffff15;
  border-radius: 10px;
  color: #ffffff;
  font-size: 14px;
`;
const SecuritySetupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #323738;
`;

const SecuritySetupTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff15;
  width: 100%;
`;

const SecuritySetupContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

const SecurityItem = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  // border: 1px solid #ffffff15;
  padding: 15px;
  border-radius: 10px;
  background-color: #3A4142;
  min-height: 240px;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ItemHeader = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`;

const IconImg = styled.img`
  width: 40px;
  height: 40px;
`;

const Status = styled.div`
  font-size: 12px;
  color: ${(props) => (props.isEnabled ? "green" : "#ffffff50")};
`;

const ItemDetails = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ItemDetailsTitle = styled.div`
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  width: 100%;
`;

const ItemDetailsDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #ffffff80;
  width: 100%;
`;

const EditButton = styled.button`
  color: #fff;
  background-size: 200% auto;
  text-align: center;
  background: linear-gradient(to bottom, #4AE54A, #94E874);
  border: none;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  width: 200px;
  margin-top: 15px;

  &:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 8px 15px;
    color: #fff !important;
    border: 1px solid #da22ff;
  }
`;

const ActiveSessionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  background-color: #323738;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
`;

const ActiveSessionsTitle = styled.div`
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid #ffffff15;
`;

const UpdateModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const UpdateModalPop = styled.div`
  width: 500px;
  padding: 15px;
  border-radius: 10px;
  background-color: #17181b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const UpdateModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UpdateModalTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
`;

const UpdateModalClose = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
`;

const UpdateModalBody = styled.div`
  max-height: max-content;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const UpdateModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const UpdateModalInput = styled.input`
  padding: 15px 10px;
  border: 1px solid #ffffff15;
  border-radius: 10px;
  font-size: 14px;
`;

const UpdateModalBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
`;
const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
`;

const StyledCard = styled.div`
  margin-bottom: 16px;
  background-color: #323738;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
`;

const StyledCardContent = styled.div`
  color: #b0b0b0;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  width: 100%;
  margin: 8px 0;
  padding: 12px;
  font-size: 14px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: 1px solid #ffffff15;
  border-radius: 8px;
`;

const StyledButton = styled.button`
  width: 100%;
  margin-top: 16px;
  padding: 12px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  background-color: ${(props) => (props.disabled ? '#5DFF0080' : '#5DFF00')};
  border: none;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background 0.3s;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#5DFF0080' : '#4AE54A')};
  }
`;

const QRCodeImage = styled.img`
  margin-bottom: 8px;
  max-width: 100%;
  height: auto;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000;

  &:hover {
    color: #ff0000;
  }
`;
const inputStyle = {
  backgroundColor: "#1a1a1a",
  border: "1px solid #ffffff15",
  borderRadius: "10px",
  color: "#fff",
  padding: "10px 12px",
  fontSize: "14px",
  width: "100%",
};

const buttonStyle = {
  backgroundColor: "#5DFF00",
  border: "none",
  borderRadius: "24px",
  color: "#000",
  padding: "12px 24px",
  fontSize: "14px",
  fontWeight: "bold",
  cursor: "pointer",
  width: "50%",
  marginTop: "8px",
};

const disabledButtonStyle = {
  ...buttonStyle,
  backgroundColor: "#5DFF0080",
  cursor: "not-allowed",
};

class Security extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disabled: false,
      password: "",
      newPassword: "",
      confirmPassword: "",
      changePasswordModal: false,
      g2faModal: false,
      twoFaEnabled: false,
      twoFaQrCode: null,
      twoFaSecret: null,
      verifyCode: '',
    };
  }

  async componentDidMount() {
    try {
      // Get initial 2FA status
      const response = await fetch(`https://api.skyla.game/2fa/status/${getUID}`);
      const data = await response.json();
      this.setState({ 
        twoFaEnabled: data.isEnabled,
        loading: false 
      });
    } catch (error) {
      sendNotfication('Failed to get 2FA status', 'error', 'top-center');
      this.setState({ loading: false });
    }
  }

  initiate2FASetup = async () => {
    try {
      const response = await fetch('https://api.skyla.game/2fa/enable', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: getUID
        })
      });

      const data = await response.json();
      
      if (data.qrCode && data.secret) {
        this.setState({
          twoFaQrCode: data.qrCode,
          twoFaSecret: data.secret
        });
      } else {
        sendNotfication('Failed to setup 2FA', 'error', 'top-center');
      }
    } catch (error) {
      sendNotfication('Failed to setup 2FA', 'error', 'top-center');
    }
  };

  verify2FA = async (e) => {
    e.preventDefault();
    const { verifyCode } = this.state;

    if (!verifyCode) {
      sendNotfication('Please enter verification code', 'error', 'top-center');
      return;
    }

    this.setState({ disabled: true });

    try {
      const response = await fetch('https://api.skyla.game/2fa/setup-verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: getUID,
          token: verifyCode
        })
      });

      const data = await response.json();

      if (data.success) {
        this.setState({ 
          twoFaEnabled: true,
          disabled: false,
          twoFaQrCode: null,
          twoFaSecret: null,
          verifyCode: '',
          g2faModal: false
        });
        sendNotfication('2FA enabled successfully', 'success', 'top-center');
      } else {
        this.setState({ disabled: false });
        sendNotfication('Invalid verification code', 'error', 'top-center');
      }
    } catch (error) {
      this.setState({ disabled: false });
      sendNotfication('Failed to verify code', 'error', 'top-center');
    }
  };

  disable2FA = async (e) => {
    e.preventDefault();
    const { verifyCode } = this.state;

    if (!verifyCode) {
      sendNotfication('Please enter verification code', 'error', 'top-center');
      return;
    }

    this.setState({ disabled: true });

    try {
      const response = await fetch('https://api.skyla.game/2fa/disable', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: getUID,
          token: verifyCode
        })
      });

      const data = await response.json();

      if (data.success) {
        this.setState({ 
          twoFaEnabled: false,
          disabled: false,
          verifyCode: '',
          g2faModal: false
        });
        sendNotfication('2FA disabled successfully', 'success', 'top-center');
      } else {
        this.setState({ disabled: false });
        sendNotfication('Invalid verification code', 'error', 'top-center');
      }
    } catch (error) {
      this.setState({ disabled: false });
      sendNotfication('Failed to disable 2FA', 'error', 'top-center');
    }
  };

  changePassword = async (e) => {
    e.preventDefault();
    const { password, newPassword, confirmPassword } = this.state;

    if (!password || !newPassword || !confirmPassword) {
      sendNotfication('Please fill in all password fields', 'error', 'top-center');
      return;
    }

    if (newPassword !== confirmPassword) {
      sendNotfication('New passwords do not match', 'error', 'top-center');
      return;
    }

    this.setState({ disabled: true });

    try {
      const response = await fetch('https://api.skyla.game/user/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: getUID,
          oldPassword: password,
          newPassword: newPassword
        })
      });

      const data = await response.json();

      if (data.success) {
        this.setState({
          disabled: false,
          password: '',
          newPassword: '',
          confirmPassword: '',
          changePasswordModal: false
        });
        sendNotfication('Password changed successfully', 'success', 'top-center');
      } else {
        this.setState({ disabled: false });
        sendNotfication(data.error || 'Failed to change password', 'error', 'top-center');
      }
    } catch (error) {
      this.setState({ disabled: false });
      sendNotfication('Failed to change password', 'error', 'top-center');
    }
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleModal = (modalName, value) => {
    this.setState({ [modalName]: value });
  };

  render() {
    const {
      loading,
      disabled,
      changePasswordModal,
      g2faModal,
      twoFaEnabled,
      twoFaQrCode,
      verifyCode,
    } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <>
        <SecuritySetupWrapper>
          <SecuritySetupTitle>Security Setup</SecuritySetupTitle>
          <SecuritySetupContainer>
            <SecurityItem>
              <ItemHeader>
                <LockIcon />
                <Status isEnabled={true}>Enabled</Status>
              </ItemHeader>
              <ItemDetails>
                <ItemDetailsTitle>Change Password</ItemDetailsTitle>
                <ItemDetailsDescription>
                  Change to a strong password to protect your account from unauthorized access.
                </ItemDetailsDescription>
                <EditButton
                  type="button"
                  onClick={() => this.toggleModal("changePasswordModal", true)}
                >
                  Change Password
                </EditButton>
              </ItemDetails>
            </SecurityItem>

            <SecurityItem>
              <ItemHeader>
                <EmailIcon />
                <Status isEnabled={twoFaEnabled}>
                  {twoFaEnabled ? "Enabled" : "Disabled"}
                </Status>
              </ItemHeader>
              <ItemDetails>
                <ItemDetailsTitle>Two-factor authentication</ItemDetailsTitle>
                <ItemDetailsDescription>
                  Enable Two-factor authentication to protect your account from unauthorized access.
                </ItemDetailsDescription>
                <EditButton
  type="button"
  onClick={() => {
    this.toggleModal("g2faModal", true); // Open modal
    if (!twoFaEnabled) {
      this.initiate2FASetup(); // Only initiate 2FA if not enabled
    }
  }}
>
  {twoFaEnabled ? 'Manage 2FA' : 'Enable 2FA'}
</EditButton>

              </ItemDetails>
            </SecurityItem>
          </SecuritySetupContainer>
        </SecuritySetupWrapper>

        {/* Change Password Modal */}
        <Modal
          open={changePasswordModal}
          onClose={() => this.toggleModal("changePasswordModal", false)}
        >
          <Box sx={modalStyle}>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
              <Box component="form" onSubmit={this.changePassword}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Current Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="New Password"
                  name="newPassword"
                  type="password"
                  value={this.state.newPassword}
                  onChange={this.handleInputChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Confirm New Password"
                  name="confirmPassword"
                  type="password"
                  value={this.state.confirmPassword}
                  onChange={this.handleInputChange}
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={disabled}
                  sx={{ mt: 2 }}
                >
                  {disabled ? 'Changing...' : 'Change Password'}
                </Button>
              </Box>
            </DialogContent>
          </Box>
        </Modal>

        {/* 2FA Modal */}
        {g2faModal && (
        <ModalWrapper
  open={g2faModal}
  onClose={() => this.toggleModal("g2faModal", false)} // Handles outside clicks
>
  <ModalContent>
    {/* Close Button */}
    <CloseButton onClick={() => this.toggleModal("g2faModal", false)}>
      &times; {/* Cross symbol for close */}
    </CloseButton>

    <Title>{twoFaEnabled ? 'Manage 2FA' : 'Enable 2FA'}</Title>

    {/* QR Code and Instructions */}
    {!twoFaEnabled && twoFaQrCode && (
      <StyledCard>
        <StyledCardContent>
          <QRCodeImage src={twoFaQrCode} alt="2FA QR Code" />
          <p>Scan this QR code with your authenticator app</p>
        </StyledCardContent>
      </StyledCard>
    )}

    {/* Form for Verification or Disabling 2FA */}
    <FormWrapper onSubmit={twoFaEnabled ? this.disable2FA : this.verify2FA}>
      <StyledInput
        type="text"
        name="verifyCode"
        value={verifyCode}
        onChange={this.handleInputChange}
        placeholder="Enter verification code"
      />
      <StyledButton type="submit" disabled={disabled}>
        {twoFaEnabled
          ? disabled
            ? 'Disabling...'
            : 'Disable 2FA'
          : disabled
          ? 'Enabling...'
          : 'Enable 2FA'}
      </StyledButton>
    </FormWrapper>
  </ModalContent>
</ModalWrapper>
        )}

        <ActiveSessionsWrapper>
          <ActiveSessionsTitle>Active Sessions</ActiveSessionsTitle>
          <ActiveSessions />
        </ActiveSessionsWrapper>
      </>
    );
  }
}

export default Security;
