// import React, { useState, useEffect, useRef } from 'react';
// import styled from 'styled-components';

// import { 
//     Play, 
//     RefreshCw, 
//     Flag, 
//     Info,
//     ChevronRight,
//     Star
// } from 'lucide-react';
// import { useHistory } from 'react-router-dom';
// import { useBetting } from '../../../../../../../context/BettingContext';
// import { bannerImage } from '../../../../../../../utils/constants';
// import BookmakerMarket from './BookMaker';
// import InPlayBet from './InPlayBet';
// import FancyBet from './FancyBet';
// import videojs from "video.js";
// import "video.js/dist/video-js.css";

// // Styled Components
// const Container = styled.div`
//   width: 100%;
//   background: #f5f5f5;
// `;

// const Header = styled.div`
//   background: #2c3e50;
//   position: relative;
//   width: 100%;
//   height: 200px;
//   overflow: hidden;
 
//   box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
// `;

// const BannerImage = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   object-position: center;
//   transition: transform 0.5s ease;
  
//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// const BannerOverlay = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: linear-gradient(
//     rgba(0, 0, 0, 0.3),
//     rgba(0, 0, 0, 0.1)
//   );
//   pointer-events: none;
// `;


// const Container1 = styled.div`
//   width: 100%;
//   background: #f5f5f5;
//   font-family: Arial, sans-serif;
// `;

// const Header1 = styled.div`
//   background: yellow;
//   color: white;
//   padding: 10px;
//   font-size: 18px;
//   height: 200px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

// const Content = styled.div`
//   background: white;
// `;

// const Match = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px;
//   border-bottom: 1px solid #eee;
// `;

// const MatchInfo = styled.div`
//   flex: 1;
// `;

// const MatchTitle = styled.div`
//   color: #3498db;
//   font-weight: bold;
//   margin-bottom: 5px;
//   cursor: pointer;
//   display: inline-block;
  
//   &:hover {
//     text-decoration: underline;
//     color: #2980b9;
//   }
// `;

// const Status = styled.span`
//   color: #27ae60;
//   font-size: 14px;
// `;

// const OddsContainer = styled.div`
//   display: flex;
//   gap: 10px;
// `;

// const OddsSection = styled.div`
//   display: flex;
//   gap: 2px;
// `;

// const OddsBox = styled.div`
//   background: ${props => props.blue ? '#72BBEF' : '#FFB6C1'};
//   padding: 10px;
//   min-width: 60px;
//   text-align: center;
//   border-radius: 4px;
//   cursor: ${props => props.suspended ? 'not-allowed' : 'pointer'};
//   color: ${props => props.suspended ? '#e74c3c' : 'black'} !important;
//   opacity: ${props => props.suspended ? 0.7 : 1};
//   transition: all 0.2s ease;

//   &:hover {
//     opacity: ${props => props.suspended ? 0.7 : 0.8};
//   }
// `;

//   // const matches = [
//   //   {
//   //     id: 1,
//   //     team1: 'Bangladesh Women',
//   //     team2: 'Ireland Women',
//   //     status: 'In-Play',
//   //     pte: 'PTE10.05M',
//   //     isSuspended: false,
//   //     odds: {
//   //       matched: { back: '1.01', lay: '1.02' },
//   //       x: { back: '--', lay: '--' },
//   //       two: { back: '1000', lay: '--' }
//   //     }
//   //   },
//   //   {
//   //     id: 2,
//   //     team1: 'Biratnagar Kings',
//   //     team2: 'Janakpur Bolts',
//   //     status: 'In-Play',
//   //     pte: 'PTE0',
//   //     isSuspended: false,
//   //     odds: {
//   //       matched: { back: '1.5', lay: '1.6' },
//   //       x: { back: '--', lay: '--' },
//   //       two: { back: '2.5', lay: '2.6' }
//   //     }
//   //   },
//   //   {
//   //     id: 3,
//   //     team1: 'India Under-19s',
//   //     team2: 'Pakistan Under-19s',
//   //     status: 'In-Play',
//   //     pte: 'PTE0',
//   //     isSuspended: false,
//   //     odds: {
//   //       matched: { back: '1.8', lay: '1.9' },
//   //       x: { back: '--', lay: '--' },
//   //       two: { back: '2.1', lay: '2.2' }
//   //     }
//   //   },
//   //   {
//   //     id: 4,
//   //     team1: 'Lions',
//   //     team2: 'Warriors',
//   //     status: 'In-Play',
//   //     pte: 'PTE0',
//   //     isSuspended: false,
//   //     odds: {
//   //       matched: { back: '2.1', lay: '2.2' },
//   //       x: { back: '--', lay: '--' },
//   //       two: { back: '1.8', lay: '1.9' }
//   //     }
//   //   },
//   //   {
//   //     id: 5,
//   //     team1: 'South Africa',
//   //     team2: 'Sri Lanka',
//   //     status: 'In-Play',
//   //     pte: 'PTE20.34M',
//   //     isSuspended: false,
//   //     odds: {
//   //       matched: { back: '1.01', lay: '1.02' },
//   //       x: { back: '1000', lay: '--' },
//   //       two: { back: '1000', lay: '--' }
//   //     }
//   //   },
//   //   {
//   //     id: 6,
//   //     team1: 'Sydney Thunder SRL T20',
//   //     team2: 'Melbourne Renegades SRL T20',
//   //     status: 'In-Play',
//   //     pte: 'PTE0',
//   //     isSuspended: false,
//   //     odds: {
//   //       matched: { back: '1.9', lay: '2.0' },
//   //       x: { back: '--', lay: '--' },
//   //       two: { back: '1.9', lay: '2.0' }
//   //     }
//   //   },
//   //   {
//   //     id: 7,
//   //     team1: 'Western Province',
//   //     team2: 'Titans',
//   //     status: 'In-Play',
//   //     pte: 'PTE0',
//   //     isSuspended: false,
//   //     odds: {
//   //       matched: { back: '2.5', lay: '2.6' },
//   //       x: { back: '--', lay: '--' },
//   //       two: { back: '1.6', lay: '1.7' }
//   //     }
//   //   },
//   //   {
//   //     id: 8,
//   //     team1: 'Bangla Tigers',
//   //     team2: 'Up Nawabs',
//   //     status: 'In-Play',
//   //     pte: 'PTE95.019',
//   //     isSuspended: true,
//   //     odds: {
//   //       matched: { back: 'Suspend', lay: 'Suspend' },
//   //       x: { back: 'Suspend', lay: 'Suspend' },
//   //       two: { back: 'Suspend', lay: 'Suspend' }
//   //     }
//   //   },
//   //   {
//   //     id: 9,
//   //     team1: 'Northern Warriors',
//   //     team2: 'Abu Dhabi',
//   //     status: 'In-Play',
//   //     pte: 'PTE32.52M',
//   //     isSuspended: false,
//   //     odds: {
//   //       matched: { back: '120', lay: '190' },
//   //       x: { back: '--', lay: '--' },
//   //       two: { back: '--', lay: '1.01' }
//   //     }
//   //   },
//   //   {
//   //     id: 10,
//   //     team1: 'Mi Cape Town SRL T20',
//   //     team2: 'Joburg Super Kings SRL T20',
//   //     status: 'In-Play',
//   //     pte: 'PTE0',
//   //     isSuspended: false,
//   //     odds: {
//   //       matched: { back: '1.8', lay: '1.9' },
//   //       x: { back: '--', lay: '--' },
//   //       two: { back: '2.1', lay: '2.2' }
//   //     }
//   //   }
//   // ];

//   // const handleOddsClick = (match, category, type, odds) => {
//   //   if (match.isSuspended || odds === '--' || odds === 'Suspend') return;

//   //   // Determine the selection name based on category
//   //   let selectionName = '';
//   //   if (category === 'matched') {
//   //       selectionName = match.team1;  // First team for matched category
//   //   } else if (category === 'x') {
//   //       selectionName = 'The Draw';   // Draw for x category
//   //   } else if (category === 'two') {
//   //       selectionName = match.team2;  // Second team for two category
//   //   }

//   //   const betDetails = {
//   //       matchId: match.id,
//   //       matchTitle: `${match.team1} v ${match.team2}`,
//   //       team1: match.team1,
//   //       team2: match.team2,
//   //       selectionName: selectionName, // Add selection name
//   //       category,
//   //       type,
//   //       odds,
//   //       isBlue: type === 'back',
//   //       stake: '',
//   //       liability: '0.00'
//   //   };

//   //   console.log('Selected Bet:', betDetails);
//   //   addBet(betDetails);
//   // };
//   // const handleMatchClick = (matchId) => {
//   //   history.push(`/match/fullmarket/${matchId}`);  // This now matches the route path
//   // };


//   const LiveScoreFrame = ({ eventId }) => (
//     <div class="w-full overflow-hidden mt-3 lg:mt-0"><div class="bg-black w-full rounded-tr-md rounded-tl-md px-3 py-1"><p class="font-semibold text-white ml-2">Live Score</p></div><iframe class="w-full h-full pt-3 bg-black"
//      src={`https://score.akamaized.uk/?id=${eventId}`}></iframe></div>
//   );
//   const LiveStreamFrame = ({ eventId }) => (
//     <div className="mb-6">
//       <div className="w-full h-[400px] bg-gray-100 rounded-lg overflow-hidden shadow-lg">
//         <iframe
//           src={`https://livestream-v3-iframe.akamaized.uk/?eventid=${eventId}`}
//           className="w-full h-full border-0"
//           title="Live Stream"
//           allowFullScreen
//         />
//       </div>
//     </div>
//   );
// const MiddleDetailBettingUi = ({ matchData }) => {
//   const { addBet } = useBetting();
//   const history = useHistory();
//   const [bookmakerData, setBookmakerData] = useState([]);
//   const [fancyData, setFancyData] = useState([]);
//   const isMounted = useRef(true);
//   const intervalRef = useRef(null);

//   const fetchBookmakerFancy = async () => {
//     if (!matchData?.eventId || !isMounted.current) return;
    
//     try {
//       const response = await fetch(`https://api.skyla.game/sports/bookmakerFancy?eventid=${matchData.eventId}`);
//       const data = await response.json();
      
//       if (isMounted.current) {
//         setBookmakerData(data.bookMaker || []);
//         setFancyData(data.fancy || []);
//       }
//     } catch (error) {
//       console.error('Error fetching bookmaker/fancy data:', error);
//       if (isMounted.current) {
//         setBookmakerData([]);
//         setFancyData([]);
//       }
//     }
//   };

//   useEffect(() => {
//     isMounted.current = true;

//     if (matchData?.eventId) {
//       fetchBookmakerFancy();
//       intervalRef.current = setInterval(fetchBookmakerFancy, 5000);
//     }

//     return () => {
//       isMounted.current = false;
//       if (intervalRef.current) {
//         clearInterval(intervalRef.current);
//         intervalRef.current = null;
//       }
//     };
//   }, [matchData?.eventId]);

//   if (!matchData) {
//     return null;
//   }

//   return (
//     <>
//       {/* {matchData?.eventId && <LiveStreamFrame eventId={matchData.eventId} />} */}
//     {matchData?.eventId && <LiveScoreFrame eventId={matchData.eventId} />}
//       <InPlayBet matchData={matchData} />
//       {bookmakerData.length > 0 && <BookmakerMarket />}
//       {fancyData.length > 0 && <FancyBet />}
//     </>
//   );
// };

// export default MiddleDetailBettingUi;






//newer code


import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { 
    Play, 
    RefreshCw, 
    Flag, 
    Info,
    ChevronRight,
    Star
} from 'lucide-react';
import { useHistory } from 'react-router-dom';
import { useBetting } from '../../../../../../../context/BettingContext';
import { bannerImage } from '../../../../../../../utils/constants';
import BookmakerMarket from './BookMaker';
import InPlayBet from './InPlayBet';
import FancyBet from './FancyBet';
import videojs from "video.js";
import "video.js/dist/video-js.css";

// Styled Components
const Container = styled.div`
  width: 100%;
  background: #f5f5f5;
`;

const Header = styled.div`
  background: #2c3e50;
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.5s ease;
  
  &:hover {
    transform: scale(1.05);
  }

`;



const BannerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.1)
  );
  pointer-events: none;
`;


const Container1 = styled.div`
  width: 100%;

 min-height:100vh;
 overflow-y:auto;
`;

const Header1 = styled.div`
  background: yellow;
  color: white;
  padding: 10px;
  font-size: 18px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  background: white;
`;

const Match = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
`;

const MatchInfo = styled.div`
  flex: 1;
`;

const MatchTitle = styled.div`
  color: #3498db;
  font-weight: bold;
  margin-bottom: 5px;
  cursor: pointer;
  display: inline-block;
  
  &:hover {
    text-decoration: underline;
    color: #2980b9;
  }
`;

const Status = styled.span`
  color: #27ae60;
  font-size: 14px;
`;

const OddsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const OddsSection = styled.div`
  display: flex;
  gap: 2px;
`;

const OddsBox = styled.div`
  background: ${props => props.blue ? '#72BBEF' : '#FFB6C1'};
  padding: 10px;
  min-width: 60px;
  text-align: center;
  border-radius: 4px;
  cursor: ${props => props.suspended ? 'not-allowed' : 'pointer'};
  color: ${props => props.suspended ? '#e74c3c' : 'black'} !important;
  opacity: ${props => props.suspended ? 0.7 : 1};
  transition: all 0.2s ease;

  &:hover {
    opacity: ${props => props.suspended ? 0.7 : 0.8};
  }
`;

  // const matches = [
  //   {
  //     id: 1,
  //     team1: 'Bangladesh Women',
  //     team2: 'Ireland Women',
  //     status: 'In-Play',
  //     pte: 'PTE10.05M',
  //     isSuspended: false,
  //     odds: {
  //       matched: { back: '1.01', lay: '1.02' },
  //       x: { back: '--', lay: '--' },
  //       two: { back: '1000', lay: '--' }
  //     }
  //   },
  //   {
  //     id: 2,
  //     team1: 'Biratnagar Kings',
  //     team2: 'Janakpur Bolts',
  //     status: 'In-Play',
  //     pte: 'PTE0',
  //     isSuspended: false,
  //     odds: {
  //       matched: { back: '1.5', lay: '1.6' },
  //       x: { back: '--', lay: '--' },
  //       two: { back: '2.5', lay: '2.6' }
  //     }
  //   },
  //   {
  //     id: 3,
  //     team1: 'India Under-19s',
  //     team2: 'Pakistan Under-19s',
  //     status: 'In-Play',
  //     pte: 'PTE0',
  //     isSuspended: false,
  //     odds: {
  //       matched: { back: '1.8', lay: '1.9' },
  //       x: { back: '--', lay: '--' },
  //       two: { back: '2.1', lay: '2.2' }
  //     }
  //   },
  //   {
  //     id: 4,
  //     team1: 'Lions',
  //     team2: 'Warriors',
  //     status: 'In-Play',
  //     pte: 'PTE0',
  //     isSuspended: false,
  //     odds: {
  //       matched: { back: '2.1', lay: '2.2' },
  //       x: { back: '--', lay: '--' },
  //       two: { back: '1.8', lay: '1.9' }
  //     }
  //   },
  //   {
  //     id: 5,
  //     team1: 'South Africa',
  //     team2: 'Sri Lanka',
  //     status: 'In-Play',
  //     pte: 'PTE20.34M',
  //     isSuspended: false,
  //     odds: {
  //       matched: { back: '1.01', lay: '1.02' },
  //       x: { back: '1000', lay: '--' },
  //       two: { back: '1000', lay: '--' }
  //     }
  //   },
  //   {
  //     id: 6,
  //     team1: 'Sydney Thunder SRL T20',
  //     team2: 'Melbourne Renegades SRL T20',
  //     status: 'In-Play',
  //     pte: 'PTE0',
  //     isSuspended: false,
  //     odds: {
  //       matched: { back: '1.9', lay: '2.0' },
  //       x: { back: '--', lay: '--' },
  //       two: { back: '1.9', lay: '2.0' }
  //     }
  //   },
  //   {
  //     id: 7,
  //     team1: 'Western Province',
  //     team2: 'Titans',
  //     status: 'In-Play',
  //     pte: 'PTE0',
  //     isSuspended: false,
  //     odds: {
  //       matched: { back: '2.5', lay: '2.6' },
  //       x: { back: '--', lay: '--' },
  //       two: { back: '1.6', lay: '1.7' }
  //     }
  //   },
  //   {
  //     id: 8,
  //     team1: 'Bangla Tigers',
  //     team2: 'Up Nawabs',
  //     status: 'In-Play',
  //     pte: 'PTE95.019',
  //     isSuspended: true,
  //     odds: {
  //       matched: { back: 'Suspend', lay: 'Suspend' },
  //       x: { back: 'Suspend', lay: 'Suspend' },
  //       two: { back: 'Suspend', lay: 'Suspend' }
  //     }
  //   },
  //   {
  //     id: 9,
  //     team1: 'Northern Warriors',
  //     team2: 'Abu Dhabi',
  //     status: 'In-Play',
  //     pte: 'PTE32.52M',
  //     isSuspended: false,
  //     odds: {
  //       matched: { back: '120', lay: '190' },
  //       x: { back: '--', lay: '--' },
  //       two: { back: '--', lay: '1.01' }
  //     }
  //   },
  //   {
  //     id: 10,
  //     team1: 'Mi Cape Town SRL T20',
  //     team2: 'Joburg Super Kings SRL T20',
  //     status: 'In-Play',
  //     pte: 'PTE0',
  //     isSuspended: false,
  //     odds: {
  //       matched: { back: '1.8', lay: '1.9' },
  //       x: { back: '--', lay: '--' },
  //       two: { back: '2.1', lay: '2.2' }
  //     }
  //   }
  // ];

  // const handleOddsClick = (match, category, type, odds) => {
  //   if (match.isSuspended || odds === '--' || odds === 'Suspend') return;

  //   // Determine the selection name based on category
  //   let selectionName = '';
  //   if (category === 'matched') {
  //       selectionName = match.team1;  // First team for matched category
  //   } else if (category === 'x') {
  //       selectionName = 'The Draw';   // Draw for x category
  //   } else if (category === 'two') {
  //       selectionName = match.team2;  // Second team for two category
  //   }

  //   const betDetails = {
  //       matchId: match.id,
  //       matchTitle: `${match.team1} v ${match.team2}`,
  //       team1: match.team1,
  //       team2: match.team2,
  //       selectionName: selectionName, // Add selection name
  //       category,
  //       type,
  //       odds,
  //       isBlue: type === 'back',
  //       stake: '',
  //       liability: '0.00'
  //   };

  //   console.log('Selected Bet:', betDetails);
  //   addBet(betDetails);
  // };
  // const handleMatchClick = (matchId) => {
  //   history.push(`/match/fullmarket/${matchId}`);  // This now matches the route path
  // };


// Styled Components
const FrameContainer = styled.div`
  width: 100%;
  display: block;
  // position: relative;
  min-height: 169px;
`;

const ScoreContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background: #000;
  // border-radius: 8px 8px 0 0;
  min-height: 169px;
`;

const ScoreHeader = styled.div`
  width: 100%;
  background: #000;
  // border-radius: 6px 6px 0 0;
  // padding: 12px;
`;

const ScoreFrame = styled.iframe`
  width: 100%;
  height: 100%;
  min-height: 169px;
  border: none;
  display: block;
`;

const StreamContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const StreamWrapper = styled.div`
  width: 100%;
  height: 169px;
  background: #f1f1f1;
  // border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const StreamFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  display: block;
`;

const LiveScoreFrame = ({ eventId }) => (
  <ScoreContainer>
    <ScoreHeader />
    <ScoreFrame 
      src={`https://score.akamaized.uk/?id=${eventId}`}
      title="Live Score"
    />
  </ScoreContainer>
);

const LiveStreamFrame = ({ eventId }) => (
  <StreamContainer>
    <StreamWrapper>
      <StreamFrame
        src={`https://livestream-v3-iframe.akamaized.uk/?eventid=${eventId}`}
        title="Live Stream"
        allowFullScreen
      />
    </StreamWrapper>
  </StreamContainer>
);
const MiddleDetailBettingUi = ({ matchData }) => {
  const { addBet } = useBetting();
  const history = useHistory();
  const [bookmakerData, setBookmakerData] = useState([]);
  const [fancyData, setFancyData] = useState([]);
  const isMounted = useRef(true);
  const intervalRef = useRef(null);

  const fetchBookmakerFancy = async () => {
    if (!matchData?.eventId || !isMounted.current) return;
    
    try {
      const response = await fetch(`https://api.skyla.game/sports/bookmakerFancy?eventid=${matchData.eventId}`);
      const data = await response.json();
      
      if (isMounted.current) {
        setBookmakerData(data.bookMaker || []);
        setFancyData(data.fancy || []);
      }
    } catch (error) {
      console.error('Error fetching bookmaker/fancy data:', error);
      if (isMounted.current) {
        setBookmakerData([]);
        setFancyData([]);
      }
    }
  };

  useEffect(() => {
    isMounted.current = true;

    if (matchData?.eventId) {
      fetchBookmakerFancy();
      intervalRef.current = setInterval(fetchBookmakerFancy, 5000);
    }

    return () => {
      isMounted.current = false;
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [matchData?.eventId]);

  if (!matchData) {
    return null;
  }


  console.log("macted data", typeof matchData?.eventId, matchData?.eventId)

  return (
  <>
    {/* {matchData?.eventId && <LiveStreamFrame eventId={matchData.eventId} />} */}
    {/* {matchData?.eventId &&   <FrameContainer> <LiveStreamFrame eventId={matchData.eventId} />  </FrameContainer>} */}
    {matchData?.eventId &&   <FrameContainer> <LiveScoreFrame eventId={matchData.eventId} />  </FrameContainer>}
    <InPlayBet matchData={matchData} />
    {bookmakerData.length > 0 && <BookmakerMarket  matchData={matchData} 
    bookmakerData={bookmakerData}  />}
    {fancyData.length > 0 && <FancyBet matchData={matchData} 
    fancyData={fancyData} />}
    </>

  );
};

export default MiddleDetailBettingUi;