// import React, { useEffect, useLayoutEffect, useState } from 'react';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import { ChevronDown, ChevronUp, Gamepad, OrigamiIcon, SliceIcon } from 'lucide-react';
// import ClaudeIcon from '../../../Icons/Casino';
// import GameIcon from '../../../Icons/Game';
// import LotteryIcon from '../../../Icons/Lottery';
// import TradingIcon from '../../../Icons/Crypto';
// import PromotionIcon from '../../../Icons/PromotionIcon';
// import VipSvg from '../../../Icons/Vip';
// import BonusIcon from '../../../Icons/BonusIcon';
// import AffilateIcon from '../../../Icons/AffliateIcon';
// import ProvablyFairIcon from '../../../Icons/Provably';
// import HeadphoneIcon from '../../../Icons/Support';
// import GlobeIcon from '../../../Icons/Glob';
// import SkylaSlot from '../../../Icons/SlotsSkyla';
// import SkylaLivecasino from '../../../Icons/SkylaLiveCasino';
// import ClubIconMember from '../../../Icons/Club';
// import { CRISTP_ID } from '../../../Helper';
// import CrashIcon from '../../../Icons/CrashIcon';
// import SlotsIcon from '../../../Icons/SlotsIcon';



// const SidebarContainer = styled.div`
//   width: 100%;
//   transition: width 0.3s ease;
//   overflow-y: auto;
//   color: white;
//   padding-top: 46px;
// `;

// const MenuSection = styled.div`
//   padding: 12px;
//   margin-top: -55px;
// `;

// const MenuSectionS = styled.div`
//   padding:6px 0px;
//   width:100%;
// `;

// const MenuItem = styled(Link)`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 8px 12px;
//   text-decoration: none;
//   color: #808A94;
//   border-radius: ${props => {
//     if (props.accordion) {
//       return props.isAccordionOpen ? '12px 12px 0 0' : '12px'
//     }
//     return '12px'
//   }};
//   margin-bottom: 4px;
//   background-color: ${props => props.isActive ? '#3A4142' : '#323738'};
//   transition: all 0.2s ease;

//   &:hover {
//     background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
//     background-color: rgba(255, 255, 255, 0.05) !important;
//     color: white;
//   }

//   ${props => props.isActive && `
//      background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
//     background-color: rgba(255, 255, 255, 0.05) !important;
//     color: white;
//   `}
// `;
// const MenuItemVip = styled(Link)`
//   display: flex;
//   align-items: center;
//   text-decoration: none;
//   color: #808A94;
//   width:100%;
//   border-radius: 12px;
//    padding: 8px 12px;
//   background-color: ${props => props.isActive ? '#3A4142' : '#323738'};
//   transition: all 0.2s ease;

//   &:hover {
//   background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
//     background-color: rgba(255, 255, 255, 0.05) !important;
//   }

//   ${props => props.isActive && `
//      background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
//     background-color: rgba(255, 255, 255, 0.05) !important;
//   `}
// `;

// const OuterWrapper = styled(Link)`
//   display: flex;
//   align-items: center;
//   color: #808A94;
//   background-color: #323738;
//   border-radius: 12px;
//   // margin-top: -24px;
//   transition: all 0.2s ease;


// `;

// const IconBgNew = styled.div`
//   background-color: #464F50;
//   border-radius: 10px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 4px;
//   transition: background-color 0.2s ease;

//   &:hover {
//     background-color: #4a5354;
//   }
// `;

// const MenuIcon = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: ${props => props.color || 'inherit'};
//   transition: color 0.2s ease;

//   ${MenuItem}:hover & {
//     color: #ffffff;
//   }
// `;

// const VIPSpan = styled.span`
//   color: #25E284 ;
//   &:hover {
//  color:white !important;
//   }
// `;

// const ClubSpan = styled.span`
//   color: white;
//   &:hover {
//     color:white !important;
//   }
// `;

// const VIPContainer = styled.div`
//   display: flex;
//   gap: 2px;

//   ${MenuItem}:hover &,
//   ${MenuItemVip}:hover & ${ClubSpan} {
//     color: rgb(36, 238, 137);
//   }
// `;

// const MenuText = styled.span`
//   display: ${props => props.isCollapsed ? 'none' : 'block'};
//   font-size: 14px;
//   font-weight: bold;
//   color: ${props => props.isActive ? 'rgb(36, 238, 137)' : 'white'};
//   white-space: nowrap;
//   transition: color 0.2s ease;

//   ${MenuItem}:hover &,
//   ${MenuItemVip}:hover & {
//     color: white !important;
//   }
// `;


// const Divider = styled.div`
//   height: 1px;
//   background-color: #2C2F36;
//   margin: 12px 4px;
// `;

// const ExternalIndicator = styled.div`
//   display: ${props => props.isCollapsed ? 'none' : 'flex'};
//   align-items: center;
// `;

// const LeftSection = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 8px;
//   transition: all 0.2s ease;
// `;

// const IconBg = styled.div`
//   padding: 6px 8px;
//   background-color: #394142;
//   border-radius: 10px;
//   transition: background-color 0.2s ease;

//   &:hover {
//     background-color: #4a5354;
//   }
// `;

// const LogoContainer = styled(Link)`
//   display: flex;
//   align-items: center;
// `;

// const Logo = styled.img`
//   width: ${props => props.showFull ? '128px' : '96px'};
//   height: auto;
//   transition: width 0.3s ease;
// `;

// const SubMenuContainer = styled.div`
//  margin-top: -6px;
//   background-color: #323738;
//   display: ${props => props.isOpen ? 'block' : 'none'};
//   border-bottom-left-radius: 12px;
//   border-bottom-right-radius: 12px;
// `;

// const SubMenuItem = styled(Link)`
//   display: flex;
//   align-items: center;
//   padding: 8px 12px;
//   text-decoration: none;
//   color: #808A94;
//   border-radius: 8px;
//   margin-bottom: 4px;
//   background-color: ${props => props.isActive ? '#3A4142' : '#323738'};
//   transition: all 0.2s ease;

//   &:hover {
//     background-color: #3A4142;
//     color: #ffffff;
//   }
// `;

// const MenuItemClub = styled(MenuItemVip)`
//   color: #FFD700;

//   ${props => props.isActive && `
//     background: linear-gradient(90deg, rgba(255, 215, 0, 0.2), rgba(255, 215, 0, 0)) !important;
//     background-color: rgba(255, 215, 0, 0.05) !important;
//   `}

//   ${MenuText} {
//     color: #FFD700;
//   }

//   ${MenuIcon} {
//     color: #FFD700;
//   }
// `;

// const SkylaMobileMenu = ({ isCollapsed }) => {
//   const [activePath, setActivePath] = useState('/');
//   const [openAccordions, setOpenAccordions] = useState({});
//   const [openSGOriginals, setSGOriginals] = useState(false);
//   const [isHovered, setIsHovered] = useState(false);
//   const [loaded, setLoaded]=useState(false)

//   useLayoutEffect(() => {
//     setOpenAccordions(prev => ({ ...prev }));
//   }, [isCollapsed]);

//   const toggleAccordion = (path) => {
//     setOpenAccordions((prevState) => ({
//       ...prevState,
//       [path]: !prevState[path],
//     }));
//   };

//     const support = () => {
//       window.$crisp = [];
//       window.CRISP_WEBSITE_ID = CRISTP_ID;
  
//       if (!loaded) {
//         console.log("support");
//         setLoaded(true);
//         const script = document.createElement('script');
//         script.src = "https://client.crisp.chat/l.js";
//         script.id = "chtt";
//         script.async = true;
//         document.head.appendChild(script);
//       }
  
//       addNewStyle("#crisp-chatbox {display:block !important;}");
//       addNewStyle(
//         ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
//       );
  
//       wait(2000).then(() => {
//         const close = document.querySelector(".cc-wdhl");
//         if (close === null) return;
//         close.addEventListener("click", function (event) {
//           addNewStyle("#crisp-chatbox {display:none !important;}");
//           document.getElementById("chtt").src = "";
//         });
//       });
//     };
  

//   const menuItems = [
//     {
//       id: 1,
//       icon: <ClaudeIcon />,
//       text: 'Casino',
//       path: '/casino',
//       accordion: true,
//       subMenu: [
//         // { icon: <Gamepad />, text: 'SG Originals', path: '/games' },
//         // { icon: <SkylaSlot />, text: 'Slots', path: '/slots' },
//         // { icon: <SkylaLivecasino />, text: 'Live Casino', path: '/games' }
//       ]
//     },
//     {
//       id: 2, icon: <GameIcon />, text: 'Sports', path: '/sports/markets/in-play', accordion: true, 
//     },
//     {
//       id: 3, icon: <LotteryIcon />, text: 'Lottery', path: '/lottery', accordion: true,
//     },
//     {
//       id: 3, icon: <CrashIcon/>, text: 'Crash', path: '/crash-games', accordion: true,
//     },
//     {
//       id: 11, icon: <SlotsIcon/>, text: 'All Slots', path: '/all-slots', accordion: true,
//     },
//     // {
//     //   id: 4, icon: <TradingIcon />, text: 'Crypto Trading', path: '/trading', accordion: true,
//     // },
//     // {
//     //   id: 5, icon: <PromotionIcon />, text: 'Promotions', path: '/promotions', color: '#4CAF50', accordion: false, subMenu: [

//     //   ]
//     // },
//   ];

//   const vipItems = [
//     { id: 0, icon: <PromotionIcon />, text: 'Promotions', path: '/promotions',  },
//     { id: 1, icon: <VipSvg />, text: 'VIP Club', path: '/vip-club', color: '#4CAF50' },
  
    
//     { id: 2, icon: <ClubIconMember/>, text: 'Club Membership', path: '/club-membership', color: '#4CAF50' },
//     { id: 3, icon: <BonusIcon />, text: 'Bonus', path: '/bonus' },
//     { id: 4, icon: <AffilateIcon />, text: 'Affiliate', path: '/affiliate' },
//     { id: 5, icon: <ProvablyFairIcon />, text: 'Provably Fair', path: '/provably-fair' },
//     // { id: 6, icon: <HeadphoneIcon />, text: 'Live Support', path:'/menu' },
//     { id: 7, icon: <GlobeIcon />, text: 'English', path: '/langauge' },
//   ];

//   const gameItems = [
//     { icon: <ClaudeIcon />, text: 'Crash' },
//     { icon: <ClaudeIcon />, text: 'FastParity' },
//     { icon: <ClaudeIcon />, text: 'Twist' },
//     { icon: <ClaudeIcon />, text: 'Plinko' },
//   ];


//   return (
//     <SidebarContainer isCollapsed={isCollapsed}>
//       <MenuSection>
//         {menuItems.map((item, index) => (
//           <React.Fragment key={item.path}>
//             <MenuItem
//               to={item.path}
//               accordion={item.accordion}
//               isAccordionOpen={openAccordions[item.path]}
//               isActive={activePath === item.path}
//               isCollapsed={isCollapsed}
//               onClick={(e) => {
//                 if (item.accordion) {
//                   e.preventDefault();
//                   toggleAccordion(item.path);
//                   switch(item.text) {
//                     case 'Casino':
//                       window.location.href = '/casino';
//                       break;
//                     case 'Sports':
//                       window.location.href = '/sports/markets/in-play';
//                       break;
//                     case 'Lottery':
//                       window.location.href = '/lottery';
//                     case 'Live Support'  :
//                       support()

//                       break;
//                     default:
//                       break;
//                   }
//                 }
//                 setActivePath(item.path);
//               }}
//             >
//               <div style={{
//                 width: "100%",
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center"
//               }}>
//                 <LeftSection>
//                   <MenuIcon isCollapsed={isCollapsed} color={item.color}>
//                     {item.icon}
//                   </MenuIcon>
//                   <MenuText
//                     isActive={activePath === item.path}
//                     isCollapsed={isCollapsed}
//                     color={item.color}
//                     isSpecial={item.text === 'VIP Club'}
//                   >
//                     {item.text === "VIP Club" ? (
//                       <VIPContainer>
//                         <VIPSpan>VIP</VIPSpan>
//                         <ClubSpan>Club</ClubSpan>
//                       </VIPContainer>
//                     ) : item.text}
//                   </MenuText>
//                 </LeftSection>
//                 {item.accordion && !isCollapsed && (
//                   <IconBgNew>
//                     <MenuIcon isCollapsed={isCollapsed}>
//                       {openAccordions[item.path] ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
//                     </MenuIcon>
//                   </IconBgNew>
//                 )}
//               </div>
//             </MenuItem>
//             {/* {!isCollapsed && item.subMenu && openAccordions[item.path] && (
//               <SubMenuContainer isOpen={openAccordions[item.path]}>
//                 {item.subMenu.length > 0 ? (
//                   item.subMenu.map((subItem) => (
//                     <SubMenuItem
//                       key={subItem.path}
//                       to={subItem.path}
//                       isActive={activePath === subItem.path}
//                       onClick={() => setActivePath(subItem.path)}
//                       onMouseEnter={() => {
//                         if (subItem.text === "SG Originals") setIsHovered(true);
//                       }}
//                       onMouseLeave={() => setIsHovered(false)}
//                     >
//                       <LeftSection>
//                         <MenuIcon isCollapsed={false} color={subItem.color}>
//                           {subItem.icon}
//                         </MenuIcon>
//                         <MenuText isCollapsed={false}>
//                           {subItem.text}
//                         </MenuText>
//                       </LeftSection>
//                     </SubMenuItem>
//                   ))
//                 ) : (
                 
                 

// //  <div style={{ "textAlign": "center", padding:"24px" }}>Coming Soon</div>
// <div style={{ "textAlign": "center", padding:"24px" }}></div>
                

//                 )}
//               </SubMenuContainer>
//             )} */}
//             {/* {[4, 7, 8, 12].includes(index) && <Divider />} */}
//           </React.Fragment>
//         ))}
//       </MenuSection>

//       <div style={{ padding: '8px' }}>
//         <OuterWrapper>
//           <MenuSectionS>
//             {vipItems.map((item, index) => (
//               <React.Fragment key={item.path}>
//                 {item.text === 'Club Membership' ? (
//                   <MenuItemClub
//                     to={item.path}
//                     isActive={activePath === item.path}
//                     isCollapsed={isCollapsed}
//                     onClick={() => setActivePath(item.path)}
//                   >
//                     <LeftSection>
//                       <MenuIcon isCollapsed={isCollapsed} color="#FFD700">
//                         {item.icon}
//                       </MenuIcon>
//                       <MenuText
//                         isActive={activePath === item.path}
//                         isCollapsed={isCollapsed}
//                         color="#FFD700"
//                       >
//                         {item.text}
//                       </MenuText>
//                     </LeftSection>
//                   </MenuItemClub>
//                 ) : (
//                   <MenuItemVip
//                     to={item.path}
//                     isActive={activePath === item.path}
//                     isCollapsed={isCollapsed}
//                     onClick={() => setActivePath(item.path)}
//                   >
//                     <LeftSection>
//                       <MenuIcon isCollapsed={isCollapsed} color={item.color}>
//                         {item.icon}
//                       </MenuIcon>
//                       <MenuText
//                         isActive={activePath === item.path}
//                         isCollapsed={isCollapsed}
//                         color={item.color}
//                         isSpecial={item.text === 'VIP Club'}
//                       >
//                         {item.text === "VIP Club" ? (
//                           <VIPContainer>
//                             <VIPSpan>VIP</VIPSpan>
//                             <ClubSpan>Club</ClubSpan>
//                           </VIPContainer>
//                         ) : item.text}
//                       </MenuText>
//                     </LeftSection>
//                     {item.external && (
//                       <ExternalIndicator isCollapsed={isCollapsed}>
//                         <GlobeIcon size={16} />
//                       </ExternalIndicator>
//                     )}
//                   </MenuItemVip>
//                 )}
//                 {[4, 7, 8, 12].includes(index) && <Divider />}
//               </React.Fragment>
//             ))}
//           </MenuSectionS>
//         </OuterWrapper>
//       </div>
//     </SidebarContainer>
//   );
// };

// export default SkylaMobileMenu;





import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp, Gamepad, OrigamiIcon, SliceIcon } from 'lucide-react';
import ClaudeIcon from '../../../Icons/Casino';
import GameIcon from '../../../Icons/Game';
import LotteryIcon from '../../../Icons/Lottery';
import TradingIcon from '../../../Icons/Crypto';
import PromotionIcon from '../../../Icons/PromotionIcon';
import VipSvg from '../../../Icons/Vip';
import BonusIcon from '../../../Icons/BonusIcon';
import AffilateIcon from '../../../Icons/AffliateIcon';
import ProvablyFairIcon from '../../../Icons/Provably';
import HeadphoneIcon from '../../../Icons/Support';
import GlobeIcon from '../../../Icons/Glob';
import SkylaSlot from '../../../Icons/SlotsSkyla';
import SkylaLivecasino from '../../../Icons/SkylaLiveCasino';
import ClubIconMember from '../../../Icons/Club';
import { CRISTP_ID } from '../../../Helper';
import SlotsIcon from '../../../Icons/SlotsIcon';
import CrashIcon from '../../../Icons/CrashIcon';



const SidebarContainer = styled.div`
  width: 100%;
  transition: width 0.3s ease;
  overflow-y: auto;
  color: white;
  padding-top: 46px;
`;

const MenuSection = styled.div`
  padding: 12px;
  margin-top: -55px;
`;

const MenuSectionS = styled.div`
  padding:6px 0px;
  width:100%;
`;

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  text-decoration: none;
  color: #808A94;
  border-radius: ${props => {
    if (props.accordion) {
      return props.isAccordionOpen ? '12px 12px 0 0' : '12px'
    }
    return '12px'
  }};
  margin-bottom: 4px;
  background-color: ${props => props.isActive ? '#3A4142' : '#323738'};
  transition: all 0.2s ease;

  &:hover {
    background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: white;
  }

  // ${props => props.isActive && `
  //    background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
  //   background-color: rgba(255, 255, 255, 0.05) !important;
  //   color: white;
  // `}
`;
const MenuItemVip = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #808A94;
  width:100%;
  border-radius: 12px;
   padding: 8px 12px;
  background-color: ${props => props.isActive ? '#3A4142' : '#323738'};
  transition: all 0.2s ease;

  // &:hover {
  // background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
  //   background-color: rgba(255, 255, 255, 0.05) !important;
  // }

  // ${props => props.isActive && `
  //    background: linear-gradient(90deg, rgba(35, 238, 136, 0.2), rgba(35, 238, 136, 0)) !important;
  //   background-color: rgba(255, 255, 255, 0.05) !important;
  // `}
`;

const OuterWrapper = styled(Link)`
  display: flex;
  align-items: center;
  color: #808A94;
  background-color: #323738;
  border-radius: 12px;
  // margin-top: -24px;
  transition: all 0.2s ease;


`;

const IconBgNew = styled.div`
  background-color: #464F50;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #4a5354;
  }
`;

const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color || 'inherit'};
  transition: color 0.2s ease;

  ${MenuItem}:hover & {
    color: #ffffff;
  }
`;

const VIPSpan = styled.span`
  color: #25E284 ;
  &:hover {
 color:white !important;
  }
`;

const ClubSpan = styled.span`
  color: white;
  &:hover {
    color:white !important;
  }
`;

const VIPContainer = styled.div`
  display: flex;
  gap: 2px;

  ${MenuItem}:hover &,
  ${MenuItemVip}:hover & ${ClubSpan} {
    color: rgb(36, 238, 137);
  }
`;

const MenuText = styled.span`
  display: ${props => props.isCollapsed ? 'none' : 'block'};
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.isActive ? 'rgb(36, 238, 137)' : 'white'};
  white-space: nowrap;
  transition: color 0.2s ease;

  ${MenuItem}:hover &,
  ${MenuItemVip}:hover & {
    color: white !important;
  }
`;


const Divider = styled.div`
  height: 1px;
  background-color: #2C2F36;
  margin: 12px 4px;
`;

const ExternalIndicator = styled.div`
  display: ${props => props.isCollapsed ? 'none' : 'flex'};
  align-items: center;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
`;

const IconBg = styled.div`
  padding: 6px 8px;
  background-color: #394142;
  border-radius: 10px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #4a5354;
  }
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: ${props => props.showFull ? '128px' : '96px'};
  height: auto;
  transition: width 0.3s ease;
`;

const SubMenuContainer = styled.div`
 margin-top: -6px;
  background-color: #323738;
  display: ${props => props.isOpen ? 'block' : 'none'};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const SubMenuItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  text-decoration: none;
  color: #808A94;
  border-radius: 8px;
  margin-bottom: 4px;
  background-color: ${props => props.isActive ? '#3A4142' : '#323738'};
  transition: all 0.2s ease;

  &:hover {
    background-color: #3A4142;
    color: #ffffff;
  }
`;

const MenuItemClub = styled(MenuItemVip)`
  color: #FFD700;

  ${props => props.isActive && `
    background: linear-gradient(90deg, rgba(255, 215, 0, 0.2), rgba(255, 215, 0, 0)) !important;
    background-color: rgba(255, 215, 0, 0.05) !important;
  `}

  ${MenuText} {
    color: #FFD700;
  }

  ${MenuIcon} {
    color: #FFD700;
  }
`;

const SkylaMobileMenu = ({ isCollapsed }) => {
  const [activePath, setActivePath] = useState('/');
  const [openAccordions, setOpenAccordions] = useState({});
  const [openSGOriginals, setSGOriginals] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [loaded, setLoaded]=useState(false)

  useLayoutEffect(() => {
    setOpenAccordions(prev => ({ ...prev }));
  }, [isCollapsed]);

  const toggleAccordion = (path) => {
    setOpenAccordions((prevState) => ({
      ...prevState,
      [path]: !prevState[path],
    }));
  };

    const support = () => {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = CRISTP_ID;
  
      if (!loaded) {
        console.log("support");
        setLoaded(true);
        const script = document.createElement('script');
        script.src = "https://client.crisp.chat/l.js";
        script.id = "chtt";
        script.async = true;
        document.head.appendChild(script);
      }
  
      addNewStyle("#crisp-chatbox {display:block !important;}");
      addNewStyle(
        ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
      );
  
      wait(2000).then(() => {
        const close = document.querySelector(".cc-wdhl");
        if (close === null) return;
        close.addEventListener("click", function (event) {
          addNewStyle("#crisp-chatbox {display:none !important;}");
          document.getElementById("chtt").src = "";
        });
      });
    };
  

  const menuItems = [
    {
      id: 1,
      icon: <ClaudeIcon />,
      text: 'Casino',
      path: '/casino',
      accordion: true,
      subMenu: [
        // { icon: <Gamepad />, text: 'SG Originals', path: '/games' },
        // { icon: <SkylaSlot />, text: 'Slots', path: '/slots' },
        // { icon: <SkylaLivecasino />, text: 'Live Casino', path: '/games' }
      ]
    },
    {
      id: 2, icon: <GameIcon />, text: 'Sports', path: '/sports/markets/in-play', accordion: true, 
    },
    {
      id: 3, icon: <LotteryIcon />, text: 'Lottery', path: '/lottery', accordion: true,
    },
    {
      id: 646, icon: <CrashIcon/>, text: 'Crash', path: '/crash-games', accordion: true,
    },
    {
      id: 11, icon: <SlotsIcon/>, text: 'All Slots', path: '/all-slots', accordion: true,
    },
    // {
    //   id: 4, icon: <TradingIcon />, text: 'Crypto Trading', path: '/trading', accordion: true,
    // },
    // {
    //   id: 5, icon: <PromotionIcon />, text: 'Promotions', path: '/promotions', color: '#4CAF50', accordion: false, subMenu: [

    //   ]
    // },
  ];

  const vipItems = [
    { id: 0, icon: <PromotionIcon />, text: 'Promotions', path: '/promotions',  },
    { id: 1, icon: <VipSvg />, text: 'VIP Club', path: '/vip-club', color: '#4CAF50' },
  
    
    { id: 2, icon: <ClubIconMember/>, text: 'Club Membership', path: '/club-membership', color: '#4CAF50' },
    { id: 3, icon: <BonusIcon />, text: 'Bonus', path: '/bonus' },
    { id: 847, icon: <AffilateIcon />, text: 'Affiliate', path: '/affiliate' },
    { id: 5, icon: <ProvablyFairIcon />, text: 'Provably Fair', path: '/provably-fair' },
    // { id: 6, icon: <HeadphoneIcon />, text: 'Live Support', path:'/menu' },
    { id: 7, icon: <GlobeIcon />, text: 'English', path: '/langauge' },
  ];

  const gameItems = [
    { icon: <ClaudeIcon />, text: 'Crash' },
    { icon: <ClaudeIcon />, text: 'FastParity' },
    { icon: <ClaudeIcon />, text: 'Twist' },
    { icon: <ClaudeIcon />, text: 'Plinko' },
  ];


  return (
    <SidebarContainer isCollapsed={isCollapsed}>
      <MenuSection>
        {menuItems.map((item, index) => (
          <React.Fragment key={item.path}>
            <MenuItem
              to={item.path}
              accordion={item.accordion}
              isAccordionOpen={openAccordions[item.path]}
              isActive={activePath === item.path}
              isCollapsed={isCollapsed}
              onClick={(e) => {
                if (item.accordion) {
                  e.preventDefault();
                  toggleAccordion(item.path);
                  switch(item.text) {
                    case 'Casino':
                      window.location.href = '/casino';
                      break;
                    case 'Sports':
                      window.location.href = '/sports/markets/in-play';
                      break;
                    case 'Lottery':
                      window.location.href = '/lottery';
                      break;
                    case 'Crash':  // Add this case
                      window.location.href = '/crash-games';
                      break;
                    case 'All Slots':  // Add this case
                      window.location.href = '/all-slots';
                      break;
                    case 'Live Support':
                      support()
                      break;
                    default:
                      break;
                  }
                }
                setActivePath(item.path);
              }}
            >
              <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}>
                <LeftSection>
                  <MenuIcon isCollapsed={isCollapsed} color={item.color}>
                    {item.icon}
                  </MenuIcon>
                  <MenuText
                    isActive={activePath === item.path}
                    isCollapsed={isCollapsed}
                    color={item.color}
                    isSpecial={item.text === 'VIP Club'}
                  >
                    {item.text === "VIP Club" ? (
                      <VIPContainer>
                        <VIPSpan>VIP</VIPSpan>
                        <ClubSpan>Club</ClubSpan>
                      </VIPContainer>
                    ) : item.text}
                  </MenuText>
                </LeftSection>
                {item.accordion && !isCollapsed && (
                  <IconBgNew>
                    <MenuIcon isCollapsed={isCollapsed}>
                      {openAccordions[item.path] ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                    </MenuIcon>
                  </IconBgNew>
                )}
              </div>
            </MenuItem>
            {/* {!isCollapsed && item.subMenu && openAccordions[item.path] && (
              <SubMenuContainer isOpen={openAccordions[item.path]}>
                {item.subMenu.length > 0 ? (
                  item.subMenu.map((subItem) => (
                    <SubMenuItem
                      key={subItem.path}
                      to={subItem.path}
                      isActive={activePath === subItem.path}
                      onClick={() => setActivePath(subItem.path)}
                      onMouseEnter={() => {
                        if (subItem.text === "SG Originals") setIsHovered(true);
                      }}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <LeftSection>
                        <MenuIcon isCollapsed={false} color={subItem.color}>
                          {subItem.icon}
                        </MenuIcon>
                        <MenuText isCollapsed={false}>
                          {subItem.text}
                        </MenuText>
                      </LeftSection>
                    </SubMenuItem>
                  ))
                ) : (
                 
                 

//  <div style={{ "textAlign": "center", padding:"24px" }}>Coming Soon</div>
<div style={{ "textAlign": "center", padding:"24px" }}></div>
                

                )}
              </SubMenuContainer>
            )} */}
            {/* {[4, 7, 8, 12].includes(index) && <Divider />} */}
          </React.Fragment>
        ))}
      </MenuSection>

      <div style={{ padding: '8px' }}>
        <OuterWrapper>
          <MenuSectionS>
            {vipItems.map((item, index) => (
              <React.Fragment key={item.path}>
                {item.text === 'Club Membership' ? (
                  <MenuItemClub
                    to={item.path}
                    isActive={activePath === item.path}
                    isCollapsed={isCollapsed}
                    onClick={() => setActivePath(item.path)}
                  >
                    <LeftSection>
                      <MenuIcon isCollapsed={isCollapsed} color="#FFD700">
                        {item.icon}
                      </MenuIcon>
                      <MenuText
                        isActive={activePath === item.path}
                        isCollapsed={isCollapsed}
                        color="#FFD700"
                      >
                        {item.text}
                      </MenuText>
                    </LeftSection>
                  </MenuItemClub>
                ) : (
                  <MenuItemVip
                    to={item.path}
                    isActive={activePath === item.path}
                    isCollapsed={isCollapsed}
                    onClick={() => setActivePath(item.path)}
                  >
                    <LeftSection>
                      <MenuIcon isCollapsed={isCollapsed} color={item.color}>
                        {item.icon}
                      </MenuIcon>
                      <MenuText
                        isActive={activePath === item.path}
                        isCollapsed={isCollapsed}
                        color={item.color}
                        isSpecial={item.text === 'VIP Club'}
                      >
                        {item.text === "VIP Club" ? (
                          <VIPContainer>
                            <VIPSpan>VIP</VIPSpan>
                            <ClubSpan>Club</ClubSpan>
                          </VIPContainer>
                        ) : item.text}
                      </MenuText>
                    </LeftSection>
                    {item.external && (
                      <ExternalIndicator isCollapsed={isCollapsed}>
                        <GlobeIcon size={16} />
                      </ExternalIndicator>
                    )}
                  </MenuItemVip>
                )}
                {[4, 7, 8, 12].includes(index) && <Divider />}
              </React.Fragment>
            ))}
          </MenuSectionS>
        </OuterWrapper>
      </div>
    </SidebarContainer>
  );
};

export default SkylaMobileMenu;