import React, { createContext, useContext, useState } from 'react';

const BettingContext = createContext();

export const BettingProvider = ({ children }) => {
    const [selectedBets, setSelectedBets] = useState([]);
    const [placedBets, setPlacedBets] = useState([]); // New state for storing placed bets

    const addBet = (bet) => {
        setSelectedBets(prevBets => {
            const existingBetIndex = prevBets.findIndex(
                existing => existing.matchId === bet.matchId && 
                           existing.category === bet.category && 
                           existing.type === bet.type
            );
            if (existingBetIndex !== -1) {
                return prevBets.filter((_, index) => index !== existingBetIndex);
            }
            return [...prevBets, { ...bet, id: Date.now() }];
        });
    };

    const removeBet = (betId) => {
        setSelectedBets(prevBets => prevBets.filter(bet => bet.id !== betId));
    };

    const storePlacedBets = (bets) => {
        setPlacedBets(prevBets => [...prevBets, ...bets]);
    };

    return (
        <BettingContext.Provider value={{ 
            selectedBets, 
            addBet, 
            removeBet,
            placedBets,
            storePlacedBets 
        }}>
            {children}
        </BettingContext.Provider>
    );
};

export const useBetting = () => useContext(BettingContext);