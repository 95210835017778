import { Target } from 'lucide-react';
import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  background: #fff;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background: #1F344E;
  color: white;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-weight: 500;
`;

const MatchedInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
`;

const InfoIcon = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #6E6E6E;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

const ChartIcon = styled.span`
  color: #6E6E6E;
`;

const Table = styled.div`
  width: 100%;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  border-bottom: 1px solid #e0e0e0;
  background: #f5f5f5;
`;

const HeaderCell = styled.div`
  width: 90px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: black;
  border-left: 1px solid #e0e0e0;
  
  &:first-child {
    width: auto;
    border-left: none;
  }
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  border-bottom: 1px solid #e0e0e0;
  background: ${props => props.highlight ? '#f0f0f0' : '#fff'};
`;

const TeamCell = styled.div`
  padding: 12px;
  font-size: 14px;
  color: black;
  display: flex;
  align-items: center;
`;

const OddsCell = styled.div`
  width: 90px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => {
    if (props.selected) {
      return props.type === 'back' ? '#86b4da' : '#e4979c';
    }
    return props.type === 'back' ? '#B7D5EC' : '#FAC5CE';
  }};
  border-left: 1px solid #e0e0e0;
  cursor: pointer;

  .odds {
    font-weight: 600;
    font-size: 14px;
    color: black;
  }
  
  .volume {
    font-size: 12px;
    color: black;
  }
`;

// New styled components for bet container
const BetContainer = styled.div`
  padding: 2px 10px;
  background: ${props => props.betType === 'back' ? '#E6F3FF' : '#FFE6E6'};
  border-top: 1px solid #e0e0e0;
  color: black;
`;

const BetInputContainer = styled.div`
  display: flex;
  align-items: stretch;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  background: white;
`;

const AdjustButton = styled.div`
  width: 40px;
  border: none;
  background: #f0f0f0;
  color: black;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background: #e0e0e0;
  }

  &:active {
    background: #d0d0d0;
  }
`;

const BetInput = styled.div`
  flex: 1;
  padding: 8px;
  font-size: 16px;
  text-align: center;
  background: white;
  color: black;
  display: flex;
  min-width:100px;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
`;

const QuickAmounts = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2px;
  margin-bottom: 8px;
`;

const QuickAmount = styled.div`
  padding: 4px;
  text-align: center;
  background: white;
  border: 1px solid #ddd;
  cursor: pointer;
  color: black;
  font-size: 13px;
  
  &:hover {
    background: #f5f5f5;
  }
`;

const NumberPad = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2px;
  width: 100%;
`;

const NumberButton = styled.div`
  padding: 12px;
  text-align: center;
  background: white;
  border: 1px solid #ddd;
  cursor: pointer;
  color: black;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background: #f5f5f5;
  }
`;

const CrossButton = styled(NumberButton)`
  font-weight: bold;
  font-size: 16px;
`;

const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  margin-top: 8px;
`;

const CancelButton = styled.div`
  padding: 10px;
  text-align: center;
  background: white;
  border: 1px solid #ddd;
  cursor: pointer;
  color: black;
  font-size: 14px;
`;

const PlaceButton = styled.div`
  padding: 10px;
  text-align: center;
  background: #f8b853;
  border: 1px solid #f8b853;
  cursor: pointer;
  color: black;
  font-size: 14px;
`;

const AcceptOddsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 5px;
  color: black;
  font-size: 13px;
`;

const AcceptOddsCheckbox = styled.input`
  margin: 0;
  cursor: pointer;
`;

const quickAmounts = [100, 1000, 2000, 5000, 50000, 100000];

const InPlayBetMobile = ({data}) => {
  // Update state to handle multiple bets
  const [selectedBets, setSelectedBets] = useState({});
  const [betAmounts, setBetAmounts] = useState({});
  const [acceptAnyOdds, setAcceptAnyOdds] = useState({});

  const handleOddsClick = (team, type, odds, volume) => {
    const betKey = `${team}-${type}`;
    const oppositeType = type === 'back' ? 'lay' : 'back';
    const oppositeKey = `${team}-${oppositeType}`;
    
    setSelectedBets(prev => {
      const newBets = { ...prev };
      
      // If same bet is clicked again, remove it
      if (newBets[betKey]) {
        delete newBets[betKey];
      } else {
        // Remove opposite type bet for same team if exists
        delete newBets[oppositeKey];
        // Add new bet
        newBets[betKey] = { team, type, odds, volume };
      }
      
      return newBets;
    });

    // Initialize bet amount if not exists
    if (!betAmounts[betKey]) {
      setBetAmounts(prev => ({
        ...prev,
        [betKey]: '100'
      }));
    }
  };

  const handleAmountChange = (betKey, amount) => {
    setBetAmounts(prev => ({
      ...prev,
      [betKey]: amount.toString()
    }));
  };

  const handleNumberClick = (betKey, num) => {
    setBetAmounts(prev => {
      const currentAmount = prev[betKey] || '0';
      if (currentAmount === '100' && num !== '00') {
        return { ...prev, [betKey]: num.toString() };
      } else {
        return { ...prev, [betKey]: currentAmount + num.toString() };
      }
    });
  };

  const handleClear = (betKey) => {
    setBetAmounts(prev => ({
      ...prev,
      [betKey]: '0'
    }));
  };

  const adjustBetAmount = (betKey, adjustment) => {
    setBetAmounts(prev => {
      const currentAmount = Number(prev[betKey] || 0);
      const newAmount = Math.max(0, currentAmount + adjustment);
      return {
        ...prev,
        [betKey]: newAmount.toString()
      };
    });
  };

  const handleCancelBet = (betKey) => {
    setSelectedBets(prev => {
      const newBets = { ...prev };
      delete newBets[betKey];
      return newBets;
    });
  };

  const renderBetContainer = (betKey, bet) => (
    <BetContainer key={betKey} betType={bet.type}>
      {/* BetContainer content */}
      <div style={{fontSize: '12px', color: '#6E6E6E', textAlign: 'right', margin:"1px 64px 1px 0px"}}>Min Bet</div>
      <div style={{display: 'flex', gap: '10px'}}>
        <BetInput>{`${bet.odds}`}</BetInput>
        <BetInputContainer>
          <AdjustButton onClick={() => adjustBetAmount(betKey, -10)}>−</AdjustButton>
          <BetInput>{betAmounts[betKey]}</BetInput>
          <AdjustButton onClick={() => adjustBetAmount(betKey, 10)}>+</AdjustButton>
        </BetInputContainer>
      </div>

      <QuickAmounts>
        {quickAmounts.map(amount => (
          <QuickAmount 
            key={amount}
            onClick={() => handleAmountChange(betKey, amount)}
          >
            {amount}
          </QuickAmount>
        ))}
      </QuickAmounts>

      <NumberPad>
        {[1, 2, 3, 4, 5, 6].map((num) => (
          <NumberButton key={num} onClick={() => handleNumberClick(betKey, num)}>
            {num}
          </NumberButton>
        ))}
        <CrossButton onClick={() => handleClear(betKey)}>×</CrossButton>
        {[7, 8, 9, 0, '00'].map((num) => (
          <NumberButton key={num} onClick={() => handleNumberClick(betKey, num)}>
            {num}
          </NumberButton>
        ))}
      </NumberPad>

      <ActionButtons>
        <CancelButton onClick={() => handleCancelBet(betKey)}>
          Cancel
        </CancelButton>
        <PlaceButton>
          Place Bet
        </PlaceButton>
      </ActionButtons>

      <AcceptOddsContainer>
        <AcceptOddsCheckbox 
          type="checkbox" 
          checked={acceptAnyOdds[betKey] || false}
          onChange={() => setAcceptAnyOdds(prev => ({
            ...prev,
            [betKey]: !prev[betKey]
          }))}
        />
        <span>Accept any odds</span>
      </AcceptOddsContainer>
    </BetContainer>
  );

  return (
    <Container>
      <Header>
      <HeaderTitle>
          <Target size={16} />
          Match Odds
        </HeaderTitle>
        <MatchedInfo>
          Matched
          <span>IR 22,433,508</span>
        </MatchedInfo>
      </Header>
      
      <Table>
        <TableHeader>
          <HeaderCell></HeaderCell>
          <HeaderCell>Back</HeaderCell>
          <HeaderCell>Lay</HeaderCell>
        </TableHeader>

        {/* Team 1 */}
        <TableRow>
          <TeamCell>{data.team1}</TeamCell>
          <OddsCell 
            type="back" 
            onClick={() => handleOddsClick('team1', 'back', '4.3', '216')}
            selected={selectedBets['team1-back']}
          >
            <span className="odds">4.3</span>
            <span className="volume">216</span>
          </OddsCell>
          <OddsCell 
            type="lay"
            onClick={() => handleOddsClick('team1', 'lay', '4.4', '209')}
            selected={selectedBets['team1-lay']}
          >
            <span className="odds">4.4</span>
            <span className="volume">209</span>
          </OddsCell>
        </TableRow>
        {Object.entries(selectedBets)
          .filter(([key]) => key.startsWith('team1'))
          .map(([betKey, bet]) => renderBetContainer(betKey, bet))}

        {/* Team 2 */}
        <TableRow>
          <TeamCell>{data.team2}</TeamCell>
          <OddsCell 
            type="back"
            onClick={() => handleOddsClick('team2', 'back', '1.29', '714')}
            selected={selectedBets['team2-back']}
          >
            <span className="odds">1.29</span>
            <span className="volume">714</span>
          </OddsCell>
          <OddsCell 
            type="lay"
            onClick={() => handleOddsClick('team2', 'lay', '1.3', '192')}
            selected={selectedBets['team2-lay']}
          >
            <span className="odds">1.3</span>
            <span className="volume">192</span>
          </OddsCell>
        </TableRow>
        {Object.entries(selectedBets)
          .filter(([key]) => key.startsWith('team2'))
          .map(([betKey, bet]) => renderBetContainer(betKey, bet))}

        {/* Draw */}
        <TableRow>
          <TeamCell>The Draw</TeamCell>
          <OddsCell 
            type="back"
            onClick={() => handleOddsClick('draw', 'back', '4.4', '209')}
            selected={selectedBets['draw-back']}
          >
            <span className="odds">4.4</span>
            <span className="volume">209</span>
          </OddsCell>
          <OddsCell 
            type="lay"
            onClick={() => handleOddsClick('draw', 'lay', '5.4', '2.9')}
            selected={selectedBets['draw-lay']}
          >
            <span className="odds">5.4</span>
            <span className="volume">2.9</span>
          </OddsCell>
        </TableRow>
        {Object.entries(selectedBets)
          .filter(([key]) => key.startsWith('draw'))
          .map(([betKey, bet]) => renderBetContainer(betKey, bet))}
      </Table>
    </Container>
  );
};

export default InPlayBetMobile;