import React from 'react'
import styled from 'styled-components'
import { useBetting } from '../../../../../context/BettingContext'

// Define styled components outside of the main component
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
`

const StyledHeader = styled.div`
  background: #2c3e50;
  color: white;
  padding: 14px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`

const StyledTableHeader = styled.thead`
  background-color: #CCCCCC;
  color: white;
`

const StyledTableHeaderCell = styled.th`
  padding: 3px 16px;
  text-align: left;
  font-weight: bold;
  color: black !important;
`

const StyledTableRow = styled.tr`
  background-color: ${props => props.type === 'back' ? '#e6f3ff' : '#ffe6e6'};
  margin-left:4px;
`

const StyledTableCell = styled.td`
  padding: 2px 10px;
  color: #333;
`

function BettingTable() {
    const { placedBets } = useBetting();

    return (
        <div>
            <StyledHeader>My Bets</StyledHeader>

            <StyledTable>
                <StyledTableHeader>
                    <tr>
                        <StyledTableHeaderCell>Selection</StyledTableHeaderCell>
                        <StyledTableHeaderCell>Odds</StyledTableHeaderCell>
                        <StyledTableHeaderCell>Stake</StyledTableHeaderCell>
                    </tr>
                </StyledTableHeader>
                <tbody>
                    {placedBets.map((bet) => (
                        <StyledTableRow key={bet.id} type={bet.type}>
                            <StyledTableCell style={{marginLeft:"4px"}}>{bet.selectionName}</StyledTableCell>
                            <StyledTableCell style={{marginLeft:"4px"}}>{bet.odds.toFixed(2)}</StyledTableCell>
                            <StyledTableCell style={{marginLeft:"4px"}}>{bet.stake.toFixed(2)}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </tbody>
            </StyledTable>
        </div>
    )
}

export default BettingTable

