import React, { createContext, useContext, useState, useEffect } from 'react';
import socket from '../Socket';

// Initial state object
const initialState = {
  registerbonus: 0,
  affiliatebonus: 0,
  comissionpercent: 0,
  sports: false,
  inr: false,
  mvr: false,
  aed: false,
  pkr: false,
  cryptocoin: false,
  sbc: false,
  casino: false,
  lotto: false,
  vipclub: false,
  clubmembership: false,
  bonus: false,
  affiliate: false,
  crash: false,
  originals: false,
  livegames: false,
  slotsgames: false,
  alllivegames: false,
  allslotsgames: false,
  lotterygames: false,
  indiangames: false,
  cards: false,
  jilli: false,
  bdt: false,
  clubrake: 0
};

// Create context with default values
const SiteConfigContext = createContext({
  siteConfig: initialState,
  updateSiteConfig: () => {},
});

// Separate socket handler setup
const setupSocketHandlers = (updateFn) => {
  socket.on('siteConfigUpdate', (data) => {
    console.log('Received site config update:', data);
    updateFn(data);
  });

  socket.emit('requestSiteConfig');

  return () => {
    socket.off('siteConfigUpdate');
  };
};

function SiteConfigProvider({ children }) {
  const [siteConfig, setSiteConfig] = useState(initialState);

  useEffect(() => {
    const cleanup = setupSocketHandlers((newData) => {
      setSiteConfig(prev => ({ ...prev, ...newData }));
    });

    return cleanup;
  }, []);

  const contextValue = {
    siteConfig,
    updateSiteConfig: (newConfig) => setSiteConfig(prev => ({ ...prev, ...newConfig }))
  };

  return (
    <SiteConfigContext.Provider value={contextValue}>
      {children}
    </SiteConfigContext.Provider>
  );
}

function useSiteConfig() {
  const context = useContext(SiteConfigContext);
  if (!context) {
    throw new Error('useSiteConfig must be used within a SiteConfigProvider');
  }
  return context;
}

export { SiteConfigProvider, useSiteConfig };