import React, { useState, useEffect, useLayoutEffect } from 'react';
import SportsWeb from "./SportsWeb";
import SportsMobileView from './SportsMobile';
import ComingSoonSports from './components/DisabledSports';
import CricketBetUiComponent from './components/CricketUi/CricketBetUi';
import styled, { keyframes } from 'styled-components';
import { useSiteConfig } from '../../../hooks/useSiteConfig';

// Loader animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1A1D1F;
`;

const Loader = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #2C3137;
  border-top: 5px solid #27ED87;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const Sports = () => {
  const [activeTab, setActiveTab] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [sportsEnabled, setSportsEnabled] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { siteConfig} = useSiteConfig();

  console.log("SITTEEEE", siteConfig)
  

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  useLayoutEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('https://api.skyla.game/siteconfig');
        const data = await response.json();
        setSportsEnabled(data.sports);
      } catch (error) {
        console.error('Error fetching site configuration:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfig();
  }, []);


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  
  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <>
      {/* {siteConfig?.sports ? (
        isMobile ? <SportsMobileView /> : <CricketBetUiComponent />
      ) : (
        <ComingSoonSports />
      )} */}
     {(isMobile ? <SportsMobileView /> : <CricketBetUiComponent />)}
    </>
  );
};

export default Sports;