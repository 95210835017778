import React from 'react';
import { Trophy, Star, Award, ChevronRight } from 'lucide-react';
import styled, { keyframes } from 'styled-components';

import JoinClubLogin from '../../Auth/JoinClubLogin';

// Keyframe Animations
const float = keyframes`
  0%, 100% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
`;

const pulse = keyframes`
  0%, 100% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.05); opacity: 1; }
`;

// Styled Components
const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
  
  @media (max-width: 899px) {
    padding: 0.5rem;
  }
`;

const GlowOrb = styled.div`
  position: absolute;
  border-radius: 50%;
  filter: blur(80px);
  opacity: 0.4;
  animation: ${float} 20s infinite;

  @media (max-width: 768px) {
    filter: blur(60px);
  }
`;

const TopOrb = styled(GlowOrb)`
  width: 400px;
  height: 400px;
  background: radial-gradient(circle at center, #52EC82, transparent 70%);
  top: -200px;
  left: -200px;

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
    top: -150px;
    left: -150px;
  }
`;

const BottomOrb = styled(GlowOrb)`
  width: 400px;
  height: 400px;
  background: radial-gradient(circle at center, #89E977, transparent 70%);
  bottom: -200px;
  right: -200px;

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
    bottom: -150px;
    right: -150px;
  }
`;

const Card = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(
    135deg,
    rgba(50, 55, 56, 0.9) 0%,
    rgba(74, 83, 84, 0.9) 50%,
    rgba(50, 55, 56, 0.9) 100%
  );
  border-radius: 24px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 899px) {
    padding: 1.5rem;
    border-radius: 16px;
  }
`;

const IconContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto 1.5rem;

  @media (max-width: 899px) {
    width: 80px;
    height: 80px;
  }
`;

const FloatingIcon = styled.div`
  position: absolute;
  animation: ${float} 3s ease-in-out infinite;
  
  &:nth-child(1) {
    top: -10px;
    left: -10px;
  }
  
  &:nth-child(2) {
    top: -10px;
    right: -10px;
  }
  
  &:nth-child(3) {
    bottom: -10px;
    left: -10px;
  }
  
  svg {
    width: 24px;
    height: 24px;
    color: #52EC82;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
`;

const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #292D2E;
  border: 5px solid rgba(82, 236, 130, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: ${pulse} 3s infinite ease-in-out;
  
  svg {
    width: 60px;
    height: 60px;
    color: #323738;
  }
`;

const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #ffffff, #52EC82, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(82, 236, 130, 0.3);
  padding: 0 1rem;

  @media (max-width: 899px) {
    font-size: 1.4rem;
  }
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 1.4;
  padding: 0 0.5rem;
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
  margin: 1.5rem 0;
  padding: 0 0.5rem;
  
  @media (max-width: 899px) {
    gap: 0.5rem;
  }
  
  @media (max-width: 390px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const StatItem = styled.div`
  padding: 0.75rem;
  background: rgba(82, 236, 130, 0.05);
  border-radius: 12px;
  
  .number {
    font-size: 1.4rem;
    font-weight: 700;
    background: linear-gradient(to right, #52EC82, #89E977);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 0.25rem;
  }
   
  .label {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.8rem;
  }
`;

const Divider = styled.div`
  height: 1px;
  background: linear-gradient(to right, rgba(82, 236, 130, 0.1), rgba(137, 233, 119, 0.5), rgba(82, 236, 130, 0.1));
  margin: 1rem 0;
`;



const ClubMembershipPublic = () => {
    return (
        <Container>
            <TopOrb />
            <BottomOrb />
            <Card>
                <IconContainer>
                    <IconWrapper>
                        <img src='/assets/images/lg.png' alt="Club Logo" style={{height:"", width:'100px', objectFit:'contain'}}/>
                    </IconWrapper>
                    <FloatingIcon>
                        <Trophy />
                    </FloatingIcon>
                    <FloatingIcon>
                        <Star />
                    </FloatingIcon>
                    <FloatingIcon>
                        <Award />
                    </FloatingIcon>
                </IconContainer>

                <Title>Join Our Exclusive Club Community!</Title>
                <Subtitle>
                    Create or join a club, connect with other members, and unlock exclusive rewards and benefits.
                </Subtitle>

                <StatsContainer>
                    <StatItem>
                        <div className="number">500+</div>
                        <div className="label">Active Clubs</div>
                    </StatItem>
                    <StatItem>
                        <div className="number">50K+</div>
                        <div className="label">Members</div>
                    </StatItem>
                    <StatItem>
                        <div className="number">$50K+</div>
                        <div className="label">Rewards Given</div>
                    </StatItem>
                </StatsContainer>

                <Divider />

              <div style={{width:'100%', justifyContent:'center', display:'flex'}}>
            <JoinClubLogin/>
              </div>
            </Card>
        </Container>
    );
};

export default ClubMembershipPublic;