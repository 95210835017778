import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const spinReverse = keyframes`
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
`;

const pulse = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  width: 100%;
  background: white;
`;

const SpinnerWrapper = styled.div`
  position: relative;
  width: 96px;
  height: 96px;
`;

const SpinnerCircle = styled.div`
  position: absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: 4px solid;
  border-radius: 50%;
  animation: ${props => props.reverse ? spinReverse : spin} 1.5s linear infinite;
  
  ${props => {
    if (props.variant === 'outer') {
      return `
        border-top-color: #3498db;
        border-right-color: #4aa3df;
        border-bottom-color: #5dade2;
        border-left-color: #7fb3d5;
        width: 96px;
        height: 96px;
      `;
    } else if (props.variant === 'middle') {
      return `
        border-top-color: #e74c3c;
        border-right-color: #ec7063;
        border-bottom-color: #f1948a;
        border-left-color: #f5b7b1;
        top: 8px;
        left: 8px;
        width: 80px;
        height: 80px;
      `;
    } else if (props.variant === 'inner') {
      return `
        border-top-color: #2ecc71;
        border-right-color: #58d68d;
        border-bottom-color: #82e0aa;
        border-left-color: #abebc6;
        top: 16px;
        left: 16px;
        width: 64px;
        height: 64px;
      `;
    }
  }}
`;

const LoadingText = styled.p`
  margin-top: 16px;
  color: #7f8c8d;
  font-size: 18px;
  font-weight: 500;
  animation: ${pulse} 1.5s ease-in-out infinite;
  font-family: Arial, sans-serif;
`;

const LoadingSpinner = () => {
  return (
    <LoaderContainer>
      <SpinnerWrapper>
        <SpinnerCircle variant="outer" />
        <SpinnerCircle variant="middle" reverse />
        <SpinnerCircle variant="inner" />
      </SpinnerWrapper>
      <LoadingText>Loading...</LoadingText>
    </LoaderContainer>
  );
};

export default LoadingSpinner;